import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineClose } from 'react-icons/ai';
import { toast } from 'react-toastify';

import { type OwnerType } from '../../../common/constants';
import { extractErrorsFromApi } from '../../../common/helpers';
import RenderErrorBag from '../../../components/render-error-bag';
import { useBulkDeleteReceiptMutation } from '../receipt.api-slice';

interface IProps {
  isOpen: boolean;
  toggleModal: () => any;
  ownerEntityType?: OwnerType;
  ownerEntityId?: string;
}

const BulkDeleteReceipts: React.FC<IProps> = ({ isOpen, toggleModal, ownerEntityId, ownerEntityType }) => {
  const [bulkDeleteReceipts, { isSuccess, isLoading, isError, error, reset }] = useBulkDeleteReceiptMutation();
  const [errorBag] = useState<string[]>([]);
  const { t } = useTranslation();

  const handleConfirmation = (): void => {
    void bulkDeleteReceipts({ ownerEntityId, ownerEntityType });
    toggleModal();
  };

  useEffect(() => {
    if (isSuccess) {
      toast(t('receipts.bulkDelete.successMessage'));
      reset();
    }
    if (isError && error != null) {
      toast.error(extractErrorsFromApi(error).toString);
      reset();
    }
  }, [isSuccess, error]);

  return (
    <>
      {isOpen ? (
        <>
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden outline-none focus:outline-none">
            <div className="mx-auto my-6 w-1/2">
              <div className="flex flex-col rounded-lg border-0 bg-white shadow-lg outline-none focus:outline-none">
                <div className="flex items-start justify-between rounded-t border-b border-solid border-slate-200 p-5">
                  <h3 className="text-3xl font-semibold">{t('receipts.bulkDelete.title')}</h3>
                  <button
                    className="float-right ml-auto border-0 p-1 text-3xl font-semibold leading-none text-black outline-none focus:outline-none"
                    onClick={toggleModal}
                  >
                    <AiOutlineClose size={20} />
                  </button>
                </div>
                <div className="relative w-full flex-auto p-6">
                  {errorBag.length > 0 && <RenderErrorBag errorBag={errorBag} />}
                  {t('receipts.bulkDelete.body')}
                </div>
                <div className="mt-4 flex w-full justify-end space-x-2 rounded-b border-t border-solid border-slate-200 px-4 py-6">
                  <button className="rounded-lg bg-gray-200 px-5 py-2 text-black" onClick={toggleModal}>
                    {t('receipts.bulkDelete.cancelBtnLabel')}
                  </button>
                  <button
                    disabled={isLoading}
                    onClick={handleConfirmation}
                    className="rounded-lg bg-greenColor px-4 py-2 text-white"
                  >
                    {t('receipts.bulkDelete.saveBtnLabel')}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
        </>
      ) : null}
    </>
  );
};

export default BulkDeleteReceipts;
