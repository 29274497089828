import { Form, Formik, type FormikHelpers } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import type { OwnerEntityType, OwnerType } from '../../../common/constants';
import { extractErrorsFromApi, getAgencyAndMerchantIdFromOwnerEntity } from '../../../common/helpers';
import type { SampleReceiptUpdateRequestBody } from '../../../common/types';
import RenderErrorBag from '../../../components/render-error-bag';
import { useAddSampleReceiptMutation } from '../sample-receipt.api-slice';
import { SampleReceiptSchema } from '../sample-receipt.schema';
import SampleReceiptForm from './SampleReceiptForm';

interface IProps {
  isOpen: boolean;
  toggleModal: () => any;
  ownerEntityType: OwnerType;
  ownerEntity: OwnerEntityType;
}

const initialValue: SampleReceiptUpdateRequestBody = {
  title: '',
  receipt: ''
};

const AddSampleReceipt: React.FC<IProps> = ({ isOpen, toggleModal, ownerEntityType, ownerEntity }) => {
  const [addItem, { isLoading, isError, error, isSuccess, reset }] = useAddSampleReceiptMutation();
  const [errorBag, setErrorBag] = useState<string[]>([]);
  const { t } = useTranslation();

  const handleAddItem = async (
    values: SampleReceiptUpdateRequestBody,
    action: FormikHelpers<SampleReceiptUpdateRequestBody>
  ): Promise<any> => {
    const { agencyId, merchantId } = getAgencyAndMerchantIdFromOwnerEntity(ownerEntityType, ownerEntity);
    await addItem({ ...values, ownerEntityType, ownerEntityId: ownerEntity.id, agencyId, merchantId });
    toggleModal();
  };

  useEffect(() => {
    if (isSuccess) {
      toast(t('sampleReceipts.add.successMessage'));
      reset();
    }
    if (isError && error != null) {
      setErrorBag(extractErrorsFromApi(error));
    }
  }, [isError, isSuccess]);

  return (
    <>
      {isOpen && (
        <>
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden outline-none focus:outline-none">
            <div className="relative mx-auto my-6 w-full max-w-3xl">
              <div className="relative flex w-full flex-col rounded-lg border-0 bg-white shadow-lg outline-none focus:outline-none">
                <div className="flex items-start justify-between rounded-t border-b border-solid border-slate-200 p-5">
                  <h3 className="text-3xl font-semibold">{t('sampleReceipts.add.title')}</h3>
                </div>
                <div className="relative w-full flex-auto p-6">
                  {errorBag.length > 0 && <RenderErrorBag errorBag={errorBag} />}
                  {
                    <Formik
                      initialValues={initialValue}
                      onSubmit={handleAddItem}
                      validationSchema={SampleReceiptSchema}
                    >
                      {({ isSubmitting }) => (
                        <Form className="flex w-full flex-col items-start px-2" autoComplete="off">
                          <SampleReceiptForm />
                          <div className="mt-4 flex w-full space-x-2 rounded-b border-t border-solid border-slate-200 py-6">
                            <button className="w-1/2 rounded-lg bg-gray-200 px-5 py-2 text-black" onClick={toggleModal}>
                              {t('sampleReceipts.add.cancelBtnLabel')}
                            </button>
                            <button
                              type="submit"
                              disabled={isLoading || isSubmitting}
                              className="w-1/2 rounded-lg bg-greenColor px-4 py-2 text-white"
                            >
                              {t('sampleReceipts.add.saveBtnLabel')}
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
        </>
      )}
    </>
  );
};

export default AddSampleReceipt;
