import { apiSlice } from '../../app/services/api-slice';
import { urlWithPageAndTerm } from '../../common/helpers';
import type {
  ApiSuccessResponse,
  Device,
  DeviceUpdateRequest,
  ListWithIdPaginationAndSearchReq,
  ListWithPaginationAndSearchReq,
  PaginatedData,
  PaginatedMeta
} from '../../common/types';

const deviceTagName: string = 'Devices';

export const deviceStoreApi = apiSlice
  .enhanceEndpoints({
    addTagTypes: [deviceTagName]
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getDevices: builder.query<PaginatedData<Device> | null, ListWithPaginationAndSearchReq>({
        query: ({ term = '', page = 1, pendingOnly = false }): string => {
          return urlWithPageAndTerm('devices', { term, page, pendingOnly });
        },
        transformResponse: (data: ApiSuccessResponse) => {
          if (data.data === undefined) {
            return null;
          }
          return {
            data: data.data.data as Device[],
            meta: data.data.meta as PaginatedMeta
          };
        },
        providesTags: (result) =>
          result != null
            ? [
                ...result.data.map(({ id }) => ({
                  type: deviceTagName,
                  id
                })),
                { type: deviceTagName, id: 'LIST' }
              ]
            : [{ type: deviceTagName, id: 'LIST' }]
      }),
      getPendingDevices: builder.query<PaginatedData<Device>, ListWithPaginationAndSearchReq>({
        query: ({ term = '', page = 1, pendingOnly = true }): string => {
          return urlWithPageAndTerm('devices', { term, page, pendingOnly });
        },
        transformResponse: (data: ApiSuccessResponse) => {
          return {
            data: data.data.data as Device[],
            meta: data.data.meta as PaginatedMeta
          };
        },
        providesTags: (result) =>
          result != null
            ? [
                ...result.data.map(({ id }) => ({
                  type: deviceTagName,
                  id
                })),
                { type: deviceTagName, id: 'LIST_PENDING_DEVICES' }
              ]
            : [{ type: deviceTagName, id: 'LIST_PENDING_DEVICES' }]
      }),
      getDevicesForAgency: builder.query<PaginatedData<Device>, ListWithIdPaginationAndSearchReq>({
        query: ({ id, term = '', page = 1 }): string => {
          return urlWithPageAndTerm(`devices/by-agency/${id}`, { term, page });
        },
        transformResponse: (data: ApiSuccessResponse) => {
          return {
            data: data.data.data as Device[],
            meta: data.data.meta as PaginatedMeta
          };
        },
        providesTags: (result) =>
          result != null
            ? [
                ...result.data.map(({ id }) => ({
                  type: deviceTagName,
                  id
                })),
                { type: deviceTagName, id: 'LIST' }
              ]
            : [{ type: deviceTagName, id: 'LIST' }]
      }),
      getDevicesForMerchant: builder.query<PaginatedData<Device>, ListWithIdPaginationAndSearchReq>({
        query: ({ id, term = '', page = 1 }): string => {
          return urlWithPageAndTerm(`devices/by-merchant/${id}`, { term, page });
        },
        transformResponse: (data: ApiSuccessResponse) => {
          return {
            data: data.data.data as Device[],
            meta: data.data.meta as PaginatedMeta
          };
        },
        providesTags: (result) =>
          result != null
            ? [
                ...result.data.map(({ id }) => ({
                  type: deviceTagName,
                  id
                })),
                { type: deviceTagName, id: 'LIST' }
              ]
            : [{ type: deviceTagName, id: 'LIST' }]
      }),
      getDevicesForStore: builder.query<PaginatedData<Device>, ListWithIdPaginationAndSearchReq>({
        query: ({ id, term = '', page = 1 }): string => {
          return urlWithPageAndTerm(`devices/by-store/${id}`, { term, page });
        },
        transformResponse: (data: ApiSuccessResponse) => {
          return {
            data: data.data.data as Device[],
            meta: data.data.meta as PaginatedMeta
          };
        },
        providesTags: (result) =>
          result != null
            ? [
                ...result.data.map(({ id }) => ({
                  type: deviceTagName,
                  id
                })),
                { type: deviceTagName, id: 'LIST' }
              ]
            : [{ type: deviceTagName, id: 'LIST' }]
      }),
      getListOfDevices: builder.query<Device[], string>({
        query: (id: string): string => `/devices/short-list?storeId=${id}`,
        transformResponse: (data: ApiSuccessResponse) => {
          return data.data as Device[];
        },
        providesTags: (result) =>
          result != null
            ? [
                ...result.map(() => ({
                  type: deviceTagName
                })),
                { type: deviceTagName, id: 'LIST_SHORT' }
              ]
            : [{ type: deviceTagName, id: 'LIST_SHORT' }]
      }),
      getDeviceById: builder.query<Device, string>({
        query: (id: string) => `/devices/${id}`,
        transformResponse: (data: ApiSuccessResponse) => {
          return data.data as Device;
        },
        providesTags: (result) => (result != null ? [{ type: deviceTagName, id: result?.id }] : [])
      }),
      approveDevice: builder.mutation<Device, string>({
        query: (id) => ({
          url: `/devices/approve-device/${id}`,
          method: 'POST'
        }),
        invalidatesTags: (result, error, arg) => [
          { type: deviceTagName, id: 'LIST' },
          { type: deviceTagName, id: 'LIST_PENDING_DEVICES' }
        ]
      }),
      deleteDeviceById: builder.mutation<void, string>({
        query: (id: string) => ({
          url: `/devices/${id}`,
          method: 'DELETE'
        }),
        invalidatesTags: (result, error, arg) => [
          { type: deviceTagName, id: 'LIST' },
          { type: deviceTagName, id: 'LIST_PENDING_DEVICES' }
        ]
      }),
      updateDevice: builder.mutation<Device, DeviceUpdateRequest>({
        query: ({ id, body }) => ({
          url: `/devices/${id}`,
          method: 'PATCH',
          body
        }),
        invalidatesTags: (result, error, arg) => [{ type: deviceTagName, id: result?.id }]
      })
    })
  });

export const {
  useGetDevicesQuery,
  useGetPendingDevicesQuery,
  useGetDevicesForAgencyQuery,
  useGetDevicesForMerchantQuery,
  useGetDevicesForStoreQuery,
  useGetDeviceByIdQuery,
  useUpdateDeviceMutation,
  useApproveDeviceMutation,
  useDeleteDeviceByIdMutation,
  useLazyGetListOfDevicesQuery
} = deviceStoreApi;
export default deviceStoreApi;
