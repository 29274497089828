import { AiOutlineEdit } from 'react-icons/ai';
import { Link } from 'react-router-dom';

import type { OwnerType } from '../../../../common/constants';

interface IProps {
  ownerEntityType: OwnerType;
  ownerEntityId: string;
}

const NoDataContainer: React.FC<IProps> = ({ ownerEntityType, ownerEntityId }) => {
  const DEFAULT_RULE_NOT_EXIST_MSG = `No default rule exists for this ${ownerEntityType}.`;
  const ADD_DEFAULT_RULE_BUTTON_LABEL = 'Add Default Rule';

  return (
    <div className="flex h-full w-full flex-col items-center justify-center">
      <span className="text-lg">{DEFAULT_RULE_NOT_EXIST_MSG}</span>
      <Link to={`/admin/rules/${ownerEntityType}/${ownerEntityId}`}>
        <div className="space-between flex items-center justify-center rounded-lg bg-greenColor p-2 text-center  text-gray-50">
          <AiOutlineEdit size={20} />
          <button className="px-2 text-sm">{ADD_DEFAULT_RULE_BUTTON_LABEL}</button>
        </div>
      </Link>
    </div>
  );
};

export default NoDataContainer;
