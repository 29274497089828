import { AiOutlineMinusCircle } from 'react-icons/ai';

import { Action, type GlobalIdentifierObject, GlobalIdentifierOperator } from '../../../../common/types';
import CustomCheckboxInputV2 from '../../../../components/Form/CustomCheckboxInputV2';
import CustomMultiTextInputV2 from '../../../../components/Form/CustomMultiTextInputV2';
import CustomTextInputV3 from '../../../../components/Form/CustomTextInputV3';

interface IProps {
  identifier: GlobalIdentifierObject;
  handleChange: (action: Action, identifier: GlobalIdentifierObject) => void;
}

const GlobalIdentifierComponent: React.FC<IProps> = ({ identifier, handleChange }) => {
  const handleTagChange = (name: string, tags: string[]): void => {
    const updatedIdentifier = {
      ...identifier,
      rule: {
        ...identifier.rule,
        keyPattern: tags
      }
    };
    handleChange(Action.MODIFY, updatedIdentifier);
  };

  const handleLabelChange = (name: string, value: string): void => {
    const updatedIdentifier = {
      ...identifier,
      name: value
    };
    handleChange(Action.MODIFY, updatedIdentifier);
  };

  const handleDeletion = (): void => {
    handleChange(Action.REMOVE, identifier);
  };

  const handleCheckboxChange = (name: string, checked: boolean): void => {
    const updated = {
      ...identifier,
      enforceUniqueness: checked
    };
    handleChange(Action.MODIFY, updated);
  };

  return (
    <div className="flex flex-row items-center justify-between space-x-2">
      <span className="pl-2">{identifier.rule.operator}</span>
      <CustomTextInputV3
        type="text"
        name={identifier.name}
        value={identifier.name}
        handleChange={handleLabelChange}
        label=""
      />
      {identifier.rule.operator === GlobalIdentifierOperator.STRING_MATCH && (
        <CustomMultiTextInputV2
          label=""
          name={identifier.name}
          values={identifier.rule.keyPattern}
          handleChange={handleTagChange}
        />
      )}

      {identifier.rule.operator === GlobalIdentifierOperator.KEYLESS_SEARCH_BY_POSITION && (
        <input type="number" name={`sections.0.value`} />
      )}

      <CustomCheckboxInputV2
        name="has"
        label=""
        value={identifier.enforceUniqueness}
        handleChange={handleCheckboxChange}
      />

      <button className="justify-center rounded-md px-3 py-2 text-sm" onClick={handleDeletion}>
        <AiOutlineMinusCircle size={24} color="red" />
      </button>
    </div>
  );
};

export default GlobalIdentifierComponent;
