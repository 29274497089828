export interface LineItem {
  name: string;
  unitPrice: number;
  quantity: number;
  totalPrice: number;
}

export interface ParsedGlobalIdentifiers {
  key: string;
  value: string;
}

export interface ParsedReceiptInternal {
  globalIdentifiers: ParsedGlobalIdentifiers[];
  lineItems: LineItem[];
}

export enum ParseStatus {
  SKIPPED = 'skipped',
  PARTIALLY_PARSED = 'partial',
  ALL_FIELDS_PARSED = 'all',
  NO_FIELDS_PARSED = 'none'
}
export interface ParsedReceipt {
  status: ParseStatus;
  parsedItems: ParsedReceiptInternal;
  error: string[];
}

export enum ReceiptParseStatus {
  NOT_YET_STARTED = 'not_yet_started',
  QUEUED = 'queued',
  IN_PROGRESS = 'in_progress',
  SUCCESS = 'success',
  ERROR = 'error'
}

export interface ReceiptProcessorResult {
  receiptId: string;
  status: ReceiptParseStatus;
  sentToExternalService: boolean;
  parsedResult: ParsedReceipt;
  reason: string;
}

export interface BatchProcessErrorResult {
  id: string;
  status: ReceiptParseStatus;
  reason: string;
}

export interface BatchProcessDto {
  stats: {
    totalProcessed: number;
    success: number;
    failed: number;
    skipped: number;
  };
  data: BatchProcessErrorResult[];
}
