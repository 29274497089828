import { FaAngleLeft, FaBox, FaClipboardList, FaCog, FaReceipt,FaSignOutAlt, FaStore, FaTachometerAlt, FaUniversity, FaUser, FaUsers, FaWarehouse } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { useAppDispatch } from '../../app/hooks/hook';
import { apiSlice } from '../../app/services/api-slice';
import footerLogo from '../../assets/footerLogo.webp';
import { logout } from '../../auth/auth.slice';
import { UserRole } from '../../common/constants';
import { type UserInToken } from '../../common/types';

interface MenuItem {
  label: string;
  testId: string;
  icon: JSX.Element;
  type: 'link' | 'button';
  link?: string;
  handler?: () => any;
}

interface IProps {
  authUser: UserInToken;
  open: boolean;
  setOpen: () => void;
}

const Sidebar: React.FC<IProps> = ({ authUser, open, setOpen }) => {
  const dispatch = useAppDispatch();

  const handleLogout = (): void => {
    dispatch(logout());
    dispatch(apiSlice.util.resetApiState());
  };

  const menus: MenuItem[] = [
    {
      label: 'Dashboard',
      type: 'link',
      testId: 'menu-dashboard',
      icon: <FaTachometerAlt size={20} />,
      link: '/admin/dashboard'
    }
  ];

  if (authUser.role === UserRole.SUPER_ADMIN) {
    menus.push({
      label: 'Agency',
      type: 'link',
      testId: 'menu-agency',
      icon: <FaUniversity size={20} />,
      link: '/admin/agencies'
    });
  }

  if (authUser.role === UserRole.SUPER_ADMIN || authUser.role === UserRole.AGENCY_ADMIN) {
    menus.push({
      label: 'Merchant',
      testId: 'menu-merchant',
      type: 'link',
      icon: <FaWarehouse size={20} />,
      link: '/admin/merchants'
    });
  }

  menus.push(
    { label: 'Store', type: 'link', testId: 'menu-store', icon: <FaStore size={20} />, link: '/admin/stores' },
    { label: 'User', type: 'link', testId: 'menu-user', icon: <FaUsers size={20} />, link: '/admin/users' },
    { label: 'Device', type: 'link', testId: 'menu-device', icon: <FaBox size={20} />, link: '/admin/devices' },
    { label: 'Rule', type: 'link', testId: 'menu-rule', icon: <FaClipboardList size={20} />, link: '/admin/rules' },

    {
      label: 'API Config',
      type: 'link',
      testId: 'menu-api-config',
      icon: <FaCog size={20} />,
      link: '/admin/api-configs'
    },
    { label: 'Receipt', type: 'link', testId: 'menu-receipt', icon: <FaReceipt size={20} />, link: '/admin/receipts' },
    {
      label: 'Sample Receipt',
      type: 'link',
      testId: 'menu-sample-receipt',
      icon: <FaReceipt size={20} />,
      link: '/admin/sample-receipts'
    },
    {
      label: 'Profile',
      type: 'link',
      testId: 'menu-profile',
      icon: <FaUser size={20} />,
      link: '/admin/profile'
    },
    {
      label: 'Logout',
      type: 'button',
      testId: 'menu-logout',
      icon: <FaSignOutAlt size={20} />,
      handler: handleLogout
    }
  );

  return (
    <>
      <FaAngleLeft
        onClick={setOpen}
        className={`absolute -right-3 top-9 w-7 cursor-pointer rounded-full border-2 border-sidebarBlack bg-white ${
          !open ? 'rotate-180' : ''
        }`}
        size={32}
      />
      <div className="flex items-center gap-x-4 text-white">
        <img src={footerLogo} alt="Logo" width={`${open ? '' : 48}`} className="duration-300" />
      </div>
      <ul className="pt-6">
        {menus.map((menu, index) => (
          <div key={index}>
            {menu.type === 'button' && (
              <li
                data-cy={menu.testId}
                onClick={menu.handler}
                className="text-md mt-4 flex cursor-pointer items-center gap-x-4 rounded-md p-2 text-gray-300 hover:bg-primary"
              >
                {menu.icon}
                <span className={`${!open ? 'hidden' : ''} origin-left duration-300`}>{menu.label}</span>
              </li>
            )}
            {menu.type === 'link' && menu.link !== undefined && (
              <Link to={menu.link}>
                <li
                  data-cy={menu.testId}
                  key={index}
                  className="text-md mt-4 flex cursor-pointer items-center gap-x-4 rounded-md p-2 text-gray-300 hover:bg-primary"
                >
                  {menu.icon}
                  <span className={`${!open ? 'hidden' : ''} origin-left duration-300`}>{menu.label}</span>
                </li>
              </Link>
            )}
          </div>
        ))}
      </ul>
    </>
  );
};

export default Sidebar;
