import { apiSlice } from '../../app/services/api-slice';
import { urlWithPageAndTerm } from '../../common/helpers';
import {
  type ApiSuccessResponse,
  type ListWithIdPaginationAndSearchReq,
  type ListWithPaginationAndSearchReq,
  type MerchantStore,
  type MerchantStoreRequest,
  type PaginatedData,
  type PaginatedMeta,
  type ReceiptRule,
  type UpdateMerchantStore
} from '../../common/types';

const tagName: string = 'MerchantStores';

const merchantStoreApi = apiSlice
  .enhanceEndpoints({
    addTagTypes: [tagName]
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getStores: builder.query<PaginatedData<MerchantStore>, ListWithPaginationAndSearchReq>({
        query: ({ term = '', page = 1, pendingOnly = false }): string => {
          return urlWithPageAndTerm('stores', { term, page, pendingOnly });
        },
        transformResponse: (data: ApiSuccessResponse) => {
          return {
            data: data.data.data as MerchantStore[],
            meta: data.data.meta as PaginatedMeta
          };
        },
        providesTags: (result) =>
          result != null
            ? [
                ...result.data.map(({ id }) => ({
                  type: tagName,
                  id
                })),
                { type: tagName, id: 'LIST' }
              ]
            : [{ type: tagName, id: 'LIST' }]
      }),
      getListOfStores: builder.query<MerchantStore[], string>({
        query: (id: string): string => `/stores/short-list/?merchantId=${id}`,
        transformResponse: (data: ApiSuccessResponse) => {
          return data.data as MerchantStore[];
        },
        providesTags: (result) =>
          result != null
            ? [
                ...result.map(() => ({
                  type: tagName
                })),
                { type: tagName, id: 'LIST_SHORT' }
              ]
            : [{ type: tagName, id: 'LIST_SHORT' }]
      }),
      getStoresForAgency: builder.query<PaginatedData<MerchantStore>, ListWithIdPaginationAndSearchReq>({
        query: ({ id, term = '', page = 1 }): string => {
          return urlWithPageAndTerm(`stores/by-agency/${id}`, { term, page });
        },
        transformResponse: (data: ApiSuccessResponse) => {
          return {
            data: data.data.data as MerchantStore[],
            meta: data.data.meta as PaginatedMeta
          };
        },
        providesTags: (result) =>
          result != null
            ? [
                ...result.data.map(({ id }) => ({
                  type: tagName,
                  id
                })),
                { type: tagName, id: 'LIST' }
              ]
            : [{ type: tagName, id: 'LIST' }]
      }),
      getStoresForMerchant: builder.query<PaginatedData<MerchantStore>, ListWithIdPaginationAndSearchReq>({
        query: ({ id, term = '', page = 1 }): string => {
          return urlWithPageAndTerm(`stores/by-merchant/${id}`, { term, page });
        },
        transformResponse: (data: ApiSuccessResponse) => {
          return {
            data: data.data.data as MerchantStore[],
            meta: data.data.meta as PaginatedMeta
          };
        },
        providesTags: (result) =>
          result != null
            ? [
                ...result.data.map(({ id }) => ({
                  type: tagName,
                  id
                })),
                { type: tagName, id: 'LIST' }
              ]
            : [{ type: tagName, id: 'LIST' }]
      }),
      getStoreById: builder.query<MerchantStore, string>({
        query: (id: string) => `/stores/${id}`,
        transformResponse: (data: ApiSuccessResponse) => {
          return data.data as MerchantStore;
        },
        providesTags: (result) => (result != null ? [{ type: tagName, id: result?.id }] : [])
      }),
      getStoreDefaultPolicy: builder.query<ReceiptRule, string>({
        query: (id: string) => `/receipts/${id}/get-default-rule`,
        transformResponse: (data: ApiSuccessResponse) => {
          return data.data as ReceiptRule;
        }
      }),
      addStore: builder.mutation({
        query: (merchant: MerchantStoreRequest) => ({
          url: '/stores',
          method: 'POST',
          body: merchant
        }),
        invalidatesTags: [{ type: tagName, id: 'LIST' }]
      }),
      notifyStore: builder.mutation({
        query: (id: string) => ({
          url: `/stores/${id}/notify`,
          method: 'POST'
        })
      }),
      updateStore: builder.mutation<MerchantStore, UpdateMerchantStore>({
        query: ({ id, body }) => ({
          url: `/stores/${id}`,
          method: 'PATCH',
          body
        }),
        invalidatesTags: (result, error, arg) => [{ type: tagName, id: arg.id }]
      }),
      approveStore: builder.mutation<MerchantStore, string>({
        query: (id) => ({
          url: `/stores/${id}/approve`,
          method: 'POST'
        }),
        invalidatesTags: (result, error, arg) => [{ type: tagName, id: arg }]
      }),
      deleteStoreById: builder.mutation<void, string>({
        query: (id: string) => ({
          url: `/stores/${id}`,
          method: 'DELETE'
        }),
        invalidatesTags: (result, error, arg) => [{ type: tagName, id: 'LIST' }]
      })
    }),
    overrideExisting: false
  });

export const {
  useGetStoresQuery,
  useLazyGetListOfStoresQuery,
  useGetStoresForAgencyQuery,
  useGetStoresForMerchantQuery,
  useGetStoreByIdQuery,
  useAddStoreMutation,
  useUpdateStoreMutation,
  useApproveStoreMutation,
  useDeleteStoreByIdMutation,
  useNotifyStoreMutation
} = merchantStoreApi;
export default merchantStoreApi;
