import { AiOutlineEdit } from 'react-icons/ai';

interface IProps {
  storeId: string;
  toggleModal: () => any;
}

const DEFAULT_NOT_EXIST_MSG = 'No default api config exists for this store.';
const ADD_DEFAULT_BUTTON_LABEL = 'Add Default API Config';

const NoDataContainer: React.FC<IProps> = ({ toggleModal }) => {
  return (
    <div onClick={toggleModal} className="flex h-full w-full flex-col items-center justify-center">
      <span className="text-lg">{DEFAULT_NOT_EXIST_MSG}</span>
      <div className="space-between flex items-center justify-center rounded-lg bg-greenColor p-2 text-center  text-gray-50">
        <AiOutlineEdit size={20} />
        <button className="px-2 text-sm">{ADD_DEFAULT_BUTTON_LABEL}</button>
      </div>
    </div>
  );
};

export default NoDataContainer;
