import { apiSlice } from '../../app/services/api-slice';
import { type OwnerType } from '../../common/constants';
import { urlWithPageAndTerm } from '../../common/helpers';
import type {
  ApiSuccessResponse,
  ListWithPaginationAndSearchReq,
  PaginatedData,
  PaginatedMeta,
  ReceiptRuleV2CreateRequest,
  ReceiptRuleV2UpdateRequest
} from '../../common/types';
import { type AssignExistingReceiptRulePayload, type ReceiptRuleV2Dto } from './types';

interface OwnerTypeWithPageReqParam {
  ownerEntityId: string;
  ownerEntityType: OwnerType;
  page?: number;
}

const tagName: string = 'ReceiptRule';

export const receiptRuleApi = apiSlice
  .enhanceEndpoints({
    addTagTypes: [tagName]
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getAllReceiptRules: builder.query<PaginatedData<ReceiptRuleV2Dto> | null, ListWithPaginationAndSearchReq>({
        query: ({ term = '', page = 1 }): string => {
          return urlWithPageAndTerm('/rules', { term, page });
        },
        transformResponse: (data: ApiSuccessResponse) => {
          if (data.data === undefined) {
            return null;
          }
          return {
            data: data.data.data as ReceiptRuleV2Dto[],
            meta: data.data.meta as PaginatedMeta
          };
        },
        providesTags: (result) =>
          result != null
            ? [
                ...result.data.map(({ id }) => ({
                  type: tagName,
                  id
                })),
                { type: tagName, id: 'LIST' }
              ]
            : [{ type: tagName, id: 'LIST' }]
      }),
      getReceiptRuleById: builder.query<ReceiptRuleV2Dto, string>({
        query: (ruleId) => `/rules/${ruleId}`,
        transformResponse: (data: ApiSuccessResponse) => {
          return data.data as ReceiptRuleV2Dto;
        },
        providesTags: (result) => (result != null ? [{ type: tagName, id: result?.id }] : [])
      }),
      getReceiptRuleByOwner: builder.query<ReceiptRuleV2Dto, OwnerTypeWithPageReqParam>({
        query: ({ ownerEntityType, ownerEntityId }) => `/rules/${ownerEntityType}/${ownerEntityId}`,
        transformResponse: (data: ApiSuccessResponse) => {
          return data.data as ReceiptRuleV2Dto;
        },
        providesTags: (result) => (result != null ? [{ type: tagName, id: result?.id }] : [])
      }),

      getReceiptRulesByOwner: builder.query<PaginatedData<ReceiptRuleV2Dto> | null, OwnerTypeWithPageReqParam>({
        query: ({ ownerEntityId, ownerEntityType, page = 1 }): string => {
          return urlWithPageAndTerm(`/rules/${ownerEntityType}/${ownerEntityId}`, { page });
        },
        transformResponse: (data: ApiSuccessResponse) => {
          if (data.data === undefined) {
            return null;
          }
          return {
            data: data.data.data as ReceiptRuleV2Dto[],
            meta: data.data.meta as PaginatedMeta
          };
        },
        providesTags: (result) =>
          result != null
            ? [
                ...result.data.map(({ id }) => ({
                  type: tagName,
                  id
                })),
                { type: tagName, id: 'LIST' }
              ]
            : [{ type: tagName, id: 'LIST' }]
      }),
      updateRule: builder.mutation<ReceiptRuleV2Dto, ReceiptRuleV2UpdateRequest>({
        query: ({ id, body }) => ({
          url: `/rules/${id}`,
          method: 'PATCH',
          body
        }),
        transformResponse: (data: ApiSuccessResponse) => {
          return data.data as ReceiptRuleV2Dto;
        }
      }),
      addRule: builder.mutation<ReceiptRuleV2Dto, ReceiptRuleV2CreateRequest>({
        query: (body) => ({
          url: `/rules`,
          method: 'POST',
          body
        }),
        transformResponse: (data: ApiSuccessResponse) => {
          return data.data as ReceiptRuleV2Dto;
        }
      }),
      deleteReceiptRuleById: builder.mutation<any, string>({
        query: (id: string) => ({
          url: `/rules/${id}`,
          method: 'DELETE'
        }),
        invalidatesTags: (result, error, arg) => [{ type: tagName, id: 'LIST' }]
      }),
      assignExistingReceiptRule: builder.mutation<ReceiptRuleV2Dto, AssignExistingReceiptRulePayload>({
        query: (body) => ({
          url: `/rules/assign`,
          method: 'POST',
          body
        }),
      }),
      importSampleBonusItemsFile: builder.mutation<ReceiptRuleV2Dto, {id: string, file: File}>({
        query: ({ id, file }) => {
          const formData = new FormData();
          formData.append('file', file);
         
          return {
            url: `/rules-bonus-items/${id}`,
            method: 'POST',
            body: formData,
            formData: true
          }
        },
        transformResponse: (data: ApiSuccessResponse) => {
          return data.data as ReceiptRuleV2Dto;
        }
      }),
    })
  });

export const {
  useGetAllReceiptRulesQuery,
  useGetReceiptRuleByIdQuery,
  useGetReceiptRuleByOwnerQuery,
  useGetReceiptRulesByOwnerQuery,
  useUpdateRuleMutation,
  useAddRuleMutation,
  useDeleteReceiptRuleByIdMutation,
  useAssignExistingReceiptRuleMutation,
  useImportSampleBonusItemsFileMutation,
} = receiptRuleApi;
export default receiptRuleApi;
