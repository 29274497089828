import {Link} from "react-router-dom";

import {type TopDeviceData} from '../../../common/types';
import EmptyState from "../../../components/EmptyView";

interface IProps {
    topDeviceData: TopDeviceData[] | null;
}

const TopDeviceDashboard: React.FC<IProps> = ({ topDeviceData }) => {
    if (topDeviceData == null || topDeviceData.length === 0) {
        return <EmptyState message="No Data" />;
    }
    return (
        <div className="my-2 flex w-full flex-col space-y-2 overflow-auto border-b-gray-50 p-2">
            <div
                className="flex w-full items-center justify-between justify-items-center rounded-md bg-gray-50 p-4 text-lg font-bold"
            >
                <span>Device Name</span>
                <span>Receipt</span>
            </div>
            {topDeviceData.map((data) => {
                return (
                    <div
                        key={`recent-device-${data.deviceId}`}
                        className="flex w-full items-center justify-between justify-items-center rounded-md bg-gray-50 p-4 hover:text-primary"
                    >
                        <Link to={`/admin/devices/${data.deviceId}/receipts`}>
                            {data.deviceName}
                        </Link>
                        <span>{data.receiptCount}</span>
                    </div>
                );
            })}
        </div>
    );
};

export default TopDeviceDashboard;
