import { AiOutlineCheckCircle, AiOutlineExclamationCircle } from 'react-icons/ai';

interface IProps {
  message: string;
  type?: 'success' | 'error';
}

const SuccessMessage: React.FC<IProps> = ({ message, type = 'error' }) => {
  return (
    <div className="mx-auto flex h-full min-h-[65vh] w-full flex-col items-center justify-center text-center">
      {type === 'success' && (
        <span>
          <AiOutlineCheckCircle size={96} color="green" />
        </span>
      )}
      {type === 'error' && (
        <span>
          <AiOutlineExclamationCircle size={96} color="red" />
        </span>
      )}
      <p className="text-3xl">{message}</p>
    </div>
  );
};

export default SuccessMessage;
