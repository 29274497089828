import { type Middleware } from '@reduxjs/toolkit';

import { login, logout } from '../../auth/auth.slice';
import { AUTH_TOKEN_NAME } from '../../common/constants';

export const sessionStorageMiddleware: Middleware = ({ getState }) => {
  return (next) => (action) => {
    const result = next(action);

    if (action.type === logout.type) {
      // console.log('Removing the token to sessionStorage');
      sessionStorage.removeItem(AUTH_TOKEN_NAME);
    }

    if (action.type === login.type) {
      // console.log('Saving the token to sessionStorage');
      sessionStorage.setItem(AUTH_TOKEN_NAME, action.payload.token);
    }

    return result;
  };
};
