import React, {type ReactElement} from 'react';
import { Route, Routes } from 'react-router-dom';

import AgencyDashboardPage from './admin/agencies/pages/AgencyDashboardPage';
import ListAgenciesPage from './admin/agencies/pages/ListAgenciesPage';
import AddOrEditConfiguration from './admin/configuration/pages';
import Dashboard from './admin/dashboard/dashboard';
import DeviceIndexPage from './admin/devices/pages/DeviceIndexPage';
import ListDevicesForAgency from './admin/devices/pages/ListDevicesForAgency';
import ListDevicesForMerchantPage from './admin/devices/pages/ListDevicesForMerchant';
import ListDevicesForStorePage from './admin/devices/pages/ListDevicesForStorePage';
import ListDevicesPage from './admin/devices/pages/ListDevicesPage';
import ListMerchantsForAgencyPage from './admin/merchants/pages/ListMerchantsForAgencyPage';
import ListMerchantsPage from './admin/merchants/pages/ListMerchantsPage';
import MerchantDashboardPage from './admin/merchants/pages/MerchantDashboardPage';
import ListReceiptRules from './admin/ReceiptRule/pages/ListReceiptRulesPage';
import ReceiptRuleCopyPage from './admin/ReceiptRule/pages/ReceiptRuleCopyPage';
import AddOrEditReceiptRule from './admin/receipts/pages/AddOrEditReceiptRule';
import ListReceiptsPage from './admin/receipts/pages/ListReceipts';
import ListReceiptsForAgency from './admin/receipts/pages/ListReceiptsForAgency';
import ListReceiptsForDevice from './admin/receipts/pages/ListReceiptsForDevice';
import ListReceiptsForMerchant from './admin/receipts/pages/ListReceiptsForMerchant';
import ListReceiptsForStore from './admin/receipts/pages/ListReceiptsForStore';
import ReceiptRuleTestPage from './admin/receipts/pages/ReceiptRuleTestPage';
import ListDeviceSampleReceiptsPage from './admin/sample-receipts/pages/ListDeviceSampleReceiptsPage';
import ListSampleReceiptsPage from './admin/sample-receipts/pages/ListSampleReceiptsPage';
import ListStoreSampleReceiptsPage from './admin/sample-receipts/pages/ListStoreSampleReceiptsPage';
import ListApiConfigsPage from './admin/sticky-config/pages/ListApiConfigsPage';
import AddStoreForMerchant from './admin/stores/pages/AddStoreForMerchant';
import StoreDetail from './admin/stores/pages/DetailStore';
import ListStoresForAgency from './admin/stores/pages/ListStoresForAgency';
import ListStoresForMerchantPage from './admin/stores/pages/ListStoresForMerchant';
import ListStoresPage from './admin/stores/pages/ListStoresPage';
import AddStoreUserPage from './admin/users/pages/AddStoreUserPage';
import ListUsersForAgencyPage from './admin/users/pages/ListUsersForAgencyPage';
import ListUsersForMerchantPage from './admin/users/pages/ListUsersForMerchantPage';
import ListUsersForStorePage from './admin/users/pages/ListUsersForStorePage';
import ListUsersPage from './admin/users/pages/ListUsersPage';
import MyProfile from './admin/users/pages/ProfilePage';
import AdminPage from './AdminPage';
import AgencyRegisterForm from './auth/agency-register.form';
import UserLoginForm from './auth/user-login.form';
import HomePage from './HomePage';
import LandingPage from './LandingPage';
import { AgencyAdminOutlet } from './utils/AgencyAdminOutlet';
import { PrivateOutlet } from './utils/private-outlet';
import { SuperAdminOutlet } from './utils/SuperAdminOutlet';
import ReceiptRuleBonusItemsPage from './admin/ReceiptRule/pages/ReceiptRuleBonusItemsPage';

const AppRoutes = (): ReactElement => {
  return (
    <Routes>
      <Route path="login" element={<UserLoginForm />} />
      <Route path="/register" element={<AgencyRegisterForm />} />
      <Route path="/" element={<LandingPage />}>
        <Route path="" element={<HomePage />} />
      </Route>
      <Route path="/admin" element={<PrivateOutlet />}>
        <Route path="" element={<AdminPage />}>
          <Route path="" element={<Dashboard />} />
          <Route path="dashboard" element={<Dashboard />} />

          <Route path="agencies" element={<SuperAdminOutlet />}>
            <Route path="" element={<ListAgenciesPage />} />
            <Route path="pending-requests" element={<ListAgenciesPage filter={{ pending: true }} />} />
            <Route path=":id/merchants" element={<ListMerchantsForAgencyPage pendingOnly={false} />} />
            <Route path=":id/stores" element={<ListStoresForAgency />} />
            <Route path=":id/devices" element={<ListDevicesForAgency />} />
            <Route path=":id/users" element={<ListUsersForAgencyPage pendingOnly={false} />} />
            <Route path=":id/receipts" element={<ListReceiptsForAgency />} />
            <Route path=":id" element={<AgencyDashboardPage />} />
          </Route>

          <Route path="merchants" element={<AgencyAdminOutlet />}>
            <Route path="" element={<ListMerchantsPage />} />
            <Route path="pending-requests" element={<ListMerchantsPage filter={{ pending: true }} />} />
            <Route path=":id/stores/new" element={<AddStoreForMerchant />} />
            <Route path=":id/stores" element={<ListStoresForMerchantPage />} />
            <Route path=":id/devices" element={<ListDevicesForMerchantPage />} />
            <Route path=":id/users" element={<ListUsersForMerchantPage pendingOnly={false} />} />
            <Route path=":id/pending-users" element={<ListUsersForMerchantPage pendingOnly={true} />} />
            <Route path=":id/receipts" element={<ListReceiptsForMerchant />} />
            <Route path=":id" element={<MerchantDashboardPage />} />
          </Route>

          <Route path="stores" element={<PrivateOutlet />}>
            <Route index element={<ListStoresPage />} />
            <Route path="pending-requests" element={<ListStoresPage filter={{ pending: true }} />} />
            <Route path=":id/devices" element={<ListDevicesForStorePage />} />
            <Route path=":id" element={<StoreDetail />} />
            <Route path=":id/users" element={<ListUsersForStorePage />} />
            <Route path=":id/sample-receipts" element={<ListStoreSampleReceiptsPage />} />
            <Route path=":id/users/new" element={<AddStoreUserPage />} />
            <Route path=":id/receipts" element={<ListReceiptsForStore />} />
          </Route>

          <Route path="rules" element={<PrivateOutlet />}>
            <Route path=":ownerEntityType/:ownerEntityId" element={<AddOrEditReceiptRule />} />
            <Route path=":ruleId/test" element={<ReceiptRuleTestPage />} />
            <Route path=":ruleId/copy" element={<ReceiptRuleCopyPage />} />
            <Route path=":ruleId/bonus-items" element={<ReceiptRuleBonusItemsPage />} />
          </Route>

          <Route path="configurations/:ownerEntityType/:ownerEntityId" element={<AddOrEditConfiguration />} />

          <Route path="api-configs" element={<PrivateOutlet />}>
            <Route index element={<ListApiConfigsPage />} />
          </Route>

          <Route path="sample-receipts" element={<PrivateOutlet />}>
            <Route index element={<ListSampleReceiptsPage filter="all" />} />
          </Route>

          <Route path="rules" element={<PrivateOutlet />}>
            <Route index element={<ListReceiptRules filter="all" />} />
          </Route>

          <Route path="devices" element={<DeviceIndexPage />}>
            <Route index element={<ListDevicesPage />} />
            <Route path="pending-requests" element={<ListDevicesPage filter={{ pending: true }} />} />
            <Route path=":id/sample-receipts" element={<ListDeviceSampleReceiptsPage />} />
            <Route path=":id/receipts" element={<ListReceiptsForDevice />} />
          </Route>

          <Route path="users" element={<ListUsersPage />} />
          <Route path="users/pending-requests" element={<ListUsersPage filter={{ pending: true }} />} />
          <Route path="profile" element={<MyProfile />} />
          <Route path="receipts" element={<ListReceiptsPage filter="all" />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default AppRoutes;
