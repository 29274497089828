import { type FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { type OwnerEntityType } from '../../../common/constants';
import { getAgencyAndMerchantIdFromOwnerEntity } from '../../../common/helpers';
import {
  Action,
  type GeneralComponentList,
  type GeneralComponentListKeys,
  type GlobalIdentifierObject,
  type LineItemComponentV2,
  type Rule,
  type Section,
  type SpecialPointProduct} from '../../../common/types';
import Breadcrumb from '../../../components/Breadcrumb';
import CustomTextInputV3 from '../../../components/Form/CustomTextInputV3';
import HorizontalDivider from '../../devices/components/HorizontalDivider';
import { useAddRuleMutation, useUpdateRuleMutation } from '../../ReceiptRule/receipt-rule.api-slice';
import BlacklistComponentBuilder from '../components/ReceiptBuilderV2/Blacklist/BlacklistComponentBuilder';
import GeneralComponentBuilder from '../components/ReceiptBuilderV2/GeneralComponentBuilder';
import GlobalIdentifiers from '../components/ReceiptBuilderV2/GlobalIdentifiers';
import LineItemComponentBuilder from '../components/ReceiptBuilderV2/LineItemComponentBuilder';
import SectionBuilder from '../components/ReceiptBuilderV2/SectionBuilder';
import SpecialPointComponentBuilder from '../components/ReceiptBuilderV2/SpecialPointProduct/SpecialPointComponentBuilder';
import { FaFileImport } from 'react-icons/fa';

interface IProps {
  ownerEntityType: string;
  ownerEntity: OwnerEntityType;
  cancelUrl: string;
  rule: Rule;
  id?: string;
  name?: string;
}

const ReceiptRuleBuilderPage: React.FC<IProps> = ({
  ownerEntityType,
  ownerEntity,
  rule: {
    generalComponents,
    globalIdentifiers,
    id,
    lineItemComponents,
    name,
    sections,
    blacklists,
    specialPointProducts = []
  }
}) => {
  const navigate = useNavigate();
  const [addResource] = useAddRuleMutation();
  const [updateRule, { isSuccess }] = useUpdateRuleMutation();

  const { t } = useTranslation();
  const [currentRuleName, setCurrentRuleName] = useState<string>(name);
  const [currentGeneralComponents, setCurrentGeneralComponents] = useState<GeneralComponentList>(generalComponents);
  const [currentGlobalIdentifiers, setCurrentGlobalIdentifiers] = useState<GlobalIdentifierObject[]>(globalIdentifiers);
  const [currentSections, setCurrentSections] = useState<Section[]>(sections);
  const [currentLineItemComponents, setCurrentLineItemComponents] = useState<LineItemComponentV2[]>(lineItemComponents);
  const [currentBlacklists, setCurrentBlacklists] = useState<LineItemComponentV2[]>(blacklists);
  const [currentSpecialPointProducts, setCurrentSpecialPointProducts] =
    useState<SpecialPointProduct[]>(specialPointProducts);

  const handleGeneralComponentChange = (componentName: string, tokens: string[]): void => {
    const componentKey = componentName as GeneralComponentListKeys;
    setCurrentGeneralComponents((prevState) => ({
      ...prevState,
      [componentName]: {
        ...prevState[componentKey],
        tokens
      }
    }));
  };

  const handleGlobalIdentifierChange = (action: Action, value: GlobalIdentifierObject): void => {
    if (action === Action.ADD) {
      setCurrentGlobalIdentifiers([...currentGlobalIdentifiers, value]);
    }

    if (action === Action.MODIFY) {
      setCurrentGlobalIdentifiers((prevState) =>
        prevState.map((identifier) => (identifier.id === value.id ? value : identifier))
      );
    }

    if (action === Action.REMOVE) {
      setCurrentGlobalIdentifiers((prevState) => prevState.filter((identifier) => identifier.id !== value.id));
    }
  };

  const handleRuleNameChange = (name: string, value: string): void => {
    if (value !== '') {
      setCurrentRuleName(value);
    }
  };

  const handleSectionChange = (action: Action, value: Section): void => {
    if (action === Action.ADD) {
      setCurrentSections([...currentSections, value]);
    }

    if (action === Action.MODIFY) {
      setCurrentSections((prevState) => prevState.map((section) => (section.id === value.id ? value : section)));
    }

    if (action === Action.REMOVE) {
      setCurrentSections((prevState) => prevState.filter((section) => section.id !== value.id));
    }
  };

  const handleLineItemChange = (action: Action, lineItemComponent: LineItemComponentV2): void => {
    if (action === Action.ADD) {
      // need to prevent duplication
      const lineItemAlreadyExists = currentLineItemComponents.find((item) => item.name === lineItemComponent.name);
      if (lineItemAlreadyExists == null) {
        setCurrentLineItemComponents([...currentLineItemComponents, lineItemComponent]);
      } else {
        toast.warning('LineItem with this name already exists');
      }
    }

    if (action === Action.MODIFY) {
      setCurrentLineItemComponents((prevState) =>
        prevState.map((componentInLoop) =>
          componentInLoop.id === lineItemComponent.id ? lineItemComponent : componentInLoop
        )
      );
    }

    if (action === Action.REMOVE) {
      setCurrentLineItemComponents((prevState) =>
        prevState.filter((componentInLoop) => componentInLoop.id !== lineItemComponent.id)
      );
    }
  };

  const handleBlacklistChange = (action: Action, lineItemComponent: LineItemComponentV2): void => {
    if (action === Action.ADD) {
      // need to prevent duplication
      const lineItemAlreadyExists = currentBlacklists.find((item) => item.name === lineItemComponent.name);
      if (lineItemAlreadyExists == null) {
        setCurrentBlacklists([...currentBlacklists, lineItemComponent]);
      } else {
        toast.warning('Blacklist item with this name already exists');
      }
    }

    if (action === Action.MODIFY) {
      setCurrentBlacklists((prevState) =>
        prevState.map((componentInLoop) =>
          componentInLoop.id === lineItemComponent.id ? lineItemComponent : componentInLoop
        )
      );
    }

    if (action === Action.REMOVE) {
      setCurrentBlacklists((prevState) =>
        prevState.filter((componentInLoop) => componentInLoop.id !== lineItemComponent.id)
      );
    }
  };

  const handleSpecialPointProductChange = (action: Action, specialPointProduct: SpecialPointProduct): void => {
    // console.log('trying to validate dates')
    // // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // const startDate = parseYMDDate(specialPointProduct.startDate)
    // // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // const endDate = parseYMDDate(specialPointProduct.endDate)
    // if ((startDate == null) || (endDate == null)) {
    //   toast.warning('Invalid Start or End date');
    //   return;
    // }
    if (action === Action.ADD) {
      // need to prevent duplication
      const itemAlreadyExist = currentSpecialPointProducts.find((item) => item.name === specialPointProduct.name);
      if (itemAlreadyExist == null) {
        setCurrentSpecialPointProducts([...currentSpecialPointProducts, specialPointProduct]);
      } else {
        toast.warning('Special Point item with this name already exists');
      }
    }

    if (action === Action.MODIFY) {
      setCurrentSpecialPointProducts((prevState) =>
        prevState.map((componentInLoop) =>
          componentInLoop.id === specialPointProduct.id ? specialPointProduct : componentInLoop
        )
      );
    }

    if (action === Action.REMOVE) {
      setCurrentSpecialPointProducts((prevState) =>
        prevState.filter((componentInLoop) => componentInLoop.id !== specialPointProduct.id)
      );
    }
  };

  const handleSubmit = (event: FormEvent): void => {
    event.preventDefault();

    if (currentRuleName === '') {
      toast.warning('Rule name is required');
      return;
    }

    if (currentSections.length === 0) {
      toast.warning('At least one section is required');
      return;
    }

    if (currentLineItemComponents.length === 0) {
      toast.warning('At least one line item component is required');
      return;
    }
    const { agencyId, merchantId } = getAgencyAndMerchantIdFromOwnerEntity(ownerEntityType, ownerEntity);
    const reorderedLineItemComponents = currentLineItemComponents.map((lineItemComponent, index) => {
      return {
        ...lineItemComponent,
        rule: {
          ...lineItemComponent.rule,
          order: ++index
        }
      };
    });

    const commonRuleObject: Rule = {
      name: currentRuleName,
      generalComponents: currentGeneralComponents,
      globalIdentifiers: currentGlobalIdentifiers,
      sections: currentSections,
      lineItemComponents: reorderedLineItemComponents,
      blacklists: currentBlacklists,
      specialPointProducts: currentSpecialPointProducts
    };
    if (id != null) {
      void updateRule({ id, body: commonRuleObject });
    } else {
      const ruleObj = {
        ...commonRuleObject,
        ownerEntityType,
        ownerEntityId: ownerEntity.id,
        agencyId,
        merchantId
      };
      void addResource(ruleObj);
      toast('Rule is successfully attached');
    }
  };

  const handleReOrderSection = (sourceIndex: number, destinationIndex: number): void => {
    const result = Array.from(currentSections);
    const [removed] = result.splice(sourceIndex, 1);
    result.splice(destinationIndex, 0, removed);

    setCurrentSections(result);
  };

  const handleReOrderLineItem = (sourceIndex: number, destinationIndex: number): void => {
    const result = Array.from(currentLineItemComponents);
    const [removed] = result.splice(sourceIndex, 1);
    result.splice(destinationIndex, 0, removed);

    const reorderedLineItems = result.map((result, index) => ({
      ...result,
      rule: { ...result.rule, order: index + 1 }
    }));

    setCurrentLineItemComponents(reorderedLineItems);
  };

  const handleReOrderBlacklist = (sourceIndex: number, destinationIndex: number): void => {
    const result = Array.from(currentBlacklists);
    const [removed] = result.splice(sourceIndex, 1);
    result.splice(destinationIndex, 0, removed);

    const reorderedBlackLists = result.map((result, index) => ({
      ...result,
      rule: { ...result.rule, order: index + 1 }
    }));

    setCurrentBlacklists(reorderedBlackLists);
  };

  useEffect(() => {
    if (isSuccess) {
      const message = 'Receipt rule is successfully updated.';
      toast(message);
    }
  }, [isSuccess]);

  return (
    <div>
      <div className="pb-4">
        <h1>{t('receiptRule.add.title')}</h1>
        <Breadcrumb items={[{ label: t('common.adminBreadcrumbLabel') }, { label: t('receiptRule.test.title') }]} />
      </div>
      <div className="flex w-full items-center justify-between">
        <div className="flex h-full w-full text-center text-2xl">
          {id === undefined ? t('receiptRule.add.title') : t('receiptRule.edit.title')}
        </div>
        <div className='flex'>
          {id != null && (
            <div className="flex items-center">
              <button
                onClick={() => {
                  navigate(`/admin/rules/${id}/test`);
                }}
                className="ml-4 rounded-lg bg-primary px-5 py-2 text-white">
                {t('receiptRule.add.testBtnLabel')}
              </button>
            </div>
          )}
          {id != null && (
            <div className="flex items-center">
              <button
                onClick={() => {
                  navigate(`/admin/rules/${id}/bonus-items`);
                }}
                className="ml-4 rounded-lg bg-greenColor px-5 py-2 text-white">
                {t('receiptRule.bonusItems.importList')}
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="flex w-full flex-col items-center justify-between">
        <CustomTextInputV3
          type="text"
          name="receipt-rule-name"
          value={currentRuleName}
          label="Rule Name"
          handleChange={handleRuleNameChange}
        />
        <GeneralComponentBuilder components={currentGeneralComponents} handleChange={handleGeneralComponentChange} />
        <HorizontalDivider />
        <GlobalIdentifiers identifiers={currentGlobalIdentifiers} handleChange={handleGlobalIdentifierChange} />
        <HorizontalDivider />
        <SectionBuilder
          sections={currentSections}
          handleChange={handleSectionChange}
          handleReOrder={handleReOrderSection}
        />
        <HorizontalDivider />
        <LineItemComponentBuilder
          lineItemComponent={currentLineItemComponents}
          handleChange={handleLineItemChange}
          handleReOrder={handleReOrderLineItem}
        />
        <BlacklistComponentBuilder
          lineItemComponent={currentBlacklists}
          handleChange={handleBlacklistChange}
          handleReOrder={handleReOrderBlacklist}
        />
        <SpecialPointComponentBuilder
          specialPointProducts={currentSpecialPointProducts}
          handleChange={handleSpecialPointProductChange}
        />

        <div className="mt-4 flex w-full space-x-2">
          <button onClick={handleSubmit} type="submit" className="w-24 rounded-lg bg-primary px-4 py-2 text-white">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReceiptRuleBuilderPage;
