import { apiSlice } from '../../app/services/api-slice';
import { urlWithPageAndTerm } from '../../common/helpers';
import {
  type ApiSuccessResponse,
  type ListWithIdPaginationAndSearchAndPendingReq,
  type ListWithIdPaginationAndSearchReq,
  type ListWithPaginationAndSearchReq,
  type PaginatedData,
  type PaginatedMeta,
  type ProfileUpdateRequest,
  type ProfileUser,
  type User,
  type UserCreateApiDto,
  type UserUpdateRequest
} from '../../common/types';

const userTagName: string = 'Users';

export const userApi = apiSlice
  .enhanceEndpoints({
    addTagTypes: [userTagName]
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getUsers: builder.query<PaginatedData<User>, ListWithPaginationAndSearchReq>({
        query: ({ page = 1, pendingOnly = false }): string => {
          return urlWithPageAndTerm('users', { page, pendingOnly });
        },
        transformResponse: (data: ApiSuccessResponse) => {
          return {
            data: data.data.data as User[],
            meta: data.data.meta as PaginatedMeta
          };
        },
        providesTags: (result) =>
          result != null
            ? [
                ...result.data.map(({ id }) => ({
                  type: userTagName,
                  id
                })),
                { type: userTagName, id: 'LIST' }
              ]
            : [{ type: userTagName, id: 'LIST' }]
      }),
      addUser: builder.mutation({
        query: (user: UserCreateApiDto) => ({
          url: '/users',
          method: 'POST',
          body: user
        }),
        invalidatesTags: [{ type: userTagName, id: 'LIST' }]
      }),
      getUsersForAgency: builder.query<PaginatedData<User>, ListWithIdPaginationAndSearchAndPendingReq>({
        query: ({ id, term = '', page = 1, pendingOnly = false }): string => {
          return urlWithPageAndTerm(`users/by-agency/${id}`, { term, page, pendingOnly });
        },
        transformResponse: (data: ApiSuccessResponse) => {
          return {
            data: data.data.data as User[],
            meta: data.data.meta as PaginatedMeta
          };
        },
        providesTags: (result) =>
          result != null
            ? [
                ...result.data.map(({ id }) => ({
                  type: userTagName,
                  id
                })),
                { type: userTagName, id: 'LIST' }
              ]
            : [{ type: userTagName, id: 'LIST' }]
      }),
      getUsersForMerchant: builder.query<PaginatedData<User>, ListWithIdPaginationAndSearchAndPendingReq>({
        query: ({ id, term = '', page = 1, pendingOnly = false }): string => {
          return urlWithPageAndTerm(`users/by-merchant/${id}`, { term, page, pendingOnly });
        },
        transformResponse: (data: ApiSuccessResponse) => {
          return {
            data: data.data.data as User[],
            meta: data.data.meta as PaginatedMeta
          };
        },
        providesTags: (result) =>
          result != null
            ? [
                ...result.data.map(({ id }) => ({
                  type: userTagName,
                  id
                })),
                { type: userTagName, id: 'LIST' }
              ]
            : [{ type: userTagName, id: 'LIST' }]
      }),
      getUsersForStore: builder.query<PaginatedData<User>, ListWithIdPaginationAndSearchReq>({
        query: ({ id, page = 1 }): string => {
          return urlWithPageAndTerm(`users/by-store/${id}`, { page });
        },
        transformResponse: (data: ApiSuccessResponse) => {
          return {
            data: data.data.data as User[],
            meta: data.data.meta as PaginatedMeta
          };
        },
        providesTags: (result) =>
          result != null
            ? [
                ...result.data.map(({ id }) => ({
                  type: userTagName,
                  id
                })),
                { type: userTagName, id: 'LIST' }
              ]
            : [{ type: userTagName, id: 'LIST' }]
      }),
      getProfile: builder.query<ProfileUser, void>({
        query: (): string => '/users/profile',
        transformResponse: (data: ApiSuccessResponse) => {
          return data.data as ProfileUser;
        },
        providesTags: (result) => (result != null ? [{ type: userTagName, id: result?.id }] : [])
      }),
      getUserById: builder.query<ProfileUser, string>({
        query: (id: string) => `/users/${id}`,
        transformResponse: (data: ApiSuccessResponse) => {
          return data.data as ProfileUser;
        },
        providesTags: (result) => (result != null ? [{ type: userTagName, id: result?.id }] : [])
      }),
      updateUser: builder.mutation<User, UserUpdateRequest>({
        query: ({ id, body }) => ({
          url: `/users/${id}`,
          method: 'PATCH',
          body
        }),
        invalidatesTags: (result, error, arg) => [{ type: userTagName, id: result?.id }]
      }),
      updateProfile: builder.mutation<ProfileUser, ProfileUpdateRequest>({
        query: ({ body }) => ({
          url: `/users/profile`,
          method: 'PATCH',
          body
        }),
        invalidatesTags: (result, error, arg) => [{ type: userTagName, id: result?.id }]
      }),
      deleteUserById: builder.mutation<void, string>({
        query: (id: string) => ({
          url: `/users/${id}`,
          method: 'DELETE'
        }),
        invalidatesTags: (result, error, arg) => [{ type: userTagName, id: 'LIST' }]
      }),
      approveUser: builder.mutation<User, string>({
        query: (id) => ({
          url: `/users/${id}/approve`,
          method: 'POST'
        }),
        invalidatesTags: (result, error, arg) => [
          { type: userTagName, id: arg },
          { type: userTagName, id: 'LIST' }
        ]
      })
    }),
    overrideExisting: false
  });

export const {
  useGetUsersQuery,
  useAddUserMutation,
  useGetUserByIdQuery,
  useGetUsersForAgencyQuery,
  useGetUsersForMerchantQuery,
  useGetUsersForStoreQuery,
  useGetProfileQuery,
  useUpdateUserMutation,
  useUpdateProfileMutation,
  useDeleteUserByIdMutation,
  useApproveUserMutation
} = userApi;
export default userApi;
