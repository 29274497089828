import { useState } from 'react';
import { AiOutlineMinusCircle } from 'react-icons/ai';

import { Action } from '../../../common/types';
import CustomTextInputV3 from '../../../components/Form/CustomTextInputV3';

interface IProps {
  configItemKey: string;
  configItemValue: string | number | boolean;
  index: number;
  handleChange: (action: Action, index: number, configItem: { key: string; value: any }) => void;
}

const ConfigurationItem: React.FC<IProps> = ({ configItemKey, configItemValue, handleChange, index }) => {
  const [itemKey, setItemKey] = useState<string>(configItemKey);
  const [itemValue, setItemValue] = useState(configItemValue);

  const handleKeyChange = (name: string, value: string): void => {
    setItemKey(value);
    handleChange(Action.MODIFY, index, { key: value, value: itemValue });
  };

  const handleValueChange = (name: string, value: string): void => {
    setItemValue(value);
    handleChange(Action.MODIFY, index, { key: itemKey, value });
  };

  const handleDeletion = (): void => {
    handleChange(Action.REMOVE, index, { key: configItemKey, value: configItemValue });
  };

  return (
    <div className="flex flex-row items-center justify-between space-x-2">
      <CustomTextInputV3
        type="text"
        name={`configurationKey_${index}`}
        value={configItemKey}
        handleChange={handleKeyChange}
        label=""
      />
      <CustomTextInputV3
        type="text"
        name={`configurationValue_${index}`}
        value={configItemValue.toString()}
        handleChange={handleValueChange}
        label=""
      />

      <button className="justify-center rounded-md px-3 py-2 text-sm" onClick={handleDeletion}>
        <AiOutlineMinusCircle size={24} color="red" />
      </button>
    </div>
  );
};

export default ConfigurationItem;
