import { useState } from 'react';
import { DragDropContext, Draggable, type DropResult } from 'react-beautiful-dnd';
import { AiFillCaretDown } from 'react-icons/ai';

import { Action, type LineItemComponentV2, type LineItemOperatorType } from '../../../../../common/types';
import {BlacklistOperators} from '../../../constants';
import { generateLineItemComponentByOperatorType } from '../../../helpers';
import { StrictModeDroppable } from '../CustomDraggableContext';
import LineItemReactComponent from '../LineItemReactComponent';

interface IProps {
    lineItemComponent: LineItemComponentV2[];
    handleChange: (action: Action, identifier: LineItemComponentV2) => void;
    handleReOrder: (sourceIndex: number, destinationIndex: number) => void;
}

const BlacklistComponentBuilder: React.FC<IProps> = ({ lineItemComponent, handleChange, handleReOrder }) => {
    const [openLineItemComponentSelectionButton, setOpenLineItemComponentSelectionButton] = useState(false);

    const handleToggleLineItemComponentSelection = (): void => {
        setOpenLineItemComponentSelectionButton(!openLineItemComponentSelectionButton);
    };

    const handleLineItemComponentOperatorTypeSelection = (lineItemComponentOperator: LineItemOperatorType): void => {
        handleChange(Action.ADD, generateLineItemComponentByOperatorType(lineItemComponentOperator));
        setOpenLineItemComponentSelectionButton(false);
    };

    const dragEndHandler = (result: DropResult): void => {
        if (result.destination == null) return;

        handleReOrder(result.source.index, result.destination.index);
    };

    return (
        <div className="mt-2 flex w-full flex-col space-y-2">
            <div className="flex justify-between">
                <div className="text-xl">Blacklist Builder</div>
                <div className="relative inline-block text-left">
                    <div>
                        <button
                            type="button"
                            onClick={handleToggleLineItemComponentSelection}
                            className="inline-flex items-center justify-center gap-x-1.5 rounded-md bg-primary px-3 py-2 text-sm text-white"
                            id="global-identifier-operator-type-button"
                            aria-expanded="true"
                            aria-haspopup="true"
                        >
                            <span>Add Blacklist</span>
                            <AiFillCaretDown />
                        </button>
                    </div>
                    <div>
                        {openLineItemComponentSelectionButton && (
                            <div
                                className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg"
                                role="menu"
                                aria-orientation="vertical"
                                aria-labelledby="global-identifier-operator-type-button"
                                tabIndex={-1}
                            >
                                <div className="py-1" role="none">
                                    {BlacklistOperators.map((operator, index) => {
                                        return (
                                            <a
                                                key={index}
                                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-primary hover:text-white focus:outline-none"
                                                role="menuitem"
                                                tabIndex={-1}
                                                id={`menu-item-${index}`}
                                                onClick={() => {
                                                    handleLineItemComponentOperatorTypeSelection(operator.value);
                                                }}
                                            >
                                                {operator.label}
                                            </a>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div>
                <DragDropContext onDragEnd={dragEndHandler}>
                    <StrictModeDroppable droppableId="section-area">
                        {(provided) => (
                            <div className="flex flex-col space-y-2" ref={provided.innerRef} {...provided.droppableProps}>
                                {lineItemComponent?.map((lineItemComponent, index) => (
                                    <Draggable key={lineItemComponent.id} draggableId={lineItemComponent.id} index={index}>
                                        {(provided) => (
                                            <LineItemReactComponent
                                                key={lineItemComponent.id}
                                                lineItemComponent={lineItemComponent}
                                                handleChange={handleChange}
                                                innerRef={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            />
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </StrictModeDroppable>
                </DragDropContext>
            </div>
        </div>
    );
};

export default BlacklistComponentBuilder;
