interface IProps {
    message: string
}

const EmptyState: React.FC<IProps> = ({ message }) => {
    return (
        <div className="m-2 flex w-full justify-center justify-items-center rounded-md bg-gray-50 p-2  text-lg font-bold">
            <span>{message}</span>
        </div>
    );
};

export default EmptyState;