import jwtDecode, { type JwtPayload } from 'jwt-decode';

import { AUTH_TOKEN_NAME } from '../constants';
import { type PayloadFromSessionStorage, type UserInToken } from '../types';

export const extractFromSessionStorage = (): PayloadFromSessionStorage => {
  // console.log('Getting token from sessionStorage');
  const token = sessionStorage.getItem(AUTH_TOKEN_NAME) ?? null;
  const defaultPayload: PayloadFromSessionStorage = { user: null, token: null };

  if (token == null) {
    return defaultPayload;
  }

  try {
    const decodedJwt = jwtDecode<JwtPayload>(token);

    if (decodedJwt == null || (decodedJwt.exp != null && decodedJwt.exp > Date.now())) {
      return defaultPayload;
    }

    return { token, user: decodedJwt as UserInToken };
  } catch (e) {
    return defaultPayload;
  }
};
