import { type MerchantStore } from '../../../common/types';
import SuccessMessage from '../../../components/success-message';
import ListStoreItem from './ListStoreItem';

interface IProps {
  items: MerchantStore[];
  pageType?: 'pending' | 'approved';
  handleToggleActionModal: (action: string, item: MerchantStore) => any;
}

const ListStores: React.FC<IProps> = ({ items: merchantStores, pageType = 'approved', ...props }) => {
  if (merchantStores.length === 0) {
    const message = 'No merchant stores found';
    return <SuccessMessage message={message} type="error" />;
  }

  return (
    <>
      <div className="my-2 w-full overflow-auto rounded-md p-2">
        <table className="w-full border-b-2 border-gray-200 p-4">
          <thead className="bg-gray-100">
            <tr>
              <th className="p-2 pl-8 text-left text-sm font-semibold tracking-wide">Store</th>
              <th className="p-2 text-left text-sm font-semibold tracking-wide">Address</th>
              <th className="p-2 text-left text-sm font-semibold tracking-wide">Phone</th>
              <th className="p-2 text-left text-sm font-semibold tracking-wide">Status</th>
              <th className="w-12 p-2 pr-8 text-sm font-semibold tracking-wide">Actions</th>
            </tr>
          </thead>
          <tbody className="">
            {merchantStores?.map((store) => {
              return (
                <ListStoreItem
                  key={store.id}
                  item={store}
                  pageType={pageType}
                  handleToggleActionModal={props.handleToggleActionModal}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ListStores;
