import { AiOutlineEye } from 'react-icons/ai';
import { Link } from 'react-router-dom';

import { type MerchantStore } from '../../../common/types';

interface IProps {
  stores: MerchantStore[];
}

const RecentStores: React.FC<IProps> = ({ stores }) => {
  return (
    <div className="my-2 flex w-full flex-col space-y-2 overflow-auto border-b-gray-50 p-2">
      {stores.map((store, i) => {
        return (
          <div
            key={i}
            className="flex w-full items-center justify-between justify-items-center rounded-md bg-gray-50 p-4"
          >
            <div className="flex flex-col">
              <span>{store.name}</span>
              <span className="text-sm text-gray-500">{store.email}</span>
            </div>
            <div className="flex flex-row items-center">
              <span className="h-full px-1">
                <Link to={`/admin/stores/${store.id}`}>
                  <AiOutlineEye size={20} />
                </Link>
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default RecentStores;
