import { type ChangeEvent } from 'react';

interface Option {
  label: string;
  value: string;
}

interface CustomSelectInputProps {
  name: string;
  label: string;
  selectOption?: Option;
  options: Option[];
  handleChange: (name: string, value: string) => void;
  placeholder?: string;
  divClass?: string;
  labelClass?: string;
  inputClass?: string;
}

const CustomSelectInputV2: React.FC<CustomSelectInputProps> = ({
  name,
  label,
  selectOption,
  handleChange,
  options,
  divClass,
  labelClass,
  inputClass
}: CustomSelectInputProps) => {
  const handleValueChange = (event: ChangeEvent<HTMLSelectElement>): void => {
    handleChange(event.target.name.toString(), event.target.value);
  };

  return (
    <div className={divClass}>
      <label className={labelClass}>{label}</label>
      <div className="flex flex-col">
        <select name={name} className={inputClass} onChange={handleValueChange} value={selectOption?.value}>
          <option value="">Select an option</option>
          {options.map((option, index) => {
            return (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

export default CustomSelectInputV2;
