import { type OwnerEntityType, OwnerType, UserRole } from '../constants';
import type { ApiErrorResponse, ListWithPaginationAndSearchReq, OwnerScopeContext, ParentContext } from '../types';

export const getMax5Records = (records: any): any[] => {
  if (Array.isArray(records)) {
    return records.length > 5 ? records.slice(0, 4) : records;
  } else {
    return [];
  }
};

export const extractErrorsFromApi = (error: any): string[] => {
  let errorBag: string[] = [];

  if (typeof error === 'object' && 'error' in error) {
    errorBag.push(error.error);
  }

  if ('data' in error) {
    errorBag = (error as ApiErrorResponse).data.errors;
  }

  return errorBag;
};

export const urlWithPageAndTerm = (path: string, options: ListWithPaginationAndSearchReq): string => {
  const urlSearchParams = new URLSearchParams();

  if (options.page != null && options.page > 1) {
    urlSearchParams.append('page', options.page.toString());
  }

  if (options.term != null && options.term.length > 2) {
    urlSearchParams.append('term', options.term);
  }

  if (options.pendingOnly === true) {
    urlSearchParams.append('pendingOnly', options.pendingOnly.toString());
  }

  if (options.errorOnly === true) {
    urlSearchParams.append('errorOnly', options.errorOnly.toString());
  }

  if (options.nonDefaultCardOnly === true) {
    urlSearchParams.append('nonDefaultCardOnly', options.nonDefaultCardOnly.toString());
  }

  const queryString = urlSearchParams.toString();

  return queryString !== '' ? `${path}?${queryString}` : path;
};

export const getAgencyMerchantStoreBasedOnScopeAndRole = ({
  loggedInUser,
  scope,
  agency,
  merchant,
  store
}: OwnerScopeContext): ParentContext => {
  if (loggedInUser == null) {
    return {};
  }

  const { role, agencyId, merchantId, storeId } = loggedInUser;

  switch (scope) {
    case 'all':
      if (UserRole.EMPLOYEE === role || UserRole.STORE_ADMIN === role) {
        return { agencyId, merchantId, storeId };
      } else if (UserRole.MERCHANT_ADMIN === role) {
        return { agencyId, merchantId };
      } else if (UserRole.AGENCY_ADMIN === role) {
        return { agencyId };
      }
      break;

    case 'agency':
      if (UserRole.SUPER_ADMIN === role) {
        return { agencyId: agency?.id };
      }
      break;

    case 'merchant':
      if (UserRole.SUPER_ADMIN === role || UserRole.AGENCY_ADMIN === role) {
        return { agencyId: merchant?.agencyId, merchantId: merchant?.id };
      }
      break;

    case 'store':
      if (UserRole.SUPER_ADMIN === role || UserRole.AGENCY_ADMIN === role || UserRole.STORE_ADMIN === role) {
        return { agencyId: store?.agencyId, merchantId: store?.merchantId, storeId: store?.id };
      }
      break;
  }

  return {};
};

export const capitalizeFirstLetter = (word: string): string => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const getAgencyAndMerchantIdFromOwnerEntity = (
  ownerEntityType: string,
  ownerEntity: OwnerEntityType
): { agencyId: string; merchantId: string | undefined } => {
  let agencyId, merchantId;
  if (ownerEntityType !== OwnerType.AGENCY && 'agencyId' in ownerEntity) {
    agencyId = ownerEntity.agencyId;
  } else {
    agencyId = ownerEntity.id;
  }

  if (ownerEntityType !== OwnerType.MERCHANT && 'merchantId' in ownerEntity) {
    merchantId = ownerEntity.merchantId;
  } else if (ownerEntityType === OwnerType.MERCHANT) {
    merchantId = ownerEntity.id;
  }

  return { agencyId, merchantId };
};

export const getActiveClassName = (isActive: boolean): string => {
  return isActive ? 'bg-primary text-white' : 'text-gray-900';
};


export const getStatusText = (isActive: boolean): string => {
  return isActive ? 'Active' : 'Inactive';
}

export const getStatusLabelClassnames = (isActive: boolean): string => {
  return isActive ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800';
}