import { apiSlice } from '../../app/services/api-slice';
import type { ApiSuccessResponse } from '../../common/types';
import { type ConfigurationCreateRequest, type ConfigurationModel, type ConfigurationUpdateRequest } from './types';

const tagName: string = 'Configuration';

export const configurationApi = apiSlice
  .enhanceEndpoints({
    addTagTypes: [tagName]
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getConfigurationByOwner: builder.query<ConfigurationModel, { ownerEntityType: string; ownerEntityId: string }>({
        query: ({ ownerEntityType, ownerEntityId }) => `/configuration/${ownerEntityType}/${ownerEntityId}`,
        transformResponse: (data: ApiSuccessResponse) => {
          return data.data as ConfigurationModel;
        },
        providesTags: (result) => (result != null ? [{ type: tagName, id: result?.id }] : [])
      }),
      updateConfiguration: builder.mutation<ConfigurationModel, ConfigurationUpdateRequest>({
        query: ({ id, body }) => ({
          url: `/configuration/${id}`,
          method: 'PATCH',
          body
        }),
        transformResponse: (data: ApiSuccessResponse) => {
          return data.data as ConfigurationModel;
        }
      }),
      addConfiguration: builder.mutation<ConfigurationModel, ConfigurationCreateRequest>({
        query: (body) => ({
          url: `/configuration`,
          method: 'POST',
          body
        }),
        transformResponse: (data: ApiSuccessResponse) => {
          return data.data as ConfigurationModel;
        }
      }),
      deleteConfiguration: builder.mutation<void, string>({
        query: (id: string) => ({
          url: `/configuration/${id}`,
          method: 'DELETE'
        }),
        invalidatesTags: (result, error, arg) => [{ type: tagName, id: 'LIST' }]
      })
    })
  });

export const {
  useGetConfigurationByOwnerQuery,
  useAddConfigurationMutation,
  useUpdateConfigurationMutation,
  useDeleteConfigurationMutation
} = configurationApi;
export default configurationApi;
