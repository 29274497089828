import { type RoleOption } from '../../../common/types';
import CustomTextInput from '../../../components/Form/custom-text-input';

interface IProps {
  availableRoles: RoleOption[];
}

const AddUserForm: React.FC<IProps> = (props) => {
  return (
    <>
      <CustomTextInput
        type="text"
        label="Fullname"
        name="fullname"
        divClass="w-full flex flex-col "
        labelClass="w-full text-left text-gray-600"
        inputClass="w-full my-1 px-4 py-1 rounded-md"
      />
      <CustomTextInput
        type="text"
        label="Email"
        name="email"
        divClass="w-full flex flex-col "
        labelClass="w-full text-left text-gray-600"
        inputClass="w-full my-1 px-4 py-1 rounded-md"
      />
      <CustomTextInput
        type="password"
        label="Password"
        name="password"
        divClass="w-full flex flex-col "
        labelClass="w-full text-left text-gray-600"
        inputClass="w-full my-1 px-4 py-1 rounded-md"
      />
      <CustomTextInput
        type="password"
        label="Password Confirmation"
        name="passwordConfirmation"
        divClass="w-full flex flex-col"
        labelClass="w-full text-left text-gray-600"
        inputClass="w-full my-1 px-4 py-1 rounded-md"
      />
    </>
  );
};

export default AddUserForm;
