import { FunctionComponent } from "react";
import { FormikErrors } from "formik";

interface IUploadFile {
  data: { file?: File };
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<{ file?: File }>> | Promise<void>;
  errors: FormikErrors<{ file?: File }>;
}

const UploadFile: FunctionComponent<IUploadFile> = ({
  data,
  setFieldValue,
  errors
}) => {
  return (
    <div className="ml-32">
      <input
        type="file"
        name="file"
        accept="text/csv"
        onChange={(e) => {
          if (e.currentTarget.files) {
            setFieldValue("file", e.currentTarget.files[0]);
          }
        }}
      />
      {errors.file && (
        <>
          <br />
          <span id="error">{errors.file}</span>
          <br />
        </>
      )}
    </div>
  );
};

export default UploadFile;
