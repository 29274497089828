import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useAuth } from '../../app/hooks/useAuth';
import { UserRole } from '../../common/constants';
import { getMax5Records } from '../../common/helpers';
import Breadcrumb from '../../components/Breadcrumb';
import LoadingSpinner from '../../components/LoadingSpinner';
import { useGetDevicesQuery } from '../devices/device.api-slice';
import { useGetMerchantsQuery } from '../merchants/merchant.api-slice';
import { useGetStoresQuery } from '../stores/store.api-slice';
import ReceiptStat from "./components/ReceiptStat";
import RecentDevices from './components/RecentDevices';
import RecentMerchants from './components/RecentMerchants';
import RecentStores from './components/RecentStores';
import TopDeviceDashboard from "./components/TopDeviceStat";
import { useGetGlobalDashboardQuery } from './dashboard-api-slice';

const Dashboard: React.FC = () => {
  const { user } = useAuth();
  const { data: dashboards, isLoading } = useGetGlobalDashboardQuery();
  const { data: merchants } = useGetMerchantsQuery(user?.role === UserRole.SUPER_ADMIN ? { page: 1 } : skipToken);
  const { data: stores, isLoading: isLoadingStoresList } = useGetStoresQuery({ page: 1 });
  const { data: devices } = useGetDevicesQuery(user?.role === UserRole.SUPER_ADMIN ? skipToken : { page: 1 });
  const { t } = useTranslation();

  if (isLoading || isLoadingStoresList || user == null) {
    return <LoadingSpinner />;
  }

  const stats = [
    { id: 'merchantCount', title: 'Merchants', count: dashboards?.countDashboard?.data.merchantCount },
    { id: 'storeCount', title: 'Stores', count: dashboards?.countDashboard?.data.storeCount },
    { id: 'userCount', title: 'Users', count: dashboards?.countDashboard?.data.userCount },
    { id: 'deviceCount', title: 'Devices', count: dashboards?.countDashboard?.data.deviceCount },
    { id: 'receiptRuleCount', title: 'Receipt Rule', count: dashboards?.countDashboard?.data.receiptRuleCount },
    { id: 'receiptCount', title: 'Receipt', count: dashboards?.countDashboard?.data.receiptCount },
  ];
  return (
    <div>
      <div className="pb-4">
        <h1>Dashboard</h1>
        <Breadcrumb
          items={[{ label: t('common.adminBreadcrumbLabel') }, { label: t('common.dashboardBreadcrumbLabel') }]}
        />
      </div>
      {/* Stats Card */}
      <div className="flex w-full flex-col justify-between space-x-0 space-y-2 md:flex-row md:space-x-2 md:space-y-0">
        {stats.map((stat) => {
          return (
            <div
              key={stat.id}
              className={`flex h-24 flex-col justify-between rounded-md bg-gray-100 px-4 py-2 sm:w-full md:w-1/2`}
            >
              <span className="px-2 text-center text-4xl">{stat.count}</span>
              <span className="text-center text-sm">{stat.title}</span>
            </div>
          );
        })}
      </div>
        <div className="mt-4 flex flex-col md:flex-row">
            <div className="flex w-full flex-col md:w-1/2">
                <div className="flex w-full justify-between px-4">
                    <span className="text-lg">{dashboards?.lastWeekReceiptsStats.name}</span>
                </div>
                {((dashboards?.lastWeekReceiptsStats?.data) != null) && <ReceiptStat statData={dashboards.lastWeekReceiptsStats.data} />}
            </div>
            <div className="flex w-full flex-col md:w-1/2">
                <div className="flex w-full justify-between px-4">
                    <span className="text-lg">{dashboards?.topDeviceStats.name}</span>
                </div>
                {((dashboards?.topDeviceStats?.data) != null) && <TopDeviceDashboard topDeviceData={dashboards?.topDeviceStats.data} />}
            </div>
        </div>

      {/* Recent Lists */}
      <div className="mt-4 flex flex-col md:flex-row">
        {user.role === UserRole.SUPER_ADMIN && merchants != null && merchants.data.length > 0 && (
          <div className="flex w-full flex-col md:w-1/2">
            <div className="flex w-full justify-between px-4">
              <span className="text-lg">Recent Merchants</span>
              <span className="ml-4 rounded-xl bg-greenColor px-4 py-1 text-white">
                <Link to="/admin/merchants">View All</Link>
              </span>
            </div>
            <RecentMerchants merchants={getMax5Records(merchants.data)} />
          </div>
        )}
        {stores != null && stores.data.length > 0 && (
          <div className="w-full md:w-1/2">
            <div className="flex w-full items-center justify-between px-4">
              <span className="text-lg">Recent Stores</span>
              <span className="ml-4 rounded-xl bg-greenColor px-4 py-1 text-white">
                <Link to="/admin/stores">View All</Link>
              </span>
            </div>
            <RecentStores stores={getMax5Records(stores.data)} />
          </div>
        )}
        {user.role !== UserRole.SUPER_ADMIN && devices != null && devices.data.length > 0 && (
          <div className="flex w-full flex-col md:w-1/2">
            <div className="flex w-full justify-between px-4">
              <span className="text-lg">Recent Devices</span>
              <span className="ml-4 rounded-xl bg-greenColor px-4 py-1 text-white">
                <Link to="/admin/devices">View All</Link>
              </span>
            </div>
            <RecentDevices devices={getMax5Records(devices.data)} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
