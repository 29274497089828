import { type ConfigurationDataInput, type ConfigurationItemTemp } from './types';

export const convertConfigItemToArray = (config: ConfigurationDataInput): ConfigurationItemTemp[] => {
  if (config == null || config === undefined) {
    return [];
  }

  return Object.entries(config).map(([key, value]) => ({ key, value }));
};

export function replaceConfigItemByIndex<T>(array: T[], index: number, updatedConfigItem: T): T[] {
  if (index < 0 || index >= array.length) {
    throw new Error('Index out of range');
  }

  const updatedConfigs = [...array];
  updatedConfigs[index] = updatedConfigItem;
  return updatedConfigs;
}
