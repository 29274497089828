import { useState } from 'react';
import { AiFillCaretDown } from 'react-icons/ai';

import { Action,type SpecialPointProduct } from '../../../../../common/types';
import {type SPECIAL_POINT_PRODUCT_RULE_TYPES, SpecialPointOperators} from '../../../constants';
import { generateSpecialPointProductByRuleType } from '../../../helpers';
import SpecialPointProductComponent from './SpecialPointProductComponent';


interface IProps {
    specialPointProducts: SpecialPointProduct[];
    handleChange: (action: Action, identifier: SpecialPointProduct) => void;
}

const SpecialPointComponentBuilder: React.FC<IProps> = ({ specialPointProducts, handleChange }) => {
    const [openLineItemComponentSelectionButton, setOpenLineItemComponentSelectionButton] = useState(false);

    const handleToggleLineItemComponentSelection = (): void => {
        setOpenLineItemComponentSelectionButton(!openLineItemComponentSelectionButton);
    };

    const handleOperatorTypeSelection = (operator: SPECIAL_POINT_PRODUCT_RULE_TYPES): void => {
        handleChange(Action.ADD, generateSpecialPointProductByRuleType(operator));
        setOpenLineItemComponentSelectionButton(false);
    };

    return (
        <div className="mt-2 flex w-full flex-col space-y-2">
            <div className="flex justify-between">
                <div className="text-xl">Special Point Item Builder</div>
                <div className="relative inline-block text-left">
                    <div>
                        <button
                            type="button"
                            onClick={handleToggleLineItemComponentSelection}
                            className="inline-flex items-center justify-center gap-x-1.5 rounded-md bg-primary px-3 py-2 text-sm text-white"
                            id="global-identifier-operator-type-button"
                            aria-expanded="true"
                            aria-haspopup="true"
                        >
                            <span>Add Special Point Item</span>
                            <AiFillCaretDown />
                        </button>
                    </div>
                    <div>
                        {openLineItemComponentSelectionButton && (
                            <div
                                className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg"
                                role="menu"
                                aria-orientation="vertical"
                                aria-labelledby="global-identifier-operator-type-button"
                                tabIndex={-1}
                            >
                                <div className="py-1" role="none">
                                    {SpecialPointOperators.map((operator, index) => {
                                        return (
                                            <a
                                                key={index}
                                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-primary hover:text-white focus:outline-none"
                                                role="menuitem"
                                                tabIndex={-1}
                                                id={`menu-item-${index}`}
                                                onClick={() => {
                                                    handleOperatorTypeSelection(operator.value);
                                                }}
                                            >
                                                {operator.label}
                                            </a>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div>
            {specialPointProducts?.map((specialPointProduct, index) => (
                <SpecialPointProductComponent 
                    key={specialPointProduct.id}
                    specialPointProduct={specialPointProduct}
                    handleChange={handleChange}
                />
            ))}
            </div>
        </div>
    );
};

export default SpecialPointComponentBuilder;
