import { type KeyboardEventHandler, useState } from 'react';
import { toast } from 'react-toastify';

interface IProps {
  handleSearch: (term: string) => any;
}

const SearchStore: React.FC<IProps> = ({ handleSearch }) => {
  const [term, setTerm] = useState('');

  const handleChange: React.ChangeEventHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value !== '') {
      setTerm(event.target.value);
    }
  };
  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      if (term.length < 3) {
        toast.warning('At least 3 characters required');
        return;
      }
      handleSearch(term);
    }
  };

  return (
    <input
      type="text"
      className="my-2 w-64 rounded-md px-4"
      placeholder="Type name and hit enter"
      value={term}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
    />
  );
};

export default SearchStore;
