import { AiOutlineMinusCircle } from 'react-icons/ai';

import { Action, type Section, SectionTypes } from '../../../../common/types';
import CustomCheckboxInputV2 from '../../../../components/Form/CustomCheckboxInputV2';
import CustomMultiTextInputV2 from '../../../../components/Form/CustomMultiTextInputV2';
import CustomTextInputV3 from '../../../../components/Form/CustomTextInputV3';

interface IProps {
  section: Section;
  handleChange: (action: Action, identifier: Section) => void;
  innerRef: (element: HTMLElement | null) => void;
}

const SectionComponent: React.FC<IProps> = ({ section, handleChange, innerRef, ...props }) => {
  const handleTagChange = (name: string, tags: string[]): void => {
    const updatedSection = {
      ...section,
      value: tags
    };
    handleChange(Action.MODIFY, updatedSection);
  };

  const handleLabelChange = (name: string, value: string): void => {
    const updatedSection = {
      ...section,
      label: value
    };
    handleChange(Action.MODIFY, updatedSection);
  };

  const handleDeletion = (): void => {
    handleChange(Action.REMOVE, section);
  };

  const handleCheckboxChange = (name: string, checked: boolean): void => {
    const updated = {
      ...section,
      hasLineItem: checked
    };
    handleChange(Action.MODIFY, updated);
  };

  return (
    <div
      className="flex flex-row items-center justify-between space-x-2 rounded-md bg-gray-300 p-2"
      ref={innerRef}
      {...props}
      id={section.id}
    >
      <span className="pl-2">{section.type}</span>
      <CustomTextInputV3
        type="text"
        name={`customLabel_${section.id}`}
        value={section.label}
        handleChange={handleLabelChange}
        label=""
      />
      {[SectionTypes.REPEATED_TOKEN, SectionTypes.ONE_OF_TOKEN].includes(section.type) && (
        <CustomMultiTextInputV2 label="" name="customTags" values={section.value} handleChange={handleTagChange} />
      )}
      <span className="text-sm">LineItem</span>
      <CustomCheckboxInputV2
        name="hasLineItem"
        label=""
        value={section.hasLineItem}
        handleChange={handleCheckboxChange}
      />
      <button className="justify-center rounded-md px-3 py-2 text-sm" onClick={handleDeletion}>
        <AiOutlineMinusCircle size={24} color="red" />
      </button>
    </div>
  );
};

export default SectionComponent;
