import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useParams } from 'react-router-dom';

import { OwnerType } from '../../../common/constants';
import { useGetStoreByIdQuery } from '../../stores/store.api-slice';
import ListReceiptsPage from './ListReceipts';

const ListReceiptsForStore: React.FC = () => {
  const { id } = useParams();
  const { data } = useGetStoreByIdQuery(id ?? skipToken);

  return <ListReceiptsPage merchantStore={data} filter={'store'} ownerEntityType={OwnerType.STORE} ownerEntityId={id} />;
};

export default ListReceiptsForStore;
