import { Form, Formik, type FormikHelpers } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useAuth } from '../../../app/hooks/useAuth';
import { UserRole } from '../../../common/constants';
import { extractErrorsFromApi } from '../../../common/helpers';
import type { Agency, Merchant, MerchantStoreRequest, ParentContext } from '../../../common/types';
import CustomSelect from '../../../components/Form/CustomSelect';
import RenderErrorBag from '../../../components/render-error-bag';
import { useLazyGetListOfAgenciesQuery } from '../../agencies/agency.api-slice';
import { useLazyGetListOfMerchantsQuery } from '../../merchants/merchant.api-slice';
import { useAddStoreMutation } from '../store.api-slice';
import { AddMerchantStoreSchema } from '../store.schema';
import AddStoreForm from './AddStoreForm';

interface IProps {
  isOpen: boolean;
  toggleModal: () => any;
  parentContext: ParentContext;
  agencyInContext?: Agency;
  merchantInContext?: Merchant;
}

const initialData: MerchantStoreRequest = {
  name: '',
  email: '',
  phone: '',
  address: ''
};

interface Option {
  label: string;
  value: string;
}

const AddStore: React.FC<IProps> = ({ isOpen, toggleModal, parentContext, agencyInContext, merchantInContext }) => {
  const [selectedAgency, setSelectedAgency] = useState<Option | undefined>(
    agencyInContext != null ? { value: agencyInContext.id, label: agencyInContext.name } : undefined
  );
  const [selectedMerchant, setSelectedMerchant] = useState<Option | undefined>(
    merchantInContext != null ? { value: merchantInContext.id, label: merchantInContext.name } : undefined
  );
  const [listOfAgencies, setListOfAgencies] = useState<Option[]>([]);
  const [listOfMerchants, setListOfMerchants] = useState<Option[]>([]);
  const [addItem, { isLoading, isError, error, isSuccess, reset }] = useAddStoreMutation();
  const [errorBag, setErrorBag] = useState<string[]>([]);
  const { t } = useTranslation();
  const { user } = useAuth();
  const [triggerFetchAgencies, resultAgencies] = useLazyGetListOfAgenciesQuery();
  const [triggerFetchMerchants, resultMerchants] = useLazyGetListOfMerchantsQuery();

  const handleAgencyChange = (selectedValue: any): void => {
    setSelectedAgency(selectedValue);
  };

  const handleMerchantChange = (selectedValue: any): void => {
    setSelectedMerchant(selectedValue);
  };
  const handleAddItem = async (
    values: MerchantStoreRequest,
    actions: FormikHelpers<MerchantStoreRequest>
  ): Promise<any> => {
    await addItem({
      ...values,
      agencyId: selectedAgency?.value ?? parentContext.agencyId,
      merchantId: selectedMerchant?.value ?? parentContext.merchantId
    });
    actions.resetForm();
    toggleModal();
  };

  useEffect(() => {
    if (user?.role === UserRole.SUPER_ADMIN && isOpen) {
      void triggerFetchAgencies();
    }
  }, [isOpen, user]);

  useEffect(() => {
    if (isOpen && user?.role === UserRole.SUPER_ADMIN && selectedAgency?.value != null) {
      void triggerFetchMerchants(selectedAgency?.value);
    } else if (isOpen && user?.role === UserRole.AGENCY_ADMIN && parentContext?.agencyId != null) {
      void triggerFetchMerchants(parentContext?.agencyId);
    }
  }, [isOpen, user, selectedAgency?.value, parentContext.agencyId]);

  useEffect(() => {
    if (isSuccess) {
      toast(t('stores.add.successMessage'));
      reset();
    }
    if (isError && error != null) {
      setErrorBag(extractErrorsFromApi(error));
    }
  }, [isError, isSuccess]);

  useEffect(() => {
    if (resultAgencies?.data != null && resultAgencies.data.length > 0) {
      setListOfAgencies(
        resultAgencies.data.map((agency) => {
          return { label: agency.name, value: agency.id };
        })
      );
    }

    if (resultMerchants?.data != null && resultMerchants.data.length > 0) {
      setListOfMerchants(
        resultMerchants.data.map((merchant) => {
          return { label: merchant.name, value: merchant.id };
        })
      );
    } else {
      setListOfMerchants([]);
    }
  }, [resultAgencies, resultMerchants]);

  return (
    <>
      {isOpen && (
        <>
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden outline-none focus:outline-none">
            <div className="relative mx-auto my-6 w-full max-w-3xl">
              <div className="relative flex w-full flex-col rounded-lg border-0 bg-white shadow-lg outline-none focus:outline-none">
                <div className="flex items-start justify-between rounded-t border-b border-solid border-slate-200 p-5">
                  <h3 className="text-3xl font-semibold">{t('stores.add.title')}</h3>
                </div>
                <div className="relative w-full flex-auto p-6">
                  {errorBag.length > 0 && <RenderErrorBag errorBag={errorBag} />}
                  {
                    <Formik
                      initialValues={initialData}
                      onSubmit={handleAddItem}
                      validationSchema={AddMerchantStoreSchema}
                    >
                      {({ isSubmitting }) => (
                        <Form className="flex w-full flex-col items-start px-2" autoComplete="off">
                          {user != null && user.role === UserRole.SUPER_ADMIN && (
                            <CustomSelect
                              label="Agency"
                              name="agencyId"
                              divClass="w-full flex flex-col "
                              labelClass="w-full text-left text-gray-600"
                              inputClass="w-full my-1 px-4 py-1 rounded-md"
                              options={listOfAgencies}
                              defaultValue={selectedAgency}
                              handleChange={handleAgencyChange}
                            />
                          )}
                          {user != null &&
                            (user.role === UserRole.SUPER_ADMIN || user.role === UserRole.AGENCY_ADMIN) && (
                              <CustomSelect
                                label="Merchant"
                                name="merchantId"
                                divClass="w-full flex flex-col "
                                labelClass="w-full text-left text-gray-600"
                                inputClass="w-full my-1 px-4 py-1 rounded-md"
                                defaultValue={selectedMerchant}
                                options={listOfMerchants}
                                handleChange={handleMerchantChange}
                              />
                            )}
                          <AddStoreForm />
                          <div className="mt-4 flex w-full space-x-2 rounded-b border-t border-solid border-slate-200 py-6">
                            <button className="w-1/2 rounded-lg bg-gray-200 px-5 py-2 text-black" onClick={toggleModal}>
                              {t('stores.add.cancelBtnLabel')}
                            </button>
                            <button
                              type="submit"
                              disabled={isLoading || isSubmitting}
                              className="w-1/2 rounded-lg bg-greenColor px-4 py-2 text-white"
                            >
                              {t('stores.add.saveBtnLabel')}
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
        </>
      )}
    </>
  );
};

export default AddStore;
