import { v4 as uuid } from 'uuid';

import {
  GlobalIdentifierOperator,
  GlobalIdentifierPosition,
  GlobalIdentifierSeparatorType,
  GlobalIdentifierSpecialChar,
  LineItemOperatorType,
  type Option,
  type Rule,
  SectionTypes,
  ValueConstraints
} from '../../common/types';

export enum SeparatorOptionValues {
  NONE = 'NONE',
  SINGLE_SPACE = 'SINGLE_SPACE',
  MULTIPLE_SPACE = 'MULTIPLE_SPACE',
  SINGLE_LINE_BREAK = 'SINGLE_LINE_BREAK',
  MULTIPLE_LINE_BREAK = 'MULTIPLE_LINE_BREAK'
}

export enum SPECIAL_POINT_PRODUCT_RULE_TYPES  {
  FIXED = "FIXED",
  MULTIPLIER = "MULTIPLIER",
}

export const separatorOptions: Option[] = [
  { label: 'None', value: SeparatorOptionValues.NONE },
  { label: 'Line Break', value: SeparatorOptionValues.SINGLE_LINE_BREAK },
  { label: 'Multiple Line Break', value: SeparatorOptionValues.MULTIPLE_LINE_BREAK },
  { label: 'Space', value: SeparatorOptionValues.SINGLE_SPACE },
  { label: 'Multiple Space', value: SeparatorOptionValues.MULTIPLE_SPACE }
];

export const constraintOptions: Option[] = [
  { label: 'All', value: ValueConstraints.ALL },
  { label: 'Integer', value: ValueConstraints.INTEGER },
  { label: 'Decimal Number', value: ValueConstraints.DECIMAL },
  { label: 'Alphanumeric', value: ValueConstraints.ALPHANUMERIC }
];

export const LineItemComponentOperators = [
  { label: 'Pattern Match', value: LineItemOperatorType.PATTERN_MATCH },
  { label: 'Name', value: LineItemOperatorType.PREDEFINED_NAME },
  { label: 'Quantity', value: LineItemOperatorType.PREDEFINED_QUANTITY },
  { label: 'Price', value: LineItemOperatorType.PREDEFINED_PRICE },
  { label: 'Unit Price', value: LineItemOperatorType.PREDEFINED_UNIT_PRICE }
];

export const BlacklistOperators = [
  { label: 'Pattern Match', value: LineItemOperatorType.PATTERN_MATCH },
];

export const SpecialPointOperators = [
  { label: 'Fixed', value: SPECIAL_POINT_PRODUCT_RULE_TYPES.FIXED},
  { label: 'Multiplier', value: SPECIAL_POINT_PRODUCT_RULE_TYPES.MULTIPLIER},
];

export const globalIdentifierOperators = [
  { label: 'One of these words', value: GlobalIdentifierOperator.STRING_MATCH }
  // { label: 'Search by position', value: GlobalIdentifierOperator.KEYLESS_SEARCH_BY_POSITION },
];

export const sectionButtons = [
  { label: 'New Line', value: SectionTypes.LINE_BREAK },
  { label: 'One of these words', value: SectionTypes.ONE_OF_TOKEN },
  { label: 'Repeated Symbols', value: SectionTypes.REPEATED_TOKEN },
  { label: 'Until the end', value: SectionTypes.END_OF_FILE }
];

export const initialRule: Rule = {
  name: '',
  generalComponents: {
    leadingReferenceQualifier: {
      name: 'leadingReferenceQualifier',
      label: 'Tokens denoting original receipt',
      description: '',
      tokens: []
    },
    leadingReferenceDisqualifier: {
      name: 'leadingReferenceDisqualifier',
      label: 'Tokens denoting duplicate receipt',
      description: '',
      tokens: []
    },
    lineItemStartIndicator: {
      name: 'lineItemStartIndicator',
      label: 'Indicator of LineItem Start',
      description: '',
      tokens: []
    },
    lineItemEndIndicator: {
      name: 'lineItemEndIndicator',
      label: 'Indicator of LineItem End',
      description: '',
      tokens: []
    }
  },
  globalIdentifiers: [],
  sections: [],
  lineItemComponents: [],
  blacklists: [],
  specialPointProducts: [],
};

export const sampleRuleV2: Rule = {
  name: 'Test Rule',
  generalComponents: {
    leadingReferenceQualifier: {
      name: 'leadingReferenceQualifier',
      label: 'Tokens denoting original receipt',
      description: '',
      tokens: ['TAX INVOICE']
    },
    leadingReferenceDisqualifier: {
      name: 'leadingReferenceDisqualifier',
      label: 'Tokens denoting duplicate receipt',
      description: '',
      tokens: []
    },
    lineItemStartIndicator: {
      name: 'lineItemStartIndicator',
      label: 'Indicator of LineItem Start',
      description: '',
      tokens: ['EAT IN', 'TAKE AWAY']
    },
    lineItemEndIndicator: {
      name: 'lineItemEndIndicator',
      label: 'Indicator of LineItem End',
      description: '',
      tokens: ['----------']
    }
  },
  globalIdentifiers: [
    {
      id: uuid(),
      name: 'Total',
      description: 'Rule to capture total',
      rule: {
        operator: GlobalIdentifierOperator.STRING_MATCH,
        keyPattern: ['SUBTOTAL', 'TOTAL'],
        position: GlobalIdentifierPosition.ALL,
        separator: {
          type: GlobalIdentifierSeparatorType.SPECIAL_CHAR,
          value: GlobalIdentifierSpecialChar.LINE_BREAK,
          minCount: 1
        },
        valueConstraints: ValueConstraints.DECIMAL,
        sharedWithOther: false,
        prefix: {
          type: GlobalIdentifierSeparatorType.SINGLE_CHAR,
          value: '$',
          minCount: 1
        },
        suffix: undefined
      },
      uploadToExternalService: false,
      enforceUniqueness: false,
    }
  ],
  sections: [
    { id: uuid(), label: 'header', type: SectionTypes.LINE_BREAK, value: [], hasLineItem: false },
    { id: uuid(), label: 'body', type: SectionTypes.REPEATED_TOKEN, value: ['--------'], hasLineItem: true },
    { id: uuid(), label: 'footer', type: SectionTypes.END_OF_FILE, value: [], hasLineItem: false }
  ],
  lineItemComponents: [
    {
      id: uuid(),
      name: 'quantity',
      label: 'Rule to capture quantity',
      rule: {
        operator: LineItemOperatorType.PATTERN_MATCH,
        keyPattern: [],
        position: GlobalIdentifierPosition.ALL,
        separator: {
          type: GlobalIdentifierSeparatorType.SPECIAL_CHAR,
          value: GlobalIdentifierSpecialChar.SPACE,
          minCount: 2
        },
        valueConstraints: ValueConstraints.INTEGER,
        sharedWithOther: false,
        isOptional: false,
        isMultiLine: false,
        order: 1,
        prefix: {
          type: GlobalIdentifierSeparatorType.SINGLE_CHAR,
          value: '',
          minCount: 1
        },
        suffix: {
          type: GlobalIdentifierSeparatorType.SINGLE_CHAR,
          value: 'x',
          minCount: 1
        }
      },
      uploadToExternalService: true
    },
    {
      id: uuid(),
      name: 'unitPrice',
      label: 'Rule to capture unit price',
      rule: {
        operator: LineItemOperatorType.PATTERN_MATCH,
        keyPattern: [],
        position: GlobalIdentifierPosition.ALL,
        separator: {
          type: GlobalIdentifierSeparatorType.SPECIAL_CHAR,
          value: GlobalIdentifierSpecialChar.SPACE,
          minCount: 1
        },
        valueConstraints: ValueConstraints.DECIMAL,
        sharedWithOther: false,
        isOptional: false,
        isMultiLine: false,
        order: 2,
        prefix: {
          type: GlobalIdentifierSeparatorType.SINGLE_CHAR,
          value: '',
          minCount: 1
        },
        suffix: {
          type: GlobalIdentifierSeparatorType.SINGLE_CHAR,
          value: '',
          minCount: 1
        }
      },
      uploadToExternalService: true
    },
    {
      id: uuid(),
      name: 'name',
      label: 'Rule to capture item name',
      rule: {
        operator: LineItemOperatorType.PATTERN_MATCH,
        keyPattern: [],
        position: GlobalIdentifierPosition.ALL,
        separator: {
          type: GlobalIdentifierSeparatorType.SPECIAL_CHAR,
          value: GlobalIdentifierSpecialChar.SPACE,
          minCount: 1
        },
        valueConstraints: ValueConstraints.ALL,
        sharedWithOther: false,
        isOptional: false,
        isMultiLine: false,
        order: 3,
        prefix: {
          type: GlobalIdentifierSeparatorType.SINGLE_CHAR,
          value: '',
          minCount: 1
        },
        suffix: {
          type: GlobalIdentifierSeparatorType.SINGLE_CHAR,
          value: '',
          minCount: 1
        }
      },
      uploadToExternalService: true
    },
    {
      id: uuid(),
      name: 'price',
      label: 'Rule to capture price',
      rule: {
        operator: LineItemOperatorType.PATTERN_MATCH,
        keyPattern: [],
        position: GlobalIdentifierPosition.ALL,
        separator: {
          type: GlobalIdentifierSeparatorType.SPECIAL_CHAR,
          value: GlobalIdentifierSpecialChar.SPACE,
          minCount: 1
        },
        valueConstraints: ValueConstraints.DECIMAL,
        sharedWithOther: false,
        isOptional: false,
        isMultiLine: false,
        order: 4,
        prefix: {
          type: GlobalIdentifierSeparatorType.SINGLE_CHAR,
          value: '',
          minCount: 1
        },
        suffix: {
          type: GlobalIdentifierSeparatorType.SINGLE_CHAR,
          value: '',
          minCount: 1
        }
      },
      uploadToExternalService: true
    }
  ],
  blacklists: [],
  specialPointProducts: [],
};