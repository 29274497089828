import { type SeparatorType } from '../constants';

export enum Action {
  ADD = 'add',
  MODIFY = 'modify',
  REMOVE = 'remove'
}

export enum GlobalIdentifierOperator {
  STRING_MATCH = 'STRING_MATCH',
  KEYLESS_SEARCH_BY_POSITION = 'KEYLESS_SEARCH_BY_POSITION'
}

export enum GlobalIdentifierPosition {
  ALL = '*'
}

export enum GlobalIdentifierSpecialChar {
  SPACE = 'SPACE',
  LINE_BREAK = 'LINE_BREAK'
}

export enum GlobalIdentifierSeparatorType {
  SPECIAL_CHAR = 'SPECIAL_CHAR',
  SINGLE_CHAR = 'SINGLE_CHAR',
  REPEAT_CHAR = 'REPEAT_CHAR',
  NONE = 'NONE'
}

export interface GlobalIdentifierSeparator {
  type: GlobalIdentifierSeparatorType;
  value: GlobalIdentifierSpecialChar | string;
  minCount: number;
}

export interface GlobalIdentifierRule {
  operator: GlobalIdentifierOperator;
  keyPattern: string[];
  position: GlobalIdentifierPosition | number;
  separator: GlobalIdentifierSeparator;
  valueConstraints: ValueConstraints;
  sharedWithOther: boolean;
  prefix?: GlobalIdentifierSeparator;
  suffix?: GlobalIdentifierSeparator;
}

export enum LineItemOperatorType {
  PATTERN_MATCH = 'PATTERN_MATCH',
  PREDEFINED_NAME = 'PREDEFINED_NAME',
  PREDEFINED_QUANTITY = 'PREDEFINED_QUANTITY',
  PREDEFINED_PRICE = 'PREDEFINED_PRICE',
  PREDEFINED_UNIT_PRICE = 'PREDEFINED_UNIT_PRICE'
}

export enum ValueConstraints {
  DECIMAL = 'DECIMAL',
  INTEGER = 'INTEGER',
  ALPHANUMERIC = 'ALPHANUMERIC',
  ALL = 'ALL'
}

export interface LineItemComponentRule {
  operator: LineItemOperatorType;
  keyPattern: [];
  position: GlobalIdentifierPosition.ALL;
  separator: GlobalIdentifierSeparator;
  valueConstraints: ValueConstraints;
  prefix?: GlobalIdentifierSeparator;
  suffix?: GlobalIdentifierSeparator;
  sharedWithOther: boolean;
  isOptional: false;
  order: number;
  isMultiLine: boolean;
}

export interface GlobalIdentifierObject {
  id: string;
  name: string;
  description?: string;
  rule: GlobalIdentifierRule;
  uploadToExternalService: boolean;
  enforceUniqueness: boolean;
}

export enum SectionTypes {
  LINE_BREAK = 'line_break',
  ONE_OF_TOKEN = 'one_of_token',
  REPEATED_TOKEN = 'repeated_token',
  END_OF_FILE = 'end_of_file'
}

export interface Section {
  id: string;
  label: string;
  type: SectionTypes;
  value: string[];
  hasLineItem?: boolean;
}

export interface LineItemComponentV2 {
  id: string;
  name: string;
  label: string;
  rule: LineItemComponentRule;
  uploadToExternalService: boolean;
}

export interface GeneralComponent {
  name: string;
  label: string;
  description: string;
  tokens: string[];
}

export interface Option {
  label: string;
  value: string;
}

export enum GeneralComponentListKeys {
  leadingReferenceQualifier = 'leadingReferenceQualifier',
  leadingReferenceDisqualifier = 'leadingReferenceDisqualifier',
  lineItemStartIndicator = 'lineItemStartIndicator',
  lineItemEndIndicator = 'lineItemEndIndicator'
}

export type GeneralComponentList = {
  [key in GeneralComponentListKeys]: GeneralComponent;
};

export interface SpecialPointProduct {
  id?: string;
  name: string;
  rule: "MULTIPLIER" | "FIXED";
  value: number;
  startDate: string;
  endDate: string;
}

export interface Rule {
  id?: string;
  name: string;
  generalComponents: GeneralComponentList;
  globalIdentifiers: GlobalIdentifierObject[];
  sections: Section[];
  lineItemComponents: LineItemComponentV2[];
  blacklists: LineItemComponentV2[];
  specialPointProducts: SpecialPointProduct[];
}

export interface ReceiptRuleV2 extends Rule {}

export interface ReceiptRuleV2CreateRequest extends Rule {
  ownerEntityType: string;
  ownerEntityId: string;
  agencyId: string;
  merchantId?: string;
}

export interface ReceiptRuleV2UpdateRequest {
  id: string;
  body: Rule;
}

export interface ReceiptSection {
  title: string;
  startIndicator: [] | string[];
  endIndicator: [] | string[];
}

export interface ReceiptStructure {
  header: ReceiptSection[];
  body: ReceiptSection[];
  footer: ReceiptSection[];
}

export interface ReceiptRuleDto {
  leadingReferenceQualifier: string[];
  leadingReferenceDisqualifier: string[];
  lineItemStartIndicator: string[];
  lineItemEndIndicator: string[];
  totalIdentifier: string[];
  additionalFieldToParse?: string[];
  currencyIdentifierInReceipt: CurrencyIdentifier;
  lineItemRule: LineItemRule;
  sections: ReceiptStructure;

  // new fields
  rule: Rule;
  name: string;
}

export interface ReceiptRule extends ReceiptRuleDto {
  id: string;
}

export interface LineItemRule {
  quantityIdentifier: LineItemComponent;
  unitPriceIdentifier: LineItemComponent;
  nameIdentifier: LineItemComponent;
  priceIdentifier: LineItemComponent;
  modifierIdentifier: ModifierComponent;
}

export interface ModifierComponent {
  title: string;
  present: boolean;
  separator: LineItemSuffixSpecialChar.LINE_BREAK;
  order: number;
  hasPrice?: boolean;
  priceIdentifier?: LineItemComponent;
}

export interface LineItemComponent {
  title: string;
  present: boolean;
  prefixSymbol: string;
  suffixSymbol: string;
  order: number;
  description?: string;
  suffixSpecialChar?: boolean;
  isModifier?: boolean;
  rules?: string[];
  separator: SeparatorType;
}

export enum LineItemSuffixSpecialChar {
  SPACE = 'SPACE',
  LINE_BREAK = 'LINE_BREAK'
}

export interface CurrencyIdentifier {
  present: boolean;
  symbol: string;
  position: IdentifierPosition;
  currencyName: string;
}

enum IdentifierPosition {
  BEFORE = -1,
  AFTER = 1
}
