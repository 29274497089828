import { skipToken } from '@reduxjs/toolkit/query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

import { OwnerType } from '../../../common/constants';
import type { Agency, Device, Merchant, MerchantStore } from '../../../common/types';
import Breadcrumb from '../../../components/Breadcrumb';
import LoadingSpinner from '../../../components/LoadingSpinner';
import Pagination from '../../../components/Pagination';
import SuccessMessage from '../../../components/success-message';
import AddOrEditApiConfig from '../../stores/components/DefaultApiConfig/AddOrEditApiConfig';
import ApproveDevice from '../components/ApproveDevice';
import DeleteDevice from '../components/DeleteDevice';
import EditDevice from '../components/EditDevice';
import ListDevices from '../components/ListDevices';
import SearchDevice from '../components/SearchDevice';
import {
  useGetDevicesForAgencyQuery,
  useGetDevicesForMerchantQuery,
  useGetDevicesForStoreQuery,
  useGetDevicesQuery
} from '../device.api-slice';

interface IProps {
  scope?: 'all' | 'agency' | 'merchant' | 'merchantStore';
  filter?: { pending: boolean };
  agency?: Agency;
  merchant?: Merchant;
  merchantStore?: MerchantStore;
}

const ListDevicesPage: React.FC<IProps> = ({ filter, agency, merchant, merchantStore, scope = 'all' }) => {
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openApproveModal, setOpenApproveModal] = useState<boolean>(false);
  const [openApiConfigModal, setOpenApiConfigModal] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<Device | null>(null);

  const [page, setPage] = useState<number>(1);
  const [term, setTerm] = useState<string>('');
  const { t } = useTranslation();

  const { data: devices, isLoading } =
    scope === 'agency'
      ? useGetDevicesForAgencyQuery(agency != null ? { page, term, id: agency.id } : skipToken)
      : scope === 'merchant'
      ? useGetDevicesForMerchantQuery(merchant != null ? { page, term, id: merchant.id } : skipToken)
      : scope === 'merchantStore'
      ? useGetDevicesForStoreQuery(merchantStore != null ? { page, term, id: merchantStore.id } : skipToken)
      : useGetDevicesQuery({ page, term, pendingOnly: Boolean(filter?.pending) });

  const pageTitle =
    scope === 'agency' && agency != null
      ? `List of Devices for ${agency?.name}`
      : scope === 'merchant' && merchant != null
      ? `List of Devices for ${merchant?.name}`
      : scope === 'merchantStore' && merchantStore != null
      ? `List of Devices for ${merchantStore?.name}`
      : filter?.pending === true
      ? 'List of Pending Devices'
      : 'List of Devices';

  const handleSearch = (term: string): void => {
    setTerm(term);
  };

  const toggleEditModal = (): void => {
    if (openEditModal) {
      setSelectedItem(null);
    }
    setOpenEditModal(!openEditModal);
  };

  const toggleDeleteModal = (): void => {
    if (openDeleteModal) {
      setSelectedItem(null);
    }
    setOpenDeleteModal(!openDeleteModal);
  };

  const toggleApproveModal = (): void => {
    if (openApproveModal) {
      setSelectedItem(null);
    }
    setOpenApproveModal(!openApproveModal);
  };

  const toggleApiConfigModal = (): void => {
    if (openApiConfigModal) {
      setSelectedItem(null);
    }
    setOpenApiConfigModal(!openApiConfigModal);
  };

  const handleToggleActionModal = (action: string, item: Device): void => {
    if (action === 'edit') {
      setOpenEditModal((openEditModal) => !openEditModal);
      setSelectedItem(item);
    }

    if (action === 'delete') {
      setOpenDeleteModal((openDeleteModal) => !openDeleteModal);
      setSelectedItem(item);
    }

    if (action === 'approve') {
      setOpenApproveModal((openApproveModal) => !openApproveModal);
      setSelectedItem(item);
    }

    if (action === 'api-config') {
      setOpenApiConfigModal((openApiConfigModal) => !openApiConfigModal);
      setSelectedItem(item);
    }
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <div className="pb-4">
        <h1>{t('devices.list.title')}</h1>
        <Breadcrumb
          items={[{ label: t('common.adminBreadcrumbLabel') }, { label: t('devices.list.breadcrumbLabel') }]}
        />
      </div>
      <div className="flex w-full items-center justify-between">
        <h1>{pageTitle}</h1>
        <div>
          <SearchDevice handleSearch={handleSearch} />
          {scope === 'all' && filter?.pending === true && (
            <Link to="/admin/devices">
              <button className="ml-4 rounded-lg bg-gray-50 px-5 py-2">List Devices</button>
            </Link>
          )}
          {scope === 'all' && filter?.pending !== true && (
            <Link to="/admin/devices/pending-requests">
              <button className="ml-4 rounded-lg bg-gray-50 px-5 py-2">Pending Devices</button>
            </Link>
          )}
        </div>
      </div>
      {devices?.data != null && (
        <>
          {selectedItem != null && (
            <AddOrEditApiConfig
              ownerType={OwnerType.DEVICE}
              ownerEntity={selectedItem}
              isOpen={openApiConfigModal}
              toggleModal={toggleApiConfigModal}
            />
          )}
          <EditDevice isOpen={openEditModal} toggleEditModal={toggleEditModal} item={selectedItem} />

          <DeleteDevice isOpen={openDeleteModal} toggleDeleteModal={toggleDeleteModal} item={selectedItem} />
          <ApproveDevice isOpen={openApproveModal} toggleApproveModal={toggleApproveModal} item={selectedItem} />
          {devices?.data == null ||
            (devices.data.length === 0 && <SuccessMessage message={t('devices.list.empty')} type="error" />)}
          {devices?.data != null && devices?.data.length > 0 && (
            <>
              <ListDevices
                items={devices.data}
                pageType={filter?.pending === true ? 'pending' : 'confirmed'}
                handleToggleActionModal={handleToggleActionModal}
              />
              <Pagination pageData={devices.meta} handlePageChange={setPage} />
              <Tooltip id="device-list" />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ListDevicesPage;
