import { apiSlice } from '../../app/services/api-slice';
import { urlWithPageAndTerm } from '../../common/helpers';
import {
  type Agency,
  type AgencyRequest,
  type ApiSuccessResponse,
  type ListWithPaginationAndSearchReq,
  type PaginatedData,
  type PaginatedMeta,
  type UpdateAgency
} from '../../common/types';

const tagName: string = 'Agencies';

const agencyApi = apiSlice
  .enhanceEndpoints({
    addTagTypes: [tagName]
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getListOfAgencies: builder.query<Agency[], void>({
        query: (): string => '/agencies/short-list',
        transformResponse: (data: ApiSuccessResponse) => {
          return data.data as Agency[];
        },
        providesTags: (result) =>
          result != null
            ? [
                ...result.map(() => ({
                  type: tagName
                })),
                { type: tagName, id: 'LIST_SHORT' }
              ]
            : [{ type: tagName, id: 'LIST_SHORT' }]
      }),
      getAgencies: builder.query<PaginatedData<Agency>, ListWithPaginationAndSearchReq>({
        query: ({ term = '', page = 1, pendingOnly = false }): string => {
          return urlWithPageAndTerm('agencies', { term, page, pendingOnly });
        },
        transformResponse: (data: ApiSuccessResponse) => {
          return {
            data: data.data.data as Agency[],
            meta: data.data.meta as PaginatedMeta
          };
        },
        providesTags: (result) =>
          result?.data != null
            ? [
                ...result.data.map(({ id }) => ({
                  type: tagName,
                  id
                })),
                { type: tagName, id: 'LIST' }
              ]
            : [{ type: tagName, id: 'LIST' }]
      }),
      getAgencyById: builder.query<Agency, string>({
        query: (id: string) => `/agencies/${id}`,
        transformResponse: (data: ApiSuccessResponse) => {
          return data.data as Agency;
        },
        providesTags: (result) => (result != null ? [{ type: tagName, id: result?.id }] : [])
      }),
      addAgency: builder.mutation({
        query: (agency: AgencyRequest) => ({
          url: '/agencies',
          method: 'POST',
          body: agency
        }),
        invalidatesTags: [{ type: tagName, id: 'LIST' }]
      }),
      updateAgency: builder.mutation<Agency, UpdateAgency>({
        query: ({ id, body }) => ({
          url: `/agencies/${id}`,
          method: 'PATCH',
          body
        }),
        invalidatesTags: (result, error, arg) => [{ type: tagName, id: arg.id }]
      }),
      approveAgency: builder.mutation<Agency, string>({
        query: (id) => ({
          url: `/approve-agency/${id}`,
          method: 'POST'
        }),
        invalidatesTags: (result, error, arg) => [
          { type: tagName, id: arg },
          { type: tagName, id: 'LIST' }
        ]
      }),
      deleteAgencyById: builder.mutation<void, string>({
        query: (id: string) => ({
          url: `/agencies/${id}`,
          method: 'DELETE'
        }),
        invalidatesTags: (result, error, arg) => [
          { type: tagName, id: 'LIST' },
          { type: tagName, id: 'LIST_PENDING_AGENCIES' }
        ]
      })
    }),
    overrideExisting: false
  });

export const {
  useGetAgenciesQuery,
  useLazyGetListOfAgenciesQuery,
  useGetAgencyByIdQuery,
  useUpdateAgencyMutation,
  useApproveAgencyMutation,
  useAddAgencyMutation,
  useDeleteAgencyByIdMutation
} = agencyApi;
export default agencyApi;
