import { configureStore } from '@reduxjs/toolkit';

// eslint-disable-next-line sort-imports
import authSlice from '../auth/auth.slice';
import { UnauthorizedHandlerMiddleware } from './middlewares/error-handler.middleware';
import { sessionStorageMiddleware } from './middlewares/session-storage.middleware';
// eslint-disable-next-line sort-imports
import { apiSlice } from './services/api-slice';

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(apiSlice.middleware)
      .concat(sessionStorageMiddleware)
      .concat(UnauthorizedHandlerMiddleware)
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
