import { type ChangeEvent } from 'react';

interface CustomCheckboxInputProps {
  name: string;
  label: string;
  value?: boolean;
  placeholder?: string;
  divClass?: string;
  labelClass?: string;
  inputClass?: string;
  handleChange: (name: string, value: boolean) => void;
}

const CustomCheckboxInputV2: React.FC<CustomCheckboxInputProps> = ({
  name,
  label,
  divClass,
  labelClass,
  inputClass,
  value,
  handleChange
}: CustomCheckboxInputProps) => {
  const handleValueChange = (event: ChangeEvent<HTMLInputElement>): void => {
    handleChange(event.target.name.toString(), event.target.checked);
  };

  return (
    <div className={divClass}>
      <label className={labelClass}>{label}</label>
      <div className="flex flex-col">
        <input
          data-cy={`${name}-checkbox`}
          name={name}
          type="checkbox"
          className={inputClass}
          checked={value}
          onChange={handleValueChange}
        />
      </div>
    </div>
  );
};

export default CustomCheckboxInputV2;
