import { skipToken } from '@reduxjs/toolkit/query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth } from '../../../app/hooks/useAuth';
import { OwnerType, UserRole } from '../../../common/constants';
import {
  type Agency,
  type Device,
  type Merchant,
  type MerchantStore,
  type SampleReceipt,
  type UserInToken
} from '../../../common/types';
import Breadcrumb from '../../../components/Breadcrumb';
import LoadingSpinner from '../../../components/LoadingSpinner';
import Pagination from '../../../components/Pagination';
import SuccessMessage from '../../../components/success-message';
import AddSampleReceipt from '../components/AddSampleReceipt';
import DeleteSampleReceipt from '../components/DeleteSampleReceipt';
import EditSampleReceipt from '../components/EditSampleReceipt';
import ListSampleReceipts from '../components/ListSampleReceipts';
import { useGetAllSampleReceiptsQuery, useGetSampleReceiptByOwnerQuery } from '../sample-receipt.api-slice';

interface IProps {
  filter: 'all' | 'merchant' | 'store' | 'device';
  merchant?: Merchant;
  merchantStore?: MerchantStore;
  device?: Device;
  ownerEntityType?: OwnerType;
  ownerEntity?: Agency | Merchant | MerchantStore | Device;
}

interface IPropsEx extends IProps {
  authUser?: UserInToken | null;
}

const getSampleReceiptHeaderAndLink = ({
  filter,
  merchant,
  merchantStore,
  device,
  authUser
}: IPropsEx): { link: string; header: string } => {
  const commonHeader = 'List of Sample Receipts';
  const commonLink = '/admin/sample-receipts/add-to-';
  const header =
    filter === 'device' && device != null
      ? `${commonHeader} of ${device.name}`
      : filter === 'store' && merchantStore != null
      ? `${commonHeader} of ${merchantStore.name}`
      : filter === 'merchant' && merchant != null
      ? `${commonHeader} of ${merchant.name}`
      : commonHeader;
  const link =
    filter === 'device' && device != null
      ? `${commonLink}device/${device.id}`
      : filter === 'store' && merchantStore != null
      ? `${commonLink}store/${merchantStore.id}`
      : filter === 'merchant' && merchant != null
      ? `${commonLink}merchant/${merchant.id}`
      : authUser?.merchantId != null && authUser?.role === UserRole.MERCHANT_ADMIN
      ? `${commonLink}merchant/${authUser.merchantId}`
      : '';
  return { header, link };
};

const ListSampleReceiptsPage: React.FC<IProps> = ({
  filter = 'all',
  merchant,
  merchantStore,
  device,
  ownerEntityType,
  ownerEntity
}) => {
  const auth = useAuth();
  const [openAddModal, setOpenAddModal] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<SampleReceipt | null>(null);

  const { t } = useTranslation();
  const [page, setPage] = useState<number>(1);
  const { data: records, isLoading } =
    filter === 'device'
      ? useGetSampleReceiptByOwnerQuery(
          device != null ? { ownerId: device.id, ownerType: OwnerType.DEVICE, page } : skipToken
        )
      : filter === 'store'
      ? useGetSampleReceiptByOwnerQuery(
          merchantStore != null ? { ownerId: merchantStore.id, ownerType: OwnerType.STORE, page } : skipToken
        )
      : useGetAllSampleReceiptsQuery({ page });

  const toggleAddModal = (): void => {
    setOpenAddModal(!openAddModal);
  };

  const toggleEditModal = (): void => {
    if (openEditModal) {
      setSelectedItem(null);
    }
    setOpenEditModal(!openEditModal);
  };

  const toggleDeleteModal = (): void => {
    if (openDeleteModal) {
      setSelectedItem(null);
    }
    setOpenDeleteModal(!openDeleteModal);
  };

  const handleToggleActionModal = (action: string, item: SampleReceipt): void => {
    if (action === 'edit') {
      setOpenEditModal((openEditModal) => !openEditModal);
      setSelectedItem(item);
    }

    if (action === 'delete') {
      setOpenDeleteModal((openDeleteModal) => !openDeleteModal);
      setSelectedItem(item);
    }
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const { header } = getSampleReceiptHeaderAndLink({
    filter,
    merchant,
    merchantStore,
    device,
    authUser: auth?.user
  });

  return (
    <div>
      <div className="pb-4">
        <h1>{t('sampleReceipts.list.title')}</h1>
        <Breadcrumb
          items={[{ label: t('common.adminBreadcrumbLabel') }, { label: t('sampleReceipts.list.breadcrumbLabel') }]}
        />
      </div>
      <div className="flex w-full items-center justify-between">
        <h1>{header}</h1>
        {ownerEntity != null && (
          <div onClick={toggleAddModal}>
            <button className="ml-4 rounded-lg bg-gray-50 px-5 py-2">Add Sample Receipt</button>
          </div>
        )}
      </div>

      {ownerEntity != null && ownerEntityType !== undefined && (
        <AddSampleReceipt
          isOpen={openAddModal}
          toggleModal={toggleAddModal}
          ownerEntityType={ownerEntityType}
          ownerEntity={ownerEntity}
        />
      )}
      {selectedItem != null && (
        <EditSampleReceipt isOpen={openEditModal} toggleEditModal={toggleEditModal} item={selectedItem} />
      )}

      <DeleteSampleReceipt isOpen={openDeleteModal} toggleDeleteModal={toggleDeleteModal} item={selectedItem} />

      {records?.data == null ||
        (records.data.length === 0 && <SuccessMessage message={t('sampleReceipts.list.empty')} type="error" />)}

      {records?.data != null && records.data.length > 0 && (
        <>
          <ListSampleReceipts
            items={records.data}
            handleToggleActionModal={handleToggleActionModal}
          />
          <Pagination pageData={records.meta} handlePageChange={setPage} />
        </>
      )}
    </div>
  );
};

export default ListSampleReceiptsPage;
