import { skipToken } from '@reduxjs/toolkit/dist/query';
import { AiOutlineEdit } from 'react-icons/ai';
import { Link } from 'react-router-dom';

import type { OwnerType } from '../../../../common/constants';
import { useGetReceiptRuleByOwnerQuery } from '../../../ReceiptRule/receipt-rule.api-slice';
import RuleSummary from '../../../receipts/components/RuleSummary';
import NoDefaultRuleExistCreateNewOne from './NoDataContainer';

interface IProps {
  ownerEntityType: OwnerType;
  ownerEntityId: string;
}

const RuleDataContainer: React.FC<IProps> = ({ ownerEntityType, ownerEntityId }) => {
  const { data: rule } = useGetReceiptRuleByOwnerQuery(
    ownerEntityId !== '' ? { ownerEntityType, ownerEntityId } : skipToken
  );

  return (
    <div className="min-h-[200px]">
      <div className="flex h-full flex-col">
        <div className="flex w-full items-center justify-between border-b-2 pb-1">
          <span className="font-xl text-xl font-bold">Default Rule</span>
          {rule?.id != null && (
            <Link to={`/admin/rules/${ownerEntityType}/${ownerEntityId}`}>
              <div className="space-between flex items-center justify-center rounded-lg bg-greenColor p-2 text-center  text-gray-50">
                <AiOutlineEdit size={20} />
                <button className="px-2 text-sm">Edit</button>
              </div>
            </Link>
          )}
        </div>
        {rule == null && (
          <NoDefaultRuleExistCreateNewOne ownerEntityType={ownerEntityType} ownerEntityId={ownerEntityId} />
        )}
        {rule != null && <RuleSummary rule={rule} />}
      </div>
    </div>
  );
};

export default RuleDataContainer;
