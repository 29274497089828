import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { type RootState } from '../app/store';
import { extractFromSessionStorage } from '../common/helpers';
import { type AuthState, type UserInToken } from '../common/types';
import authApi from './auth.api-slice';

const { user, token } = extractFromSessionStorage();
const initialAuthState: AuthState = { token, loggedIn: false, user };

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialAuthState,
  reducers: {
    login: (state, { payload: { token, user, loggedIn } }: PayloadAction<AuthState>) => {
      state.token = token;
      state.user = user;
      state.loggedIn = loggedIn;
    },

    logout: (state) => {
      state.token = null;
      state.loggedIn = false;
      state.user = null;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(authApi.endpoints.loginUser.matchFulfilled, (state, { payload: { jwtToken, user } }) => {
      state.loggedIn = true;
      state.token = jwtToken;
      state.user = user;
    });
  }
});

export const { login, logout } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state: RootState): UserInToken | null => state.auth.user;
