import { type GeneralComponentList } from '../../../../common/types';
import CustomMultiTextInputV2 from '../../../../components/Form/CustomMultiTextInputV2';

interface IProps {
  components: GeneralComponentList;
  handleChange: (name: string, values: string[]) => void;
}

const GeneralComponentBuilder: React.FC<IProps> = ({ components, handleChange }) => {
  return (
    <div className="flex w-full flex-col">
      <div className="flex justify-between">
        <div className="text-xl">General Component Builder</div>
      </div>
      <div className="grid grid-cols-2 gap-x-2">
        {components !== undefined &&
          Object.entries(components).map(([key, component]) => {
            return (
              <CustomMultiTextInputV2
                key={key}
                label={component.label}
                name={component.name}
                values={component.tokens}
                handleChange={handleChange}
              />
            );
          })}
      </div>
    </div>
  );
};

export default GeneralComponentBuilder;
