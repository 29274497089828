import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useAuth } from '../../../app/hooks/useAuth';
import { type OwnerType, UserRole } from '../../../common/constants';
import { type Option } from '../../../common/types';
import Breadcrumb from '../../../components/Breadcrumb';
import CustomSelect from '../../../components/Form/CustomSelect';
import { useLazyGetListOfAgenciesQuery } from '../../agencies/agency.api-slice';
import { useLazyGetListOfDevicesQuery } from '../../devices/device.api-slice';
import { useLazyGetListOfMerchantsQuery } from '../../merchants/merchant.api-slice';
import RuleSummary from '../../receipts/components/RuleSummary';
import { useLazyGetListOfStoresQuery } from '../../stores/store.api-slice';
import { getAvailableOwnerTypeOptions } from '../helpers';
import { useAssignExistingReceiptRuleMutation, useGetReceiptRuleByIdQuery } from '../receipt-rule.api-slice';
import { type AssignExistingReceiptRulePayload } from '../types';


const ReceiptRuleCopyPage: React.FC = () => {
  const { t } = useTranslation();
  const { ruleId } = useParams();
  const { user } = useAuth();
  const [selectedOwnerType, setSelectOwnerType] = useState<Option | undefined>();
  const [selectedAgency, setSelectedAgency] = useState<Option | undefined>();
  const [selectedMerchant, setSelectedMerchant] = useState<Option | null>();
  const [selectedStore, setSelectedStore] = useState<Option | undefined>();
  const [selectedDevice, setSelectedDevice] = useState<Option | undefined>();

  const { data: rule } = useGetReceiptRuleByIdQuery(ruleId ?? skipToken);
  const [assignRule, { isLoading, isError, error, isSuccess, reset }] = useAssignExistingReceiptRuleMutation();

  const handleOwnerTypeChange = (selectedOwnerType: Option): void => {
    setSelectOwnerType(selectedOwnerType);
  };

  const handleSelectedAgencyChange = (selectedValue: Option): void => {
    setSelectedAgency(selectedValue);
  };

  const handleSelectedMerchantChange = (selectedValue: Option): void => {
    setSelectedMerchant(selectedValue);
  };

  const handleSelectedStoreChange = (selectedValue: Option): void => {
    setSelectedStore(selectedValue);
  };

  const handleSelectedDeviceChange = (selectedValue: Option): void => {
    setSelectedDevice(selectedValue);
  };

  const handleAddItem = async (selectedValue: any): Promise<void> => {

    let ownerId = null;
    if (selectedOwnerType?.value === 'device') {
        ownerId = selectedDevice?.value;
    }

    if (selectedOwnerType?.value === 'store') {
        ownerId = selectedStore?.value;
    }

    if (selectedOwnerType?.value === 'merchant') {
        ownerId = selectedMerchant?.value;
    }

    if (selectedOwnerType?.value === 'agency') {
        ownerId = selectedAgency?.value;
    }

    if (ownerId == null || selectedOwnerType?.value == null) {
        toast.warning('Target needs be selected first');
        return;
    }

    if (ruleId == null) {
        toast.warning('Rule needs to be selected');
        return;
    }

    const assignRuleDto: AssignExistingReceiptRulePayload = {
        ruleId,
        ownerType: selectedOwnerType.value as OwnerType,
        ownerId
    }

    await assignRule(assignRuleDto);

  };

  const availableOwnerTypeOptions = getAvailableOwnerTypeOptions(user?.role);
  const [triggerFetchAgencies, resultAgencies] = useLazyGetListOfAgenciesQuery();
  const [triggerFetchMerchants, resultMerchants] = useLazyGetListOfMerchantsQuery();
  const [triggerFetchStores, resultStores] = useLazyGetListOfStoresQuery();
  const [triggerFetchDevices, resultDevices] = useLazyGetListOfDevicesQuery();
  const [listOfAgencies, setListOfAgencies] = useState<Option[]>([]);
  const [listOfMerchants, setListOfMerchants] = useState<Option[]>([]);
  const [listOfStores, setListOfStores] = useState<Option[]>([]);
  const [listOfDevices, setListOfDevices] = useState<Option[]>([]);

  useEffect(() => {
    if (selectedOwnerType?.value === 'agency') {
        void triggerFetchAgencies();
    } else if (selectedOwnerType?.value === 'merchant') {
        // need to retrigger fetch agency only when user is super admin
        if (user?.role === UserRole.SUPER_ADMIN) {
            void triggerFetchAgencies();
        }

        if ((selectedAgency?.value) != null ) {
            void triggerFetchMerchants(selectedAgency.value);
        }

        if (((user?.agencyId) != null) && selectedMerchant?.value == null) {
            void triggerFetchMerchants(user.agencyId);
        }
    } else if (selectedOwnerType?.value === 'store') {
        if (user?.role === UserRole.SUPER_ADMIN) {
            void triggerFetchAgencies();
        }

        if ((selectedAgency?.value) != null ) {
            void triggerFetchMerchants(selectedAgency.value);
        }

        // fetch merchant list only when logged in user is agency admin & selected merchant is null
        if (((user?.agencyId) != null) && user.role === UserRole.AGENCY_ADMIN && selectedMerchant?.value == null) {
            void triggerFetchMerchants(user.agencyId);
        }

        if ((selectedMerchant?.value) != null ) {
            void triggerFetchStores(selectedMerchant.value);
        }

        if (((user?.merchantId) != null) && selectedStore?.value == null) {
            void triggerFetchStores(user.agencyId);
        }
    } else if (selectedOwnerType?.value === 'device') {
        if (user?.role === UserRole.SUPER_ADMIN) {
            void triggerFetchAgencies();
        }

        if ((selectedAgency?.value) != null ) {
            void triggerFetchMerchants(selectedAgency.value);
        }

        // fetch merchant list only when logged in user is agency admin & selected merchant is null
        if (((user?.agencyId) != null) && user.role === UserRole.AGENCY_ADMIN && selectedMerchant?.value == null) {
            void triggerFetchMerchants(user.agencyId);
        }

        if ((selectedMerchant?.value) != null ) {
            void triggerFetchStores(selectedMerchant.value);
        }

        if (((user?.merchantId) != null) && selectedStore?.value == null) {
            void triggerFetchStores(user.agencyId);
        }

        if ((selectedStore?.value) != null ) {
            void triggerFetchDevices(selectedStore.value);
        }
    }
  }, [selectedOwnerType, selectedAgency?.value, user, selectedMerchant?.value, selectedStore?.value]);


  useEffect(() => {
    if (resultAgencies?.data != null && resultAgencies.data.length > 0) {
        setListOfAgencies(
          resultAgencies.data.map((agency) => {
            return { label: agency.name, value: agency.id };
          })
        );
      } else {
        setListOfAgencies([]);
      }

      if (resultMerchants?.data != null && resultMerchants.data.length > 0) {
        setListOfMerchants(
            resultMerchants.data.map((merchant) => {
            return { label: merchant.name, value: merchant.id };
          })
        );
      } else {
        setListOfMerchants([]);
      }

      if (resultStores?.data != null && resultStores.data.length > 0) {
        setListOfStores(
            resultStores.data.map((store) => {
            return { label: store.name, value: store.id };
          })
        );
      } else {
        setListOfStores([]);
      }
      // fetching device list
      if (resultDevices?.data != null && resultDevices.data.length > 0) {
        setListOfDevices(
            resultDevices.data.map((device) => {
            return { label: device.name, value: device.id };
          })
        );
      } else {
        setListOfDevices([]);
      }
  }, [resultAgencies, resultMerchants, resultStores, resultDevices]);

  return (
    <div>
        <div className="pb-4">
            <h1>{t('receiptRule.assignReceiptRule.title')}</h1>
            <Breadcrumb items={[{ label: t('common.adminBreadcrumbLabel') }, { label: t('receiptRule.assignReceiptRule.title') }]} />
        </div>
        <div className="flex w-full flex-col">
            <h1 className="text-2xl">Rule Summary</h1>
            {rule != null && <RuleSummary rule={rule} />}
        </div>
        <div>
            {/* Maybe checbox to show all devices instead of only those devices which do not have rule assigned */}
            {/* Need to be searchable, resettable, multiselect */}
            {/* 
                If Logged-in user is super admin
                Need to show all types: agency, merchant, store, device
                If agency selected, show the list of agencies in dropdown
                If merchant selected, show two dropdowns, 
                    1. agencies list 
                    2. All merchants (Respect the agency filter when selected)
                If store selected, show three dropdowns, 
                    1. agencies list 
                    2. All merchants (Respect the agency filter when selected)
                    3. All stores (Respect the agency, merchant filter)
                If device selected, show four dropdowns, 
                    1. agencies list 
                    2. All merchants (Respect the agency filter when selected)
                    3. All stores (Respect the agency, merchant filter)
                    3. All devices (Respect the agency, merchant, store filter)
            
            If Logged-in user is agency admin
                Need to show all types: merchant, store, device
                If merchant selected, show one dropdown, 
                    1. All merchants (selectable)
                If store selected, show two dropdowns, 
                    1. All merchants
                    2. All stores (Respect the agency scope and merchant filter)
                If device selected, show three dropdowns, 
                    1. All merchants
                    2. All stores (Respect the agency scope, merchant filter)
                    3. All devices (Respect the agency scope, merchant, store filter)
            
            If Logged-in user is merchant admin
                Need to show all types: store, device
                If store selected, show one dropdowns, 
                    1. All stores
                If device selected, show two dropdowns, 
                    1. All stores
                    2. All devices (Respect the agency, merchant scope, store filter)

            If Logged-in user is store admin
                Need to show all types: device
                If store selected, show one dropdowns, 
                    1. All Devices
            */}
        <Formik initialValues={{}} onSubmit={handleAddItem}>
        {({ isSubmitting }) => (
            <Form className="flex w-full flex-col items-start px-2" autoComplete="off">
                <h1 className='my-2 py-2'>Target</h1>
                <div className='flex w-full flex-col space-y-3'>
                <CustomSelect
                        label="Owner Type"
                        name="ownerType"
                        divClass="w-full flex flex-col "
                        labelClass="w-full text-left mb-1 text-gray-600"
                        inputClass="w-full my-1 px-4 py-1 rounded-md"
                        options={availableOwnerTypeOptions}
                        handleChange={handleOwnerTypeChange}
                    />
                    {
                    selectedOwnerType?.value != null 
                    && ((user?.role) != null)
                    && [UserRole.SUPER_ADMIN].includes(user.role as UserRole)
                    && user?.role === UserRole.SUPER_ADMIN && (
                            <CustomSelect
                            label="Agency"
                            name="agencyId"
                            divClass="w-full flex flex-col "
                            labelClass="w-full text-left mb-1 text-gray-600"
                            inputClass="w-full my-1 px-4 py-1 rounded-md"
                            options={listOfAgencies}
                            handleChange={handleSelectedAgencyChange}
                            />
                        )}
                        {
                            ((user?.role) != null)
                            && [UserRole.SUPER_ADMIN, UserRole.AGENCY_ADMIN].includes(user.role as UserRole) 
                            && selectedOwnerType?.value != null 
                            && ['merchant', 'store', 'device'].includes(selectedOwnerType?.value)
                            && (
                                <CustomSelect
                                label="Merchant"
                                name="merchantId"
                                divClass="w-full flex flex-col "
                                labelClass="w-full text-left mb-1 text-gray-600"
                                inputClass="w-full my-1 px-4 py-1 rounded-md"
                                options={listOfMerchants}
                                handleChange={handleSelectedMerchantChange}
                                />
                        )}
                
                {
                    ((user?.role) != null)
                    && [UserRole.SUPER_ADMIN, UserRole.AGENCY_ADMIN, UserRole.MERCHANT_ADMIN].includes(user.role as UserRole) 
                    && selectedOwnerType?.value != null 
                    && ['store', 'device'].includes(selectedOwnerType?.value)
                    && (
                        <CustomSelect
                            label="Store"
                            name="storeId"
                            divClass="w-full flex flex-col "
                            labelClass="w-full text-left mb-1 text-gray-600"
                            inputClass="w-full my-1 px-4 py-1 rounded-md"
                            options={listOfStores}
                            handleChange={handleSelectedStoreChange}
                            />
                )}

                        {
                            ((user?.role) != null)
                            && [UserRole.SUPER_ADMIN, UserRole.AGENCY_ADMIN, UserRole.MERCHANT_ADMIN, UserRole.STORE_ADMIN].includes(user.role as UserRole) 
                            && selectedOwnerType?.value != null 
                            && ['device'].includes(selectedOwnerType?.value)
                            && (
                                <CustomSelect
                                label="Device"
                                name="deviceId"
                                divClass="w-full flex flex-col "
                                labelClass="w-full text-left mb-1 text-gray-600"
                                inputClass="w-full my-1 px-4 py-1 rounded-md"
                                options={listOfDevices}
                                handleChange={handleSelectedDeviceChange}
                                />
                        )}
                
                    <div className="mt-4 flex w-full space-x-2 rounded-b border-t border-solid border-slate-200 py-6">
                        <button className="w-1/2 rounded-lg bg-gray-200 px-5 py-2 text-black" onClick={handleAddItem}>
                            {t('users.add.cancelBtnLabel')}
                        </button>
                        <button
                            type="submit"
                            className="w-1/2 rounded-lg bg-greenColor px-4 py-2 text-white"
                        >
                            {t('users.add.saveBtnLabel')}
                        </button>
                    </div>
                </div>
            </Form>
            )}
        </Formik>
        </div>
    </div>
  );
};

export default ReceiptRuleCopyPage;
