import { type ChangeEvent } from 'react';

interface CustomTextInputProps {
  type: string;
  name: string;
  value: string | number;
  label: string;
  handleChange: (name: string, value: string) => void;
  readonly?: boolean;
  placeholder?: string;
  divClass?: string;
  labelClass?: string;
  inputClass?: string;
}

const CustomTextInputV3: React.FC<CustomTextInputProps> = ({
  type,
  label,
  name,
  value,
  handleChange,
  divClass = 'w-full flex flex-col',
  labelClass = 'w-full text-left text-gray-600',
  inputClass = 'w-full my-1 px-4 py-1 rounded-md',
  ...props
}: CustomTextInputProps) => {
  const handleValueChange = (event: ChangeEvent<HTMLInputElement>): void => {
    handleChange(event.target.name.toString(), event.target.value);
  };

  return (
    <div className={divClass}>
      <label className={labelClass}>{label}</label>
      <div className="flex flex-col">
        <input
          type={type}
          readOnly={props.readonly}
          data-cy={`${name}-input`}
          name={name}
          className={inputClass}
          value={value}
          onChange={handleValueChange}
        />
      </div>
    </div>
  );
};

export default CustomTextInputV3;
