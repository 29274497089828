import { apiSlice } from '../../app/services/api-slice';
import { urlWithPageAndTerm } from '../../common/helpers';
import type {
  ApiSuccessResponse,
  ListWithIdPaginationAndSearchAndPendingReq,
  ListWithPaginationAndSearchReq,
  Merchant,
  MerchantCreateApiDto,
  PaginatedData,
  PaginatedMeta,
  UpdateMerchant
} from '../../common/types';

const tagName: string = 'Merchants';

const merchantApi = apiSlice
  .enhanceEndpoints({
    addTagTypes: [tagName]
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getMerchants: builder.query<PaginatedData<Merchant>, ListWithPaginationAndSearchReq>({
        query: ({ term = '', page = 1, pendingOnly = false }): string => {
          return urlWithPageAndTerm('merchants', { term, page, pendingOnly });
        },
        transformResponse: (data: ApiSuccessResponse) => {
          return {
            data: data.data.data as Merchant[],
            meta: data.data.meta as PaginatedMeta
          };
        },
        providesTags: (result) =>
          result?.data != null
            ? [
                ...result.data.map(({ id }) => ({
                  type: tagName,
                  id
                })),
                { type: tagName, id: 'LIST' }
              ]
            : [{ type: tagName, id: 'LIST' }]
      }),
      getListOfMerchants: builder.query<Merchant[], string>({
        query: (id: string): string => `/merchants/short-list/?agencyId=${id}`,
        transformResponse: (data: ApiSuccessResponse) => {
          return data.data as Merchant[];
        },
        providesTags: (result) =>
          result != null
            ? [
                ...result.map(() => ({
                  type: tagName
                })),
                { type: tagName, id: 'LIST_SHORT' }
              ]
            : [{ type: tagName, id: 'LIST_SHORT' }]
      }),
      getMerchantsForAgency: builder.query<PaginatedData<Merchant>, ListWithIdPaginationAndSearchAndPendingReq>({
        query: ({ id, term = '', page = 1, pendingOnly = false }): string => {
          return urlWithPageAndTerm(`merchants/by-agency/${id}`, { term, page, pendingOnly });
        },
        transformResponse: (data: ApiSuccessResponse) => {
          return {
            data: data.data.data as Merchant[],
            meta: data.data.meta as PaginatedMeta
          };
        },
        providesTags: (result) =>
          result != null
            ? [
                ...result.data.map(({ id }) => ({
                  type: tagName,
                  id
                })),
                { type: tagName, id: 'LIST' }
              ]
            : [{ type: tagName, id: 'LIST' }]
      }),
      getMerchantById: builder.query<Merchant, string>({
        query: (id: string) => `/merchants/${id}`,
        transformResponse: (data: ApiSuccessResponse) => {
          return data.data as Merchant;
        },
        providesTags: (result) => (result != null ? [{ type: tagName, id: result?.id }] : [])
      }),
      addMerchant: builder.mutation({
        query: (merchant: MerchantCreateApiDto) => ({
          url: '/merchants',
          method: 'POST',
          body: merchant
        }),
        invalidatesTags: [{ type: tagName, id: 'LIST' }]
      }),
      notifyMerchant: builder.mutation({
        query: (id: string) => ({
          url: `/merchants/${id}/notify`,
          method: 'POST'
        })
      }),
      updateMerchant: builder.mutation<Merchant, UpdateMerchant>({
        query: ({ id, body }) => ({
          url: `/merchants/${id}`,
          method: 'PATCH',
          body
        }),
        invalidatesTags: (result, error, arg) => [{ type: tagName, id: arg.id }]
      }),
      approveMerchant: builder.mutation<Merchant, string>({
        query: (id) => ({
          url: `/approve-merchant/${id}`,
          method: 'POST'
        }),
        invalidatesTags: (result, error, arg) => [
          { type: tagName, id: arg },
          { type: tagName, id: 'LIST' }
        ]
      }),
      deleteMerchantById: builder.mutation<void, string>({
        query: (id: string) => ({
          url: `/merchants/${id}`,
          method: 'DELETE'
        }),
        invalidatesTags: (result, error, arg) => [
          { type: tagName, id: 'LIST' },
          { type: tagName, id: 'LIST_PENDING_MERCHANTS' }
        ]
      })
    }),
    overrideExisting: false
  });

export const {
  useGetMerchantsQuery,
  useLazyGetListOfMerchantsQuery,
  useGetMerchantsForAgencyQuery,
  useGetMerchantByIdQuery,
  useAddMerchantMutation,
  useUpdateMerchantMutation,
  useApproveMerchantMutation,
  useDeleteMerchantByIdMutation,
  useNotifyMerchantMutation
} = merchantApi;
export default merchantApi;
