import { v4 as uuid } from 'uuid';

import {
  type GlobalIdentifierObject,
  type GlobalIdentifierOperator,
  GlobalIdentifierPosition,
  type GlobalIdentifierSeparator,
  GlobalIdentifierSeparatorType,
  GlobalIdentifierSpecialChar,
  type LineItemComponentV2,
  LineItemOperatorType,
  type Option,
  type SpecialPointProduct,
  ValueConstraints} from '../../common/types';
import { constraintOptions, separatorOptions, SeparatorOptionValues, type SPECIAL_POINT_PRODUCT_RULE_TYPES } from './constants';

export const generateGlobalIdentifierByName = (
  index: number,
  operator: GlobalIdentifierOperator
): GlobalIdentifierObject => {
  return {
    id: uuid(),
    name: `Identifier #${index + 1}`,
    description: '',
    rule: {
      operator,
      keyPattern: [],
      position: GlobalIdentifierPosition.ALL,
      separator: {
        type: GlobalIdentifierSeparatorType.SPECIAL_CHAR,
        value: GlobalIdentifierSpecialChar.LINE_BREAK,
        minCount: 1
      },
      valueConstraints: ValueConstraints.ALL,
      sharedWithOther: false,
      prefix: undefined,
      suffix: undefined
    },
    uploadToExternalService: true,
    enforceUniqueness: false,
  };
};

export const mapSeparatorObjectToOption = (separator: GlobalIdentifierSeparator): Option | undefined => {
  const { value, minCount, type } = separator;
  let separatorKey: SeparatorOptionValues;

  if (value === GlobalIdentifierSpecialChar.SPACE) {
    separatorKey = minCount === 1 ? SeparatorOptionValues.SINGLE_SPACE : SeparatorOptionValues.MULTIPLE_SPACE;
  } else if (value === GlobalIdentifierSpecialChar.LINE_BREAK) {
    separatorKey = minCount === 1 ? SeparatorOptionValues.SINGLE_LINE_BREAK : SeparatorOptionValues.MULTIPLE_LINE_BREAK;
  } else if (type === GlobalIdentifierSeparatorType.NONE) {
    separatorKey = SeparatorOptionValues.NONE;
  } else {
    separatorKey = SeparatorOptionValues.SINGLE_SPACE;
  }

  return generateSeparatorOptionByKey(separatorKey);
};

export const generateLineItemComponentByOperatorType = (operator: LineItemOperatorType): LineItemComponentV2 => {
  const { name, valueConstraints } = generateLabelByOperatorType(operator);
  return {
    id: uuid(),
    name,
    label: name,
    rule: {
      operator,
      keyPattern: [],
      position: GlobalIdentifierPosition.ALL,
      separator: {
        type: GlobalIdentifierSeparatorType.SPECIAL_CHAR,
        value: GlobalIdentifierSpecialChar.LINE_BREAK,
        minCount: 1
      },
      valueConstraints,
      sharedWithOther: false,
      prefix: {
        type: GlobalIdentifierSeparatorType.SINGLE_CHAR,
        value: '',
        minCount: 1
      },
      suffix: {
        type: GlobalIdentifierSeparatorType.SINGLE_CHAR,
        value: '',
        minCount: 1
      },
      isOptional: false,
      isMultiLine: false,
      order: 1
    },
    uploadToExternalService: true
  };
};

const generateLabelByOperatorType = (
  operator: LineItemOperatorType
): { name: string; valueConstraints: ValueConstraints } => {
  if (operator === LineItemOperatorType.PREDEFINED_NAME) {
    return { name: 'name', valueConstraints: ValueConstraints.ALL };
  }

  if (operator === LineItemOperatorType.PREDEFINED_QUANTITY) {
    return { name: 'quantity', valueConstraints: ValueConstraints.INTEGER };
  }

  if (operator === LineItemOperatorType.PREDEFINED_UNIT_PRICE) {
    return { name: 'unitPrice', valueConstraints: ValueConstraints.DECIMAL };
  }

  if (operator === LineItemOperatorType.PREDEFINED_PRICE) {
    return { name: 'price', valueConstraints: ValueConstraints.DECIMAL };
  }

  return { name: 'LineItem Component', valueConstraints: ValueConstraints.ALL };
};

export const generateSeparatorByItsType = (separatorType: SeparatorOptionValues): GlobalIdentifierSeparator => {
  if (separatorType === SeparatorOptionValues.SINGLE_SPACE) {
    return {
      type: GlobalIdentifierSeparatorType.SPECIAL_CHAR,
      value: GlobalIdentifierSpecialChar.SPACE,
      minCount: 1
    };
  }

  if (separatorType === SeparatorOptionValues.MULTIPLE_SPACE) {
    return {
      type: GlobalIdentifierSeparatorType.SPECIAL_CHAR,
      value: GlobalIdentifierSpecialChar.SPACE,
      minCount: 2
    };
  }

  if (separatorType === SeparatorOptionValues.SINGLE_LINE_BREAK) {
    return {
      type: GlobalIdentifierSeparatorType.SPECIAL_CHAR,
      value: GlobalIdentifierSpecialChar.LINE_BREAK,
      minCount: 1
    };
  }

  if (separatorType === SeparatorOptionValues.MULTIPLE_LINE_BREAK) {
    return {
      type: GlobalIdentifierSeparatorType.SPECIAL_CHAR,
      value: GlobalIdentifierSpecialChar.LINE_BREAK,
      minCount: 2
    };
  }

  return {
    type: GlobalIdentifierSeparatorType.NONE,
    value: '',
    minCount: 0
  };
};

export const isReadOnlyLabel = (name: string): boolean => {
  return ['name', 'quantity', 'price', 'unitPrice'].includes(name);
};

export const generateSelectOptionByKey = (key: ValueConstraints): Option | undefined => {
  return constraintOptions.find((constraint) => constraint.value === key);
};

export const generateSeparatorOptionByKey = (key: SeparatorOptionValues): Option | undefined => {
  return separatorOptions.find((separator) => separator.value === key);
};

const getDateAfter = (days: number): Date => {
  const date = new Date();
  date.setDate(date.getDate() + days);

  return date;
}

export const formatDateToYMD = (date: Date): string => {
  if (!(date instanceof Date)) {
    return '';
  }
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const day = String(date.getDate()).padStart(2, '0');
  
  return `${year}-${month}-${day}`;
}

export const generateSpecialPointProductByRuleType = (operator: SPECIAL_POINT_PRODUCT_RULE_TYPES): SpecialPointProduct => {
  return {
    id: uuid(),
    name: '',
    rule: operator,
    value: 1,
    startDate: formatDateToYMD(new Date()),
    endDate: formatDateToYMD(getDateAfter(30))
  };
};


export const parseYMDDate = (dateString: string): Date | null => {
  const dateParts = dateString.split('-').map(Number);
    
    // Check if there are exactly three parts (year, month, day)
    if (dateParts.length !== 3) {
        return null;
    }

    const [year, month, day] = dateParts;

    // Check if year, month, and day are valid
    if (isNaN(year) || isNaN(month) || isNaN(day) || month < 1 || month > 12 || day < 1 || day > 31) {
        return null;
    }

    // Construct and return the Date object
    return new Date(year, month - 1, day); // Months are zero-indexed
}
