import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryWithReauth } from './fetch-base-query';

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
  tagTypes: []
});
