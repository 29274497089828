import { type Device } from '../../../common/types';

interface IProps {
  devices: Device[];
}

const RecentDevices: React.FC<IProps> = ({ devices }) => {
  return (
    <div className="my-2 flex w-full flex-col space-y-2 overflow-auto border-b-gray-50 p-2">
      {devices.map((device) => {
        return (
          <div
            key={`recent-device-${device.id}`}
            className="flex w-full items-center justify-between justify-items-center rounded-md bg-gray-50 p-4"
          >
            <span>{device.name}</span>
            <span>{device.email}</span>
          </div>
        );
      })}
    </div>
  );
};

export default RecentDevices;
