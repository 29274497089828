import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useParams } from 'react-router-dom';

import { useGetStoreByIdQuery } from '../../stores/store.api-slice';
import ListDevicesPage from './ListDevicesPage';

const ListDevicesForStorePage: React.FC = () => {
  const { id } = useParams();
  const { data } = useGetStoreByIdQuery(id ?? skipToken);

  return <ListDevicesPage merchantStore={data} scope={'merchantStore'} />;
};

export default ListDevicesForStorePage;
