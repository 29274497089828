import { useState } from 'react';
import { AiFillDelete } from 'react-icons/ai';

interface CustomMultiTextInputProps {
  label: string;
  name: string;
  values: string[];
  placeholder?: string;
  divClass?: string;
  labelClass?: string;
  inputClass?: string;
  handleChange: (name: string, values: string[]) => void;
}

const CustomMultiTextInputV2: React.FC<CustomMultiTextInputProps> = ({
  name,
  label,
  divClass = 'w-full flex flex-col',
  labelClass = 'w-full text-left text-gray-600',
  inputClass = 'w-full my-1 px-4 py-1 rounded-md',
  values,
  handleChange,
  ...props
}) => {
  const [tags, setTags] = useState<string[]>(values);

  const addTags = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    const input = event.currentTarget.value.trim();
    if (event.key === 'Enter' && input.length !== 0 && !tags.includes(input)) {
      const updatedTags = [...tags, event.currentTarget.value];
      setTags((prevState) => [...prevState, input]);
      handleChange(name, updatedTags);
      event.currentTarget.value = '';
      event.stopPropagation();
    }
  };

  const stopSubmittingForm = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      event.preventDefault();
    }
  };

  const removeTag = (index: number): void => {
    const updatedTags = [...tags.filter((tag) => tags.indexOf(tag) !== index)];
    setTags(updatedTags);
    handleChange(name, updatedTags);
  };

  return (
    <div className={divClass}>
      <label className={labelClass}>{label}</label>
      <div className="flex flex-wrap rounded-md border border-gray-600 p-2">
        <div className="flex flex-wrap space-x-2">
          {tags.map((tag, index) => {
            return (
              <div key={index} className="flex items-center justify-items-center">
                {tag}
                <AiFillDelete
                  color="red"
                  onClick={() => {
                    removeTag(index);
                  }}
                />
              </div>
            );
          })}
        </div>
        <div className="flex grow">
          <input
            name={name}
            className="w-56 rounded-md border-none py-1 outline-none"
            type="text"
            onKeyUp={(event) => {
              addTags(event);
            }}
            onKeyDown={(event) => {
              stopSubmittingForm(event);
            }}
          />
        </div>
        <input value={tags} {...{ ...props, type: 'hidden' }} />
      </div>
    </div>
  );
};

export default CustomMultiTextInputV2;
