import { apiSlice } from '../../app/services/api-slice';
import { type OwnerType } from '../../common/constants';
import { urlWithPageAndTerm } from '../../common/helpers';
import type {
  ApiSuccessResponse,
  ListWithPaginationAndSearchReq,
  PaginatedData,
  PaginatedMeta, ParsedReceiptWrapper,
  Receipt
} from '../../common/types';
import { type BatchProcessDto, type ReceiptProcessorResult } from './types';

// TODO add error handling in apis

interface ReceiptByOwnerReqParam {
  ownerEntityId: string;
  ownerEntityType: OwnerType;
  page?: number;
  errorOnly?: boolean;
  nonDefaultCardOnly?: boolean;
}

interface ReceiptByOwnerBody {
  ownerEntityId?: string;
  ownerEntityType?: OwnerType;
}

interface ReceiptTestReq {
  ruleId: string;
  sampleReceiptIds: string[]
}

const tagName: string = 'Receipt';

export const receiptApi = apiSlice
  .enhanceEndpoints({
    addTagTypes: [tagName]
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getAllReceipts: builder.query<PaginatedData<Receipt> | null, ListWithPaginationAndSearchReq>({
        query: ({ term = '', page = 1, errorOnly = false, nonDefaultCardOnly = false }): string => {
          return urlWithPageAndTerm('/receipts', { term, page, errorOnly, nonDefaultCardOnly });
        },
        transformResponse: (data: ApiSuccessResponse) => {
          if (data.data === undefined) {
            return null;
          }
          return {
            data: data.data.data as Receipt[],
            meta: data.data.meta as PaginatedMeta
          };
        },
        providesTags: (result, args) =>
          result != null
            ? [
                ...result.data.map(({ id }) => ({
                  type: tagName,
                  id
                })),
                { type: tagName, id: 'LIST' }
              ]
            : [{ type: tagName, id: 'LIST' }],
      }),
      getReceiptByOwner: builder.query<PaginatedData<Receipt> | null, ReceiptByOwnerReqParam>({
        query: ({ ownerEntityId, ownerEntityType, page = 1, errorOnly = false, nonDefaultCardOnly = false }): string => {
          return urlWithPageAndTerm(`/receipts/${ownerEntityType}/${ownerEntityId}`, { page, errorOnly, nonDefaultCardOnly });
        },
        transformResponse: (data: ApiSuccessResponse) => {
          if (data.data === undefined) {
            return null;
          }
          return {
            data: data.data.data as Receipt[],
            meta: data.data.meta as PaginatedMeta
          };
        },
        providesTags: (result) =>
          result != null
            ? [
                ...result.data.map(({ id }) => ({
                  type: tagName,
                  id
                })),
                { type: tagName, id: 'LIST' }
              ]
            : [{ type: tagName, id: 'LIST' }]
      }),
      testReceiptRule: builder.mutation<ParsedReceiptWrapper[], ReceiptTestReq>({
        query: (data:  ReceiptTestReq) => ({
          url: '/sample-receipts/test',
          method: 'POST',
          body: data
        }),
        transformResponse: (data: ApiSuccessResponse) => {
          return data.data as ParsedReceiptWrapper[];
        }
      }),
      deleteReceiptById: builder.mutation<void, string>({
        query: (id: string) => ({
          url: `/${id}`,
          method: 'DELETE'
        }),
        invalidatesTags: (result, error, arg) => [{ type: tagName, id: 'LIST' }]
      }),
      retryReceiptParse: builder.mutation<ReceiptProcessorResult, string>({
        query: (id: string) => ({
          url: `/parse-receipt/retry/${id}`,
          method: 'POST'
        }),
        // invalidatesTags: (result, error, arg) => [{ type: tagName, id: 'LIST' }],
        transformResponse: (data: ApiSuccessResponse) => {
          return data.data as ReceiptProcessorResult;
        }
      }),
      markReceiptAsArchivable: builder.mutation<void, string>({
        query: (id: string) => ({
          url: `/receipts/mark-as-archivable/${id}`,
          method: 'POST'
        })
      }),
      bulkSyncReceipt: builder.mutation<BatchProcessDto, ReceiptByOwnerBody>({
        query: (data) => ({
          url: `/parse-receipt/bulk-sync`,
          method: 'POST',
          body: data
        }),
        invalidatesTags: (result, error, arg) => [{ type: tagName, id: 'LIST' }],
        transformResponse: (data: ApiSuccessResponse) => {
          return data.data as BatchProcessDto;
        }
      }),
      bulkDeleteReceipt: builder.mutation<BatchProcessDto, ReceiptByOwnerBody>({
        query: (data) => ({
          url: `/receipts/bulk-delete`,
          method: 'DELETE',
          body: data
        }),
        invalidatesTags: (result, error, arg) => [{ type: tagName, id: 'LIST' }],
        transformResponse: (data: ApiSuccessResponse) => {
          return data.data as BatchProcessDto;
        }
      })
    })
  });

export const {
  useGetAllReceiptsQuery,
  useGetReceiptByOwnerQuery,
  useTestReceiptRuleMutation,
  useDeleteReceiptByIdMutation,
  useRetryReceiptParseMutation,
  useBulkSyncReceiptMutation,
  useBulkDeleteReceiptMutation,
  useMarkReceiptAsArchivableMutation
} = receiptApi;
export default receiptApi;
