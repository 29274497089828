import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Select from 'react-select';

import type { ParsedReceiptWrapper, SampleReceipt } from '../../../common/types';
import Breadcrumb from '../../../components/Breadcrumb';
import { useGetReceiptRuleByIdQuery } from '../../ReceiptRule/receipt-rule.api-slice';
import { useGetAllSampleReceiptsSelectQuery } from '../../sample-receipts/sample-receipt.api-slice';
import ParsedReceiptSummary from '../components/ParsedReceiptSummary';
import RuleSummary from '../components/RuleSummary';
import { useTestReceiptRuleMutation } from '../receipt.api-slice';

const ReceiptRuleTestPage: React.FC = () => {
  const { t } = useTranslation();
  const { ruleId } = useParams();
  const [selectedSampleReceipts, setSelectedSampleReceipts] = useState<string[]>([]);
  const [parseResultReady, setParseResultReady] = useState<boolean>(false);
  const { data: rule } = useGetReceiptRuleByIdQuery(ruleId ?? skipToken);

  const { data: sampleReceipts } = useGetAllSampleReceiptsSelectQuery();
  const [testRule, { data: parsedResult }] = useTestReceiptRuleMutation();

  const handleChangeItem = (newValue: any): void => {
    const currentSampleReceiptIds = newValue.map((sampleReceipt: SampleReceipt) => sampleReceipt.id);
    setSelectedSampleReceipts(currentSampleReceiptIds);
  };

  const handleSubmit = async (values: any, action: any): Promise<void> => {
    if (ruleId != null && selectedSampleReceipts.length !== 0) {
      await testRule({ ruleId, sampleReceiptIds: selectedSampleReceipts });
    }
  };

  useEffect(() => {
    if (parsedResult != null) {
      setParseResultReady(true);
    }
  }, [parsedResult]);

  return (
    <div>
      <div className="pb-4">
        <h1>{t('receiptRule.test.title')}</h1>
        <Breadcrumb items={[{ label: t('common.adminBreadcrumbLabel') }, { label: t('receiptRule.test.title') }]} />
      </div>

      <Formik onSubmit={handleSubmit} initialValues={{}}>
        {({ isSubmitting }) => (
          <Form className="flex w-full flex-col items-start px-2" autoComplete="off">
            <div className="flex w-full flex-col">
              <h1 className="text-2xl">Rule Summary</h1>
              {rule != null && <RuleSummary rule={rule} />}
            </div>

            <div className="flex w-full flex-col space-y-2 md:flex-row md:items-center md:justify-between">
              <div className="flex w-full flex-col">
                <label className="w-full text-left text-gray-600">Sample Receipt</label>
                <div className="flex flex-col">
                  <Select
                    isMulti={true}
                    options={sampleReceipts}
                    onChange={handleChangeItem}
                    getOptionLabel={(sampleReceipt) => sampleReceipt.title}
                    getOptionValue={(sampleReceipt) => sampleReceipt.receipt}
                  />
                </div>
              </div>
            </div>
            <div className="mt-4 flex w-full space-x-2 rounded-b border-t border-solid border-slate-200 py-6">
              <button type="button" className="w-1/2 rounded-lg bg-gray-200 px-5 py-2 text-black">
                {t('receiptRule.test.cancelBtnLabel')}
              </button>
              <button
                type="submit"
                disabled={isSubmitting}
                className="w-1/2 rounded-lg bg-greenColor px-4 py-2 text-white"
              >
                {t('users.add.saveBtnLabel')}
              </button>
            </div>
          </Form>
        )}
      </Formik>
      <div className="mt-10 flex flex-wrap justify-center">
        {parseResultReady &&
          (parsedResult != null) &&
          parsedResult.length > 0 &&
          parsedResult.map((result: ParsedReceiptWrapper, index: any) => <ParsedReceiptSummary key={index} result={result} />)}
      </div>
    </div>
  );
};

export default ReceiptRuleTestPage;
