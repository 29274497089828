import "react-datepicker/dist/react-datepicker.css";

import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { AiOutlineMinusCircle } from "react-icons/ai";

import { Action, type SpecialPointProduct } from "../../../../../common/types";
import CustomTextInputV3 from "../../../../../components/Form/CustomTextInputV3";
import HorizontalDivider from "../../../../devices/components/HorizontalDivider";
import { formatDateToYMD, isReadOnlyLabel } from "../../../helpers";

interface IProps {
    specialPointProduct: SpecialPointProduct;
    handleChange: (action: Action, lineItemComponent: SpecialPointProduct) => void;
  }

const SpecialPointProductComponent: React.FC<IProps> = ({ specialPointProduct, handleChange, ...props}) => {
  const [startDate, setStartDate] = useState((specialPointProduct.startDate !== "") ? new Date(Date.parse(specialPointProduct.startDate)) : new Date());
  const [endDate, setEndDate] = useState((specialPointProduct.endDate !== "") ? new Date(Date.parse(specialPointProduct.endDate)) : new Date());

  const handleDateChange = (name: string, value: Date): void => {
    let updated: SpecialPointProduct | null = null;
    if (name === 'start_date') {
      setStartDate(value);
      updated = {
        ...specialPointProduct,
        startDate: formatDateToYMD(value)
      }
    }

    if (name === 'end_date') {
      setEndDate(value);
      updated = {
        ...specialPointProduct,
        endDate: formatDateToYMD(value)
      }
    }

    if (updated !== null) {
      handleChange(Action.MODIFY, updated);
    }

  }

  const handlePropChange = (name: string, value: string): void => {
    let updated: SpecialPointProduct | null = null;

    if (name === 'name' && !isReadOnlyLabel(value)) {
      updated = { ...specialPointProduct, name: value };
    }
    
    if (specialPointProduct.value != null && name === 'value') {
      updated = {
        ...specialPointProduct,
        value: Number(value)
      };
    }

    if (updated !== null) {
      handleChange(Action.MODIFY, updated);
    }
  };

  const handleLineItemComponentDeletion = (): void => {
    handleChange(Action.REMOVE, specialPointProduct);
  };


    return (
        <div className="flex flex-col rounded-md bg-gray-300 p-2" {...props} id={specialPointProduct.id}>
          <div className="flex flex-row space-x-2 space-y-2">
            <div className="flex flex-1 flex-col space-y-2 font-medium">
              <div className="flex flex-row items-center justify-between space-x-2">
                {specialPointProduct.name != null && (
                  <>
                    <span>Name</span>
                    <CustomTextInputV3
                      type="text"
                      name="name"
                      value={specialPointProduct.name}
                      label=""
                      handleChange={handlePropChange}
                    />
                  </>
                )}
                {specialPointProduct.value != null && (
                  <>
                    <span>Value</span>
                    <CustomTextInputV3
                      type="text"
                      name="value"
                      value={specialPointProduct.value}
                      label=""
                      handleChange={handlePropChange}
                    />
                  </>
                )}
                {(
                  <>
                    <span>Start Date</span>
                    <DatePicker showIcon selected={startDate} onChange={(date: any) => { handleDateChange('start_date', date) }} dateFormat={'yyyy-MM-dd'}/>
                  </>
                )}
                {(
                  <>
                    <span>End Date</span>
                    <DatePicker showIcon selected={endDate} onChange={(date: any) => { handleDateChange('end_date', date) }} dateFormat={'yyyy-MM-dd'}/>
                  </>
                )}
              </div>
            </div>
    
            <button className="justify-center rounded-md px-3 py-2 text-sm" onClick={handleLineItemComponentDeletion}>
              <AiOutlineMinusCircle size={24} color="red" />
            </button>
          </div>
          <HorizontalDivider />
        </div>
    );
}

export default SpecialPointProductComponent;