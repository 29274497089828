import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineEdit, AiOutlineMail, AiOutlinePhone } from 'react-icons/ai';
import { FaLocationArrow } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { OwnerType } from '../../../common/constants';
import Breadcrumb from '../../../components/Breadcrumb';
import LoadingSpinner from '../../../components/LoadingSpinner';
import ReceiptStat from "../../dashboard/components/ReceiptStat";
import TopDeviceDashboard from "../../dashboard/components/TopDeviceStat";
import { useGetAgencyDashboardQuery } from '../../dashboard/dashboard-api-slice';
import MerchantDetailStatus from '../../merchants/components/MerchantDetailStatus';
import DataContainer from '../../stores/components/DefaultApiConfig/DataContainer';
import RuleDataContainer from '../../stores/components/DefaultRule/RuleDataContainer';
import { useGetAgencyByIdQuery } from '../agency.api-slice';
import EditAgency from '../components/EditAgency';

const AgencyDashboardPage: React.FC = () => {
  const { id } = useParams();
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const { data: agency, isLoading, isSuccess } = useGetAgencyByIdQuery(id ?? skipToken);
  const { data: agencyStats, isLoading: isStatsLoading } = useGetAgencyDashboardQuery(id ?? skipToken);
  const { t } = useTranslation();

  const toggleEditModal = (): void => {
    setOpenEditModal(!openEditModal);
  };

  if (Boolean(isLoading) || isStatsLoading || id == null) {
    return <LoadingSpinner />;
  }

  if (Boolean(isSuccess) && agency === undefined) {
    toast('No such agency exists');
    return <></>;
  }

  const stats = [
    {
      id: 'merchantCount',
      title: 'Merchant',
      count: agencyStats?.countDashboard.data.merchantCount != null ? agencyStats?.countDashboard.data.merchantCount : 0,
      link: `/admin/agencies/${id}/merchants`
    },
    {
      id: 'storeCount',
      title: 'Store',
      count: agencyStats?.countDashboard.data.storeCount != null ? agencyStats?.countDashboard.data.storeCount : 0,
      link: `/admin/agencies/${id}/stores`
    },
    {
      id: 'userCount',
      title: 'User',
      count: agencyStats?.countDashboard.data.userCount != null ? agencyStats?.countDashboard.data.userCount : 0,
      link: `/admin/agencies/${id}/users`
    },
    {
      id: 'deviceCount',
      title: 'Devices',
      count: agencyStats?.countDashboard.data.deviceCount != null ? agencyStats?.countDashboard.data.deviceCount : 0,
      link: `/admin/agencies/${id}/devices`
    }
  ];

  return (
    <>
      {agency != null && <EditAgency item={agency} isOpen={openEditModal} toggleEditModal={toggleEditModal} />}
      {agency != null && (
        <div>
          <div className="pb-4">
            <h1>Dashboard</h1>
            <Breadcrumb
              items={[
                { label: t('common.adminBreadcrumbLabel') },
                { label: t('agencies.list.breadcrumbLabel') },
                { label: t('agencies.dashboard.breadcrumbLabel') }
              ]}
            />
          </div>
          <div className="flex w-full">
            <div className="flex w-1/2 flex-col rounded-lg bg-gray-50 p-4">
              <div className="flex w-full items-center justify-between border-b-2 pb-4">
                <span className="text-center text-xl font-bold">{agency.name}</span>
                <div
                  className="flex rounded-lg bg-greenColor px-4 py-2 text-center text-gray-50"
                  onClick={toggleEditModal}
                >
                  <AiOutlineEdit size={24} />
                  <button className="text-sm">Edit</button>
                </div>
              </div>

              {/* Detail of Store */}
              <div className="flex w-full">
                <div className="w-1/2">
                  <div className="flex justify-between px-4 py-2">
                    <div className="flex items-center justify-between space-x-4">
                      <FaLocationArrow size={16} />
                      <span className="">{agency.address}</span>
                    </div>
                  </div>
                  <div className="flex justify-between px-4 py-2">
                    <div className="flex items-center justify-between space-x-4">
                      <AiOutlineMail size={16} />
                      <span className="">{agency.email}</span>
                    </div>
                  </div>
                  <div className="flex justify-between px-4 py-2">
                    <div className="flex items-center justify-between space-x-4">
                      <AiOutlinePhone size={16} />
                      <span className="">{agency.phone}</span>
                    </div>
                  </div>
                </div>
                {/* Status */}
                <div className="w-1/2 pl-16">
                  <div className="flex justify-between px-4 py-2">
                    <div className="flex items-center justify-between space-x-4">
                      <MerchantDetailStatus status={agency.isApproved} title="Approved" />
                    </div>
                  </div>
                  <div className="flex justify-between px-4 py-2">
                    <div className="flex items-center justify-between space-x-4">
                      <MerchantDetailStatus status={agency.isActive} title="Status" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Stat Cards */}
            <div className="flex w-1/2 flex-col">
              <div className="ml-2 flex w-full flex-col justify-between space-x-0 space-y-2  md:flex-row md:space-x-2 md:space-y-0">
                {stats.slice(0, 2).map((stat, i) => {
                  return (
                    <div
                      key={i}
                      className={`flex h-24 flex-col justify-between rounded-md bg-gray-100 px-4 py-2 sm:w-full md:w-1/2`}
                    >
                      <span className="px-2 text-center text-4xl">{stat.count}</span>
                      <Link to={stat.link} className="text-center hover:text-greenColor">
                        <span>{stat.title}</span>
                      </Link>
                    </div>
                  );
                })}
              </div>
              <div className="m-2 flex w-full flex-col justify-between space-x-0 space-y-2  md:flex-row md:space-x-2 md:space-y-0">
                {stats.slice(2, 4).map((stat) => {
                  return (
                    <div
                      key={stat.id}
                      className={`flex h-24 flex-col justify-between rounded-md bg-gray-100 px-4 py-2 sm:w-full md:w-1/2`}
                    >
                      <span className="px-2 text-center text-4xl">{stat.count}</span>
                      <Link to={stat.link} className="text-center hover:text-greenColor">
                        <span>{stat.title}</span>
                      </Link>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="mt-4 flex flex-col md:flex-row">
            <div className="flex w-full flex-col md:w-1/2">
              <div className="flex w-full justify-between px-4">
                <span className="text-lg">{agencyStats?.lastWeekReceiptsStats?.name}</span>
              </div>
              {((agencyStats?.lastWeekReceiptsStats?.data) != null) && <ReceiptStat statData={agencyStats.lastWeekReceiptsStats.data} />}
            </div>
            <div className="flex w-full flex-col md:w-1/2">
              <div className="flex w-full justify-between px-4">
                <span className="text-lg">{agencyStats?.topDeviceStats.name}</span>
              </div>
              {((agencyStats?.topDeviceStats?.data) != null) && <TopDeviceDashboard topDeviceData={agencyStats?.topDeviceStats.data} />}
            </div>

          </div>
          <div className="flex w-full space-x-2">
            <div className="mt-2 flex w-1/2 flex-col rounded-lg bg-gray-50 p-4">
              <RuleDataContainer ownerEntityId={agency.id} ownerEntityType={OwnerType.AGENCY} />
            </div>
            <div className="mt-2 flex w-1/2 flex-col rounded-lg bg-gray-50 p-4">
              <DataContainer ownerType={OwnerType.AGENCY} ownerEntity={agency} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AgencyDashboardPage;
