import { Outlet } from 'react-router-dom';

import Footer from './components/layout/footer';
import Navbar from './components/layout/navbar';

const LandingPage: React.FC = () => {
  return (
    <div className="bg-[#E6E9EB]">
      <Navbar />
      <div className="h-[70vh]">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default LandingPage;
