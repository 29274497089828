import { Form, Formik, type FormikHelpers } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useAuth } from '../../../app/hooks/useAuth';
import { UserRole } from '../../../common/constants';
import { extractErrorsFromApi } from '../../../common/helpers';
import { type Agency, type MerchantRequest } from '../../../common/types';
import type { ParentContext } from '../../../common/types/common';
import CustomSelect from '../../../components/Form/CustomSelect';
import RenderErrorBag from '../../../components/render-error-bag';
import { useLazyGetListOfAgenciesQuery } from '../../agencies/agency.api-slice';
import { useAddMerchantMutation } from '../merchant.api-slice';
import { AddMerchantSchema } from '../merchant.schema';
import AddMerchantForm from './AddMerchantForm';

interface IProps {
  isOpen: boolean;
  toggleModal: () => any;
  parentContext: ParentContext;
  agencyInContext?: Agency;
}

const initialData: MerchantRequest = {
  name: 'Merchant X',
  email: 'merx@example.com',
  phone: '123456',
  address: '123456'
};

interface Option {
  label: string;
  value: string;
}

const AddMerchant: React.FC<IProps> = ({ isOpen, toggleModal, agencyInContext, parentContext }) => {
  const [selectedAgency, setSelectedAgency] = useState<Option | undefined>(
    agencyInContext != null ? { value: agencyInContext.id, label: agencyInContext.name } : undefined
  );
  const [listOfAgencies, setListOfAgencies] = useState<Option[]>([]);
  const [addItem, { isLoading, isError, error, isSuccess, reset }] = useAddMerchantMutation();
  const [errorBag, setErrorBag] = useState<string[]>([]);
  const { t } = useTranslation();
  const { user } = useAuth();
  const [trigger, result] = useLazyGetListOfAgenciesQuery();

  const handleAddItem = async (values: MerchantRequest, actions: FormikHelpers<MerchantRequest>): Promise<any> => {
    await addItem({ ...values, agencyId: selectedAgency?.value ?? parentContext.agencyId });
    actions.resetForm();
  };

  const handleAgencyChange = (selectedValue: any): void => {
    setSelectedAgency(selectedValue);
  };

  useEffect(() => {
    if (isSuccess) {
      toast(t('merchants.add.successMessage'));
      reset();
      toggleModal();
    }
    if (isError && error != null) {
      setErrorBag(extractErrorsFromApi(error));
    }
  }, [isError, isSuccess]);

  useEffect(() => {
    if (user?.role === UserRole.SUPER_ADMIN && isOpen) {
      void trigger();
    }
  }, [isOpen, user]);

  useEffect(() => {
    if (result?.data != null && result.data.length > 0) {
      setListOfAgencies(
        result.data.map((agency: Agency) => {
          return { label: agency.name, value: agency.id };
        })
      );
    }
  }, [result]);

  return (
    <>
      {isOpen && (
        <>
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden outline-none focus:outline-none">
            <div className="relative mx-auto my-6 w-full max-w-3xl">
              <div className="relative flex w-full flex-col rounded-lg border-0 bg-white shadow-lg outline-none focus:outline-none">
                <div className="flex items-start justify-between rounded-t border-b border-solid border-slate-200 p-5">
                  <h3 className="text-3xl font-semibold">{t('merchants.add.title')}</h3>
                </div>
                <div className="relative w-full flex-auto p-6">
                  {errorBag.length > 0 && <RenderErrorBag errorBag={errorBag} />}
                  {
                    <Formik initialValues={initialData} onSubmit={handleAddItem} validationSchema={AddMerchantSchema}>
                      {({ isSubmitting }) => (
                        <Form className="flex w-full flex-col items-start px-2" autoComplete="off">
                          {user != null && user.role === UserRole.SUPER_ADMIN && (
                            <CustomSelect
                              label="Agency"
                              name="agencyId"
                              divClass="w-full flex flex-col "
                              labelClass="w-full text-left text-gray-600"
                              inputClass="w-full my-1 px-4 py-1 rounded-md"
                              options={listOfAgencies}
                              defaultValue={selectedAgency}
                              handleChange={handleAgencyChange}
                            />
                          )}
                          <AddMerchantForm />
                          <div className="mt-4 flex w-full space-x-2 rounded-b border-t border-solid border-slate-200 py-6">
                            <button className="w-1/2 rounded-lg bg-gray-200 px-5 py-2 text-black" onClick={toggleModal}>
                              {t('merchants.add.cancelBtnLabel')}
                            </button>
                            <button
                              type="submit"
                              disabled={isLoading || isSubmitting}
                              className="w-1/2 rounded-lg bg-greenColor px-4 py-2 text-white"
                            >
                              {t('merchants.add.saveBtnLabel')}
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
        </>
      )}
    </>
  );
};

export default AddMerchant;
