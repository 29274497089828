import { type Device } from '../../../common/types';
import ListDeviceItem from './ListDeviceItem';

interface IProps {
  items: Device[];
  pageType: 'confirmed' | 'pending';
  handleToggleActionModal: (action: string, item: Device) => any;
}

const ListDevices: React.FC<IProps> = ({ items: devices, handleToggleActionModal }) => {
  return (
    <>
      <div className="my-2 w-full overflow-auto rounded-md p-2">
        <table className="w-full border-b-2 border-gray-200 p-4">
          <thead className="bg-gray-100">
            <tr>
              <th className="p-2 pl-8 text-left text-sm font-semibold tracking-wide">Name</th>
              <th className="p-2 text-left text-sm font-semibold tracking-wide">Description</th>
              <th className="w-12 p-2 pr-8 text-sm font-semibold tracking-wide">Actions</th>
            </tr>
          </thead>
          <tbody className="">
            {devices.map((device) => {
              return <ListDeviceItem key={device.id} item={device} handleToggleActionModal={handleToggleActionModal} />;
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ListDevices;
