import { type SampleReceipt } from '../../../common/types';
import ListSampleReceiptItem from './ListSampleReceiptItem';

interface IProps {
  items: SampleReceipt[];
  handleToggleActionModal: (action: string, item: SampleReceipt) => any;
}

const ListSampleReceipts: React.FC<IProps> = ({
  items: records,
  handleToggleActionModal
}) => {
  return (
    <>
      <div className="my-2 w-full overflow-auto rounded-md p-2">
        <table className="w-full border-b-2 border-gray-200 p-4">
          <thead className="bg-gray-100">
            <tr className="text-sm font-semibold">
              <th className="p-2 pl-8 text-left">Title</th>
              <th className="p-2 text-left">Receipt</th>
              <th className="p-2 text-left ">Owner</th>
              <th className="p-2 pr-8">Actions</th>
            </tr>
          </thead>
          <tbody className="">
            {records.map((record) => {
              return (
                <ListSampleReceiptItem
                  key={record.id}
                  item={record}
                  handleToggleActionModal={handleToggleActionModal}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ListSampleReceipts;
