import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useParams } from 'react-router-dom';

import { OwnerType } from '../../../common/constants';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { useGetDeviceByIdQuery } from '../../devices/device.api-slice';
import ListSampleReceiptsPage from './ListSampleReceiptsPage';

const ListDeviceSampleReceiptsPage: React.FC = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetDeviceByIdQuery(id != null ? id : skipToken);

  if (isLoading || id == null) {
    return <LoadingSpinner />;
  }

  return <ListSampleReceiptsPage filter="device" device={data} ownerEntityType={OwnerType.DEVICE} ownerEntity={data} />;
};

export default ListDeviceSampleReceiptsPage;
