import { AiOutlineDelete, AiOutlineEye } from 'react-icons/ai';
import { FaRedo } from 'react-icons/fa';

import { type ActionItem } from '../../../common/constants';
import type { Receipt } from '../../../common/types';
import ListReceiptItem from './ListReceiptItem';

interface IProps {
  items: Receipt[];
  activeItemId?: string;
  toggleDeleteModal: () => any;
  toggleInfoModal: () => any;
  toggleRetryModal: () => any;
  handleDeletingItem: (item: Receipt) => any;
  handleInfoItem: (item: Receipt) => any;
  handleRetryItem: (item: Receipt) => any;
}

const ListReceipts: React.FC<IProps> = ({
  items: records,
  activeItemId,
  toggleDeleteModal,
  toggleInfoModal,
  toggleRetryModal,
  handleDeletingItem,
  handleInfoItem,
  handleRetryItem
}) => {
  const deleteItemHandler = (item: Receipt): void => {
    toggleDeleteModal();
    handleDeletingItem(item);
  };

  const infoHandler = (item: Receipt): void => {
    toggleInfoModal();
    handleInfoItem(item);
  };

  const retryHandler = (item: Receipt): void => {
    toggleRetryModal();
    handleRetryItem(item);
  };

  const getActionsList = (receipt: Receipt): any[] => {
    const actions: ActionItem[] = [];
    actions.push({
      type: 'component',
      label: 'View Receipt & Result',
      icon: (
        <button>
          <span className="h-full px-1">
            <AiOutlineEye
              size={18}
              onClick={() => {
                infoHandler(receipt);
              }}
            />
          </span>
        </button>
      )
    });

    if (receipt.status !== 'success') {
      actions.push({
        type: 'component',
        label: 'Reprocess the receipt',
        icon: (
          <button>
            <span className="h-full px-1">
              <FaRedo
                size={18}
                onClick={() => {
                  retryHandler(receipt);
                }}
              />
            </span>
          </button>
        )
      });
    }

    actions.push({
      type: 'component',
      label: 'Delete',
      icon: (
        <button
          onClick={() => {
            deleteItemHandler(receipt);
          }}
          className="rounded-md text-center text-sm text-gray-50"
        >
          <span className="h-full px-1">
            <AiOutlineDelete color="red" size={18} />
          </span>
        </button>
      )
    });

    return actions;
  };

  return (
    <>
      <div className="my-2 w-full overflow-auto rounded-md p-2">
        <table className="w-full border-b-2 border-gray-200 p-4">
          <thead className="bg-gray-100">
            <tr>
              <th className="p-2 pl-8 text-left text-sm font-semibold">ID</th>
              <th className="p-2 pl-8 text-left text-sm font-semibold">Device</th>
              <th className="p-2 text-left text-sm font-semibold">Status</th>
              <th className="p-2 text-left text-sm font-semibold">Reason</th>
              <th className="p-2 text-left text-sm font-semibold">Retry Count</th>
              <th className="p-2 text-left text-sm font-semibold">Date Created</th>
              <th className="p-2 text-left text-sm font-semibold ">ProcessedDate</th>
              <th className="p-2 pr-8 text-sm font-semibold">Actions</th>
            </tr>
          </thead>
          <tbody className="">
            {records.map((record) => {
              return <ListReceiptItem key={record.id} item={record} actions={getActionsList(record)} isActive={record.id === activeItemId} />;
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ListReceipts;
