import { useEffect, useState } from 'react';

import { type PaginatedMeta } from '../common/types';

interface IProps {
  pageData?: PaginatedMeta;
  handlePageChange?: any;
}

const emptyPageMeta: PaginatedMeta = {
  currentPage: 1,
  perPage: 10,
  totalItemCount: 0,
  pageCount: 1,
  hasPreviousPage: false,
  hasNextPage: false
};

const getStartOfPage = (currentPage: number, perPage: number, totalItemCount: number): number => {
  const firstItemInPage = ((currentPage-1) * perPage) + 1;

  if (totalItemCount === 0) {
    return 0;
  }

  if ( totalItemCount > 0 && firstItemInPage <= 0) {
    return 1;
  }

  return firstItemInPage;
  }

const getEndOfPage = (currentPage: number, perPage: number, totalItemCount: number): number => {
  const lastItemInPage = currentPage * perPage;
  if (totalItemCount < lastItemInPage) {
    return totalItemCount;
  }

  return lastItemInPage;
}

const Pagination: React.FC<IProps> = ({ pageData, handlePageChange }) => {
  const [pageMeta, setPageMeta] = useState<PaginatedMeta>(pageData ?? emptyPageMeta);

  useEffect(() => {
    if (pageData != null) {
      setPageMeta(pageData);
    }
  }, [pageData]);

  const NextPage = (): void => {
    if (pageMeta.hasNextPage) {
      handlePageChange(pageMeta.currentPage + 1);
    }
  };

  const PrevPage = (): void => {
    if (pageMeta.hasPreviousPage) {
      handlePageChange(pageMeta.currentPage - 1);
    }
  };

  return (
    <div className="flex flex-col items-center">
      <span className="text-lg text-gray-700 dark:text-gray-400">
          Showing <span className="font-semibold text-gray-900">{getStartOfPage(pageMeta.currentPage, pageMeta.perPage, pageMeta.totalItemCount)}</span> to <span className="font-semibold text-gray-900">{getEndOfPage(pageMeta.currentPage, pageMeta.perPage, pageMeta.totalItemCount)}</span> of <span className="font-semibold text-gray-900">{pageMeta.totalItemCount}</span> Records
      </span>
      <div className="xs:mt-0 mt-2 inline-flex">
        <button onClick={PrevPage} disabled={!pageMeta.hasPreviousPage}  className="flex h-10 items-center justify-center rounded-l bg-gray-800 px-4 text-base font-medium text-white hover:bg-primary disabled:bg-gray-300 disabled:text-black">
            <svg className="mr-2 h-3.5 w-3.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 5H1m0 0 4 4M1 5l4-4"/>
            </svg>
            Prev
        </button>
        <button onClick={NextPage} disabled={!pageMeta.hasNextPage} className="flex h-10 items-center justify-center rounded-r border-0 border-l border-gray-700 bg-gray-800 px-4 text-base font-medium text-white hover:bg-primary disabled:bg-gray-300 disabled:text-black">
            Next
            <svg className="ml-2 h-3.5 w-3.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Pagination;
