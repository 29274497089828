import { Form, Formik, type FormikHelpers } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useAuth } from '../../../app/hooks/useAuth';
import { UserRole } from '../../../common/constants';
import { extractErrorsFromApi } from '../../../common/helpers';
import type {
  Agency,
  Merchant,
  MerchantStore,
  ParentContext,
  RoleOption,
  UserCreateFormDto
} from '../../../common/types';
import CustomSelect from '../../../components/Form/CustomSelect';
import RenderErrorBag from '../../../components/render-error-bag';
import { useLazyGetListOfAgenciesQuery } from '../../agencies/agency.api-slice';
import { useLazyGetListOfMerchantsQuery } from '../../merchants/merchant.api-slice';
import { useLazyGetListOfStoresQuery } from '../../stores/store.api-slice';
import { useAddUserMutation } from '../user.api-slice';
import { UserAddSchema } from '../user.schema';
import AddUserForm from './AddUserForm';

interface IProps {
  isOpen: boolean;
  toggleModal: () => any;
  availableRoles: RoleOption[];
  injectedIds: ParentContext;
  agencyInContext?: Agency;
  merchantInContext?: Merchant;
  storeInContext?: MerchantStore;
}

const initialData: UserCreateFormDto = {
  role: UserRole.EMPLOYEE,
  fullname: '',
  email: '',
  password: '',
  passwordConfirmation: ''
};

interface Option {
  label: string;
  value: string;
}

const AddUser: React.FC<IProps> = ({
  isOpen,
  toggleModal,
  availableRoles,
  injectedIds,
  agencyInContext,
  merchantInContext,
  storeInContext
}) => {
  const [selectedRole, setSelectedRole] = useState<Option | undefined>();
  const [selectedAgency, setSelectedAgency] = useState<Option | undefined>(
    agencyInContext != null ? { value: agencyInContext.id, label: agencyInContext.name } : undefined
  );
  const [selectedMerchant, setSelectedMerchant] = useState<Option | undefined>(
    merchantInContext != null ? { value: merchantInContext.id, label: merchantInContext.name } : undefined
  );
  const [selectedStore, setSelectedStore] = useState<Option | undefined>(
    storeInContext != null ? { value: storeInContext.id, label: storeInContext.name } : undefined
  );
  const [listOfAgencies, setListOfAgencies] = useState<Option[]>([]);
  const [listOfMerchants, setListOfMerchants] = useState<Option[]>([]);
  const [listOfStores, setListOfStores] = useState<Option[]>([]);
  const [addItem, { isLoading, isError, error, isSuccess, reset }] = useAddUserMutation();
  const [errorBag, setErrorBag] = useState<string[]>([]);
  const { t } = useTranslation();
  const { user } = useAuth();
  const [triggerFetchAgencies, resultAgencies] = useLazyGetListOfAgenciesQuery();
  const [triggerFetchMerchants, resultMerchants] = useLazyGetListOfMerchantsQuery();
  const [triggerFetchStores, resultStores] = useLazyGetListOfStoresQuery();

  const handleAddItem = async (values: UserCreateFormDto, actions: FormikHelpers<UserCreateFormDto>): Promise<any> => {
    await addItem({
      ...values,
      agencyId: selectedAgency?.value ?? injectedIds.agencyId,
      merchantId: selectedMerchant?.value ?? injectedIds.merchantId,
      storeId: selectedStore?.value ?? injectedIds.storeId
    });
    actions.resetForm();
    toggleModal();
  };

  const handleRoleChange = (selectedValue: any): void => {
    setSelectedRole(selectedValue);
  };

  const handleAgencyChange = (selectedValue: any): void => {
    setSelectedAgency(selectedValue);
  };

  const handleMerchantChange = (selectedValue: any): void => {
    setSelectedMerchant(selectedValue);
  };

  const handleStoreChange = (selectedValue: any): void => {
    setSelectedStore(selectedValue);
  };

  useEffect(() => {
    if (isSuccess) {
      toast(t('users.add.successMessage'));
      reset();
    }
    if (isError && error != null) {
      setErrorBag(extractErrorsFromApi(error));
    }
  }, [isError, isSuccess]);

  useEffect(() => {
    if (isOpen && user?.role === UserRole.SUPER_ADMIN && selectedRole?.value !== UserRole.SUPER_ADMIN) {
      void triggerFetchAgencies();
    }

    if (
      isOpen &&
      (user?.role === UserRole.SUPER_ADMIN || user?.role === UserRole.AGENCY_ADMIN) &&
      selectedRole?.value !== UserRole.SUPER_ADMIN &&
      selectedRole?.value !== UserRole.AGENCY_ADMIN &&
      injectedIds?.agencyId != null
    ) {
      void triggerFetchMerchants(injectedIds?.agencyId);
    } else if (
      isOpen &&
      (user?.role === UserRole.SUPER_ADMIN || user?.role === UserRole.AGENCY_ADMIN) &&
      selectedRole?.value !== UserRole.SUPER_ADMIN &&
      selectedRole?.value !== UserRole.AGENCY_ADMIN &&
      selectedAgency?.value != null
    ) {
      void triggerFetchMerchants(selectedAgency.value);
    } else if (isOpen && selectedRole?.value === UserRole.MERCHANT_ADMIN && selectedAgency?.value != null) {
      void triggerFetchMerchants(selectedAgency.value);
    }

    if (
      isOpen &&
      (selectedRole?.value === UserRole.STORE_ADMIN || selectedRole?.value === UserRole.EMPLOYEE) &&
      selectedMerchant?.value != null
    ) {
      void triggerFetchStores(selectedMerchant.value);
    }
  }, [isOpen, selectedRole, injectedIds.agencyId, selectedAgency?.value, selectedMerchant?.value]);

  useEffect(() => {
    if (resultAgencies?.data != null && resultAgencies.data.length > 0) {
      setListOfAgencies(
        resultAgencies.data.map((agency) => {
          return { label: agency.name, value: agency.id };
        })
      );
    } else {
      setListOfAgencies([]);
    }

    if (resultMerchants?.data != null && resultMerchants.data.length > 0) {
      setListOfMerchants(
        resultMerchants.data.map((merchant) => {
          return { label: merchant.name, value: merchant.id };
        })
      );
    } else {
      setListOfMerchants([]);
    }

    if (resultStores?.data != null && resultStores.data.length > 0) {
      setListOfStores(
        resultStores.data.map((store: MerchantStore) => {
          return { label: store.name, value: store.id };
        })
      );
    } else {
      setListOfStores([]);
    }
  }, [resultAgencies, resultMerchants, resultStores]);

  return (
    <>
      {isOpen && (
        <>
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden outline-none focus:outline-none">
            <div className="relative mx-auto my-6 w-full max-w-3xl">
              <div className="relative flex w-full flex-col rounded-lg border-0 bg-white shadow-lg outline-none focus:outline-none">
                <div className="flex items-start justify-between rounded-t border-b border-solid border-slate-200 p-5">
                  <h3 className="text-3xl font-semibold">{t('users.add.title')}</h3>
                </div>
                <div className="relative w-full flex-auto p-6">
                  {errorBag.length > 0 && <RenderErrorBag errorBag={errorBag} />}
                  {
                    <Formik initialValues={initialData} onSubmit={handleAddItem} validationSchema={UserAddSchema}>
                      {({ isSubmitting }) => (
                        <Form className="flex w-full flex-col items-start px-2" autoComplete="off">
                          <CustomSelect
                            label="Role"
                            name="role"
                            divClass="w-full flex flex-col "
                            labelClass="w-full text-left text-gray-600"
                            inputClass="w-full my-1 px-4 py-1 rounded-md"
                            options={availableRoles}
                            handleChange={handleRoleChange}
                          />
                          {selectedRole?.value != null &&
                            selectedRole?.value !== UserRole.SUPER_ADMIN &&
                            user?.role === UserRole.SUPER_ADMIN && (
                              <CustomSelect
                                label="Agency"
                                name="agencyId"
                                divClass="w-full flex flex-col "
                                labelClass="w-full text-left text-gray-600"
                                inputClass="w-full my-1 px-4 py-1 rounded-md"
                                options={listOfAgencies}
                                handleChange={handleAgencyChange}
                              />
                            )}
                          {selectedRole?.value != null &&
                            selectedRole?.value !== UserRole.SUPER_ADMIN &&
                            selectedRole?.value !== UserRole.AGENCY_ADMIN &&
                            (user?.role === UserRole.SUPER_ADMIN || user?.role === UserRole.AGENCY_ADMIN) && (
                              <CustomSelect
                                label="Merchant"
                                name="merchantId"
                                divClass="w-full flex flex-col "
                                labelClass="w-full text-left text-gray-600"
                                inputClass="w-full my-1 px-4 py-1 rounded-md"
                                options={listOfMerchants}
                                handleChange={handleMerchantChange}
                              />
                            )}
                          {((selectedRole?.value != null && selectedRole?.value === UserRole.STORE_ADMIN) ||
                            selectedRole?.value === UserRole.EMPLOYEE) && (
                            <CustomSelect
                              label="Store"
                              name="storeId"
                              divClass="w-full flex flex-col "
                              labelClass="w-full text-left text-gray-600"
                              inputClass="w-full my-1 px-4 py-1 rounded-md"
                              options={listOfStores}
                              handleChange={handleStoreChange}
                            />
                          )}
                          <AddUserForm availableRoles={availableRoles} />
                          <div className="mt-4 flex w-full space-x-2 rounded-b border-t border-solid border-slate-200 py-6">
                            <button className="w-1/2 rounded-lg bg-gray-200 px-5 py-2 text-black" onClick={toggleModal}>
                              {t('users.add.cancelBtnLabel')}
                            </button>
                            <button
                              type="submit"
                              disabled={isLoading || isSubmitting}
                              className="w-1/2 rounded-lg bg-greenColor px-4 py-2 text-white">
                              {t('users.add.saveBtnLabel')}
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
        </>
      )}
    </>
  );
};

export default AddUser;
