import { AiOutlineEye } from 'react-icons/ai';
import { Link } from 'react-router-dom';

import { type Merchant } from '../../../common/types';

interface IProps {
  merchants: Merchant[];
}

const RecentMerchants: React.FC<IProps> = ({ merchants }) => {
  return (
    <div className="my-2 flex w-full flex-col space-y-2 overflow-auto border-b-gray-50 p-2">
      {merchants.map((merchant) => {
        return (
          <div
            key={`recent-merchant-${merchant.id}`}
            className="flex w-full items-center justify-between justify-items-center rounded-md bg-gray-50 p-4"
          >
            <span>{merchant.name}</span>
            <span>
              <Link to={`/admin/merchants/${merchant.id}`}>
                <AiOutlineEye size={20} />
              </Link>
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default RecentMerchants;
