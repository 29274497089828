import { AiOutlineCopy, AiOutlineFileSync } from 'react-icons/ai';
import { Link } from 'react-router-dom';

import type { ItemActionProps } from '../../../common/types';
import type { ReceiptRuleV2Dto } from '../types';
import ReceiptRuleActionsList from './ReceiptRuleActionList';
import { FaFileImport } from 'react-icons/fa';

const ListReceiptRuleItem: React.FC<ItemActionProps<ReceiptRuleV2Dto>> = ({ item, handleToggleActionModal }) => {
  return (
    <tr className="text-sm text-gray-700 odd:bg-white even:bg-gray-50 hover:bg-gray-300" key={item.id}>
      <td className="p-2 pl-8 text-xs">{item.id}</td>
      <td className="p-2">{item.name}</td>
      <td className="p-2">{item.ownerEntityType.toUpperCase()} - {item.ownerEntityId}</td>
      <td className="w-24 whitespace-nowrap p-2 pr-8 text-left">
        <div className="flex flex-row items-center space-x-3">
          <Link to={`/admin/rules/${item.id}/test`}>
            <span className="h-full px-1">
              <AiOutlineFileSync size={18} />
            </span>
          </Link>
          <Link to={`/admin/rules/${item.id}/copy`}>
            <span className="h-full px-1">
              <AiOutlineCopy size={18} />
            </span>
          </Link>
          <Link to={`/admin/rules/${item.id}/bonus-items`}>
            <span className="h-full px-1">
              <FaFileImport size={18} />
            </span>
          </Link>
          <ReceiptRuleActionsList
            item={item}
            handleToggleActionModal={handleToggleActionModal}
          />
        </div>
      </td>
    </tr>
  );
};

export default ListReceiptRuleItem;
