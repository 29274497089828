import { useField } from 'formik';
import Select from 'react-select';

interface Option {
  label: string;
  value: string;
}

interface CustomSelectInputProps {
  name: string;
  placeholder?: string;
  label: string;
  options: Option[];
  divClass: string;
  labelClass: string;
  inputClass: string;
  defaultValue?: { label: string; value: string };
  handleChange?: (selectedOption: any) => void;
}

const CustomSelectInput: React.FC<CustomSelectInputProps> = ({
  label,
  divClass,
  labelClass,
  inputClass,
  options,
  defaultValue,
  handleChange,
  ...props
}: CustomSelectInputProps) => {
  const [, meta, { setValue }] = useField(props);

  const isError = Boolean(meta.touched && meta.error);

  const handleChangeItem = (newValue: any): void => {
    setValue(newValue.value);

    if (handleChange != null) {
      handleChange(newValue);
    }
  };

  return (
    <div className={divClass}>
      <label className={labelClass}>{label}</label>
      <div className="flex flex-col">
        <Select options={options} onChange={handleChangeItem} defaultValue={defaultValue} />
        {isError && <div className="text-left text-xs text-red-600">{meta.error}</div>}
      </div>
    </div>
  );
};

export default CustomSelectInput;
