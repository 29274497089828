import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useParams } from 'react-router-dom';

import { useGetMerchantByIdQuery } from '../../merchants/merchant.api-slice';
import ListDevicesPage from './ListDevicesPage';

const ListDevicesForMerchantPage: React.FC = () => {
  const { id } = useParams();
  const { data } = useGetMerchantByIdQuery(id ?? skipToken);

  return <ListDevicesPage merchant={data} scope={'merchant'} />;
};

export default ListDevicesForMerchantPage;
