import { AiOutlineCheck } from 'react-icons/ai';
import { FaTimes } from 'react-icons/fa';

interface IMerchantDetailStatusProps {
  title: string;
  status: boolean;
}

const MerchantDetailStatus: React.FC<IMerchantDetailStatusProps> = ({ title, status }) => {
  return (
    <>
      <span className="font-bold">{title}</span>
      {status && <AiOutlineCheck size={24} color="green" />}
      {!status && <FaTimes size={24} color="red" />}
    </>
  );
};

export default MerchantDetailStatus;
