import { AiOutlineCheck } from 'react-icons/ai';
import { FaTimes } from 'react-icons/fa';

interface IStoreDetailStatusProps {
  title: string;
  status: boolean;
}

const StoreDetailStatus: React.FC<IStoreDetailStatusProps> = ({ title, status }) => {
  return (
    <>
      <span className="font-bold">{title}</span>
      {status && <AiOutlineCheck size={24} color="green" />}
      {!status && <FaTimes size={24} color="red" />}
    </>
  );
};

export default StoreDetailStatus;
