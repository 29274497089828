interface BreadcrumbItem {
  label: string;
  link?: string;
}
interface IProps {
  items: BreadcrumbItem[];
}

const Breadcrumb: React.FC<IProps> = ({ items }) => {
  return (
    <>
      {items.map((item, index) => {
        return (
          <span className="text-gray-500" key={index}>
            {item.label}
            {index === items.length - 1 ? '' : ' > '}
          </span>
        );
      })}
    </>
  );
};

export default Breadcrumb;
