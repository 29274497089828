import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useParams } from 'react-router-dom';

import { useGetAgencyByIdQuery } from '../../agencies/agency.api-slice';
import ListStoresPage from './ListStoresPage';

const ListStoresForAgency: React.FC = () => {
  const { id } = useParams();
  const { data } = useGetAgencyByIdQuery(id ?? skipToken);

  return <ListStoresPage agency={data} scope={'agency'} />;
};

export default ListStoresForAgency;
