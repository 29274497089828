import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useParams } from 'react-router-dom';

import { useGetMerchantByIdQuery } from '../../merchants/merchant.api-slice';
import ListStoresPage from './ListStoresPage';

const ListStoresForMerchant: React.FC = () => {
  const { id } = useParams();
  const { data } = useGetMerchantByIdQuery(id ?? skipToken);

  return <ListStoresPage merchant={data} scope={'merchant'} />;
};

export default ListStoresForMerchant;
