import { skipToken } from '@reduxjs/toolkit/query';

import { type PaginatedData,type User } from '../../../common/types';
import { useGetUsersForAgencyQuery, useGetUsersForMerchantQuery, useGetUsersForStoreQuery, useGetUsersQuery } from '../user.api-slice';

interface UserQueryInputs {
  page: number;
  scope?: 'all' | 'agency' | 'merchant' | 'merchantStore' | undefined;
  filter?: { pending: boolean };
  agencyId?: string | undefined;
  merchantId?: string | undefined;
  storeId?: string | undefined;
}

const useCustomUsersQuery = ({
  page,
  scope,
  filter,
  agencyId,
  merchantId,
  storeId
}: UserQueryInputs): { isLoading: boolean; users: PaginatedData<User> | undefined } => {
    const { data: users, isLoading } =
    scope === 'agency'
      ? useGetUsersForAgencyQuery(agencyId != null ? { page, id: agencyId } : skipToken)
      : scope === 'merchant'
      ? useGetUsersForMerchantQuery(merchantId != null ? { page, id: merchantId } : skipToken)
      : scope === 'merchantStore'
      ? useGetUsersForStoreQuery(storeId != null ? { page, id: storeId } : skipToken)
      : useGetUsersQuery({ page, pendingOnly: filter?.pending === true });

  return { isLoading, users };
};

export default useCustomUsersQuery;
