import { Form, Formik, type FormikHelpers } from 'formik';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { type ProfileUser } from '../../../common/types';
import CustomTextInput from '../../../components/Form/custom-text-input';
import RenderErrorBag from '../../../components/render-error-bag';
import MyProfileReadonlyInfo from '../components/MyProfileReadonlyInfo';
import { useGetProfileQuery, useUpdateProfileMutation } from '../user.api-slice';
import { ProfileSchema } from '../user.schema';

const MyProfile: React.FC = () => {
  const { data: profile } = useGetProfileQuery();
  const [updateMyProfile, { isLoading }] = useUpdateProfileMutation();

  const [errorBag] = useState<string[]>([]);

  const handleUpdateProfile = async (values: ProfileUser, actions: FormikHelpers<ProfileUser>): Promise<any> => {
    await updateMyProfile({ body: values });
    toast('Profile is successfully updated');
  };

  return (
    <div className="mx-auto my-8 flex min-h-[70vh] max-w-2xl flex-col items-start rounded-md bg-gray-50 p-8">
      <div className="flex w-full items-center justify-center">
        <span className="h-full w-full text-2xl">Edit Profile</span>
      </div>
      <div className="mt-4 flex w-full flex-col items-start px-2">
        {errorBag.length > 0 && <RenderErrorBag errorBag={errorBag} />}
        {profile != null && (
          <Formik initialValues={profile} onSubmit={handleUpdateProfile} validationSchema={ProfileSchema}>
            {({ isSubmitting }) => (
              <Form className="flex w-full flex-col items-start space-y-2" autoComplete="off">
                <CustomTextInput
                  type="text"
                  label="Fullname"
                  name="fullname"
                  divClass="w-full flex flex-col "
                  labelClass="w-full text-left text-gray-600"
                  inputClass="w-full my-1 px-4 py-1 rounded-md"
                />
                <MyProfileReadonlyInfo label="Email" type="text" value={profile.email} />
                <MyProfileReadonlyInfo label="Activation Status" type="boolean" value={profile.isActive} />
                <MyProfileReadonlyInfo label="Approved" type="boolean" value={profile.isApproved} />
                <div className="mt-4 flex w-full space-x-2">
                  <button
                    type="submit"
                    disabled={isLoading || isSubmitting}
                    className="w-1/2 rounded-lg bg-greenColor px-4 py-2 text-white"
                  >
                    Submit
                  </button>
                  <Link to="/admin" className="w-1/2">
                    <button className="ml-4 w-full rounded-lg bg-gray-200 px-5 py-2 text-black">Cancel</button>
                  </Link>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </div>
  );
};

export default MyProfile;
