import { FaUser } from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';

interface NonDefaultCardIconProps {
    id: string;
    hasNonDefaultCard: boolean;
    card?: string;
}

const NonDefaultCardIcon: React.FC<NonDefaultCardIconProps> = ({id, hasNonDefaultCard, card}) => {
    if (hasNonDefaultCard) {
        return (
        <div className='flex flex-col' data-tooltip-id={`card-detail-${id}`} data-tooltip-content={`${card ?? ''}`}>
            <span className="items-center pl-2 pt-1"><FaUser size={12} color="green" /></span>
            <Tooltip id={`card-detail-${id}`} />
        </div>
        );
    } else {
        return null;
    }
}

export default NonDefaultCardIcon;