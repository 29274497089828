import { apiSlice } from '../../app/services/api-slice';
import type {ApiSuccessResponse, DashboardList} from '../../common/types';

const tagName: string = 'Dashboard';

const tagApi = apiSlice
  .enhanceEndpoints({
    addTagTypes: [tagName]
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getGlobalDashboard: builder.query<DashboardList, void>({
        query: () => '/dashboard',
        transformResponse: (data: ApiSuccessResponse) => {
          return data.data as DashboardList;
        },
        providesTags: (result) => [{ type: tagName, id: 'LIST' }]
      }),
      getAgencyDashboard: builder.query<DashboardList, string>({
        query: (agencyId: string) => `/dashboard/by-agency/${agencyId}`,
        transformResponse: (data: ApiSuccessResponse) => {
          return data.data as DashboardList;
        },
        providesTags: (result) => (result != null ? [{ type: tagName }] : [])
      }),
      getMerchantDashboard: builder.query<DashboardList, string>({
        query: (merchantId: string) => `/dashboard/by-merchant/${merchantId}`,
        transformResponse: (data: ApiSuccessResponse) => {
          return data.data as DashboardList;
        },
        providesTags: (result) => (result != null ? [{ type: tagName }] : [])
      }),
      getStoreDashboard: builder.query<DashboardList, string>({
        query: (storeId: string) => `/dashboard/by-store/${storeId}`,
        transformResponse: (data: ApiSuccessResponse) => {
          return data.data as DashboardList;
        },
        providesTags: (result) => (result != null ? [{ type: tagName }] : [])
      })
    }),
    overrideExisting: false
  });

export const {
  useGetGlobalDashboardQuery,
  useGetAgencyDashboardQuery,
  useGetMerchantDashboardQuery,
  useGetStoreDashboardQuery
} = tagApi;
