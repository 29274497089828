import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

import { useAuth } from '../../../app/hooks/useAuth';
import { OwnerType } from '../../../common/constants';
import { getAgencyMerchantStoreBasedOnScopeAndRole } from '../../../common/helpers';
import type { Agency, Merchant, MerchantStore } from '../../../common/types';
import Breadcrumb from '../../../components/Breadcrumb';
import LoadingSpinner from '../../../components/LoadingSpinner';
import Pagination from '../../../components/Pagination';
import SuccessMessage from '../../../components/success-message';
import AddStore from '../components/AddStore';
import ApproveStore from '../components/ApproveStore';
import AddOrEditApiConfig from '../components/DefaultApiConfig/AddOrEditApiConfig';
import DeleteStore from '../components/DeleteStore';
import EditStore from '../components/EditStore';
import ListStores from '../components/ListStores';
import SearchStore from '../components/SearchStore';
import useCustomMerchantStoreQuery from '../hooks/custom-merchant-store-hook';

interface IProps {
  scope?: 'all' | 'agency' | 'merchant';
  filter?: { pending: boolean };
  agency?: Agency;
  merchant?: Merchant;
}

const ListStoresPage: React.FC<IProps> = ({ filter, agency, merchant, scope = 'all' }) => {
  const { user } = useAuth();
  const [openAddModal, setOpenAddModal] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openApproveModal, setOpenApproveModal] = useState<boolean>(false);
  const [openApiConfigModal, setOpenApiConfigModal] = useState<boolean>(false);
  const parentContext = getAgencyMerchantStoreBasedOnScopeAndRole({ loggedInUser: user, scope, agency, merchant });
  const [selectedItem, setSelectedItem] = useState<MerchantStore | null>(null);

  const [page, setPage] = useState<number>(1);
  const [term, setTerm] = useState<string>('');
  const { t } = useTranslation();
  const { merchantStores, isLoading } = useCustomMerchantStoreQuery({
    page,
    term,
    scope,
    filter,
    agencyId: agency?.id,
    merchantId: merchant?.id
  });

  const pageTitle =
    scope === 'agency' && agency != null
      ? `List of Stores for ${agency?.name}`
      : scope === 'merchant' && merchant != null
      ? `List of Stores for ${merchant?.name}`
      : filter?.pending === true
      ? 'List of Pending Stores'
      : 'List of Stores';

  const handleSearch = (term: string): void => {
    setTerm(term);
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const toggleAddModal = (): void => {
    setOpenAddModal(!openAddModal);
  };

  const toggleEditModal = (): void => {
    if (openEditModal) {
      setSelectedItem(null);
    }
    setOpenEditModal(!openEditModal);
  };

  const toggleDeleteModal = (): void => {
    if (openDeleteModal) {
      setSelectedItem(null);
    }
    setOpenDeleteModal(!openDeleteModal);
  };

  const toggleApproveModal = (): void => {
    if (openApproveModal) {
      setSelectedItem(null);
    }
    setOpenApproveModal(!openApproveModal);
  };

  const toggleApiConfigModal = (): void => {
    if (openApiConfigModal) {
      setSelectedItem(null);
    }
    setOpenApiConfigModal(!openApiConfigModal);
  };

  const handleToggleActionModal = (action: string, item: MerchantStore): void => {
    if (action === 'edit') {
      setOpenEditModal((openEditModal) => !openEditModal);
      setSelectedItem(item);
    }

    if (action === 'delete') {
      setOpenDeleteModal((openDeleteModal) => !openDeleteModal);
      setSelectedItem(item);
    }

    if (action === 'approve') {
      setOpenApproveModal((openApproveModal) => !openApproveModal);
      setSelectedItem(item);
    }

    if (action === 'api-config') {
      setOpenApiConfigModal((openApiConfigModal) => !openApiConfigModal);
      setSelectedItem(item);
    }
  };

  return (
    <div className="">
      <div className="pb-4">
        <h1>{t('stores.list.title')}</h1>
        <Breadcrumb
          items={[{ label: t('common.adminBreadcrumbLabel') }, { label: t('stores.list.breadcrumbLabel') }]}
        />
      </div>
      <div className="flex w-full flex-col space-y-2 md:flex-row md:items-center md:justify-between">
        <h1 className="text-2xl">{pageTitle}</h1>
        <div className="flex justify-items-center gap-x-2 md:items-center md:justify-between">
          <SearchStore handleSearch={handleSearch} />
          {filter?.pending === true && (
            <Link to="/admin/stores">
              <button className="ml-4 rounded-lg bg-gray-50 px-5 py-2">List Stores</button>
            </Link>
          )}
          {filter?.pending !== true && (
            <>
              <Link to="/admin/stores/pending-requests">
                <button className="ml-4 rounded-lg bg-gray-50 px-5 py-2">Pending Stores</button>
              </Link>
              <button onClick={toggleAddModal} className="ml-4 rounded-lg bg-greenColor px-5 py-2 text-white">
                {t('stores.add.title')}
              </button>
            </>
          )}
        </div>
      </div>

      {selectedItem != null && (
        <AddOrEditApiConfig
          ownerType={OwnerType.STORE}
          ownerEntity={selectedItem}
          isOpen={openApiConfigModal}
          toggleModal={toggleApiConfigModal}
        />
      )}

      <AddStore
        isOpen={openAddModal}
        toggleModal={toggleAddModal}
        parentContext={parentContext}
        agencyInContext={agency}
        merchantInContext={merchant}
      />

      <EditStore isOpen={openEditModal} toggleEditModal={toggleEditModal} item={selectedItem} />

      <DeleteStore isOpen={openDeleteModal} toggleDeleteModal={toggleDeleteModal} item={selectedItem} />
      <ApproveStore isOpen={openApproveModal} toggleApproveModal={toggleApproveModal} item={selectedItem} />
      {merchantStores?.data == null ||
        (merchantStores.data.length === 0 && <SuccessMessage message={t('stores.list.empty')} type="error" />)}
      {merchantStores?.data != null && merchantStores.data.length > 0 && (
        <>
          <ListStores items={merchantStores.data} handleToggleActionModal={handleToggleActionModal} />
          <Pagination pageData={merchantStores.meta} handlePageChange={setPage} />
          <Tooltip id="store-list" />
        </>
      )}
    </div>
  );
};

export default ListStoresPage;
