import { AiOutlineCheck } from 'react-icons/ai';
import { FaTimes } from 'react-icons/fa';

import { UserRoleLabel } from '../../../common/constants';
import { type User } from '../../../common/types';
import UserActionsList from './UserActionList';

interface IProps {
  item: User;
  handleToggleActionModal: (action: string, item: User) => any;
}

const ListUserItem: React.FC<IProps> = ({ item, handleToggleActionModal }) => {
  return (
    <tr className=" text-sm text-gray-700 odd:bg-white even:bg-gray-50 hover:bg-gray-300" key={item.id}>
      <td className="whitespace-nowrap p-2 pl-8">
        <div className="flex flex-col">
          <span>{item.fullname}</span>
          <span className="text-xs text-gray-400">{item.email.toLowerCase()}</span>
        </div>
      </td>
      <td className="whitespace-nowrap p-2">
        {item?.role != null ? UserRoleLabel[item.role] : ''}
      </td>
      <td className="p-2">
        {item.isRootUser && <AiOutlineCheck size={24} color="green" />}
        {!item.isRootUser && <FaTimes size={24} color="red" />}
      </td>
      <td className="p-2">
        {item.isApproved && <AiOutlineCheck size={24} color="green" />}
        {!item.isApproved && <FaTimes size={24} color="red" />}
      </td>
      <td className="p-2">
        {item.isActive && <AiOutlineCheck size={24} color="green" />}
        {!item.isActive && <FaTimes size={24} color="red" />}
      </td>
      <td className="w-24 whitespace-nowrap p-2 pr-8">
        <div className="flex flex-row items-center justify-items-center space-x-2">
          <UserActionsList
            item={item} 
            handleToggleActionModal={handleToggleActionModal}
          />
        </div>
      </td>
    </tr>
  );
};

export default ListUserItem;
