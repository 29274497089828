import { useState } from 'react';

import { type UserInToken } from '../../common/types';
import Sidebar from './Sidebar';

interface IProps {
  authUser: UserInToken;
  children?: React.ReactNode;
}

const VerticalLayout: React.FC<IProps> = ({ authUser, children }) => {
  const [open, setOpen] = useState(true);

  const sideMenuCollapseHandler = (): void => {
    setOpen(!open);
  };

  return (
    <div className="flex">
      <div className={`${open ? 'w-72' : 'w-20'} relative h-screen bg-sidebarBlack  p-6 pt-8 duration-300`}>
        <Sidebar authUser={authUser} open={open} setOpen={sideMenuCollapseHandler} />
      </div>
      <div className="mx-auto h-screen w-full flex-1 bg-[#E6E9EB] p-6 pt-10">{children}</div>
    </div>
  );
};

export default VerticalLayout;
