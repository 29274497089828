import { type User } from '../../../common/types';
import ListUserItem from './ListUserItem';

interface IProps {
  items: User[];
  filter?: { pending: boolean };
  handleToggleActionModal: (action: string, item: User) => any;
}

const ListUsers: React.FC<IProps> = ({ items: users, filter, handleToggleActionModal }) => {
  return (
    <>
      <div className="my-2 w-full overflow-auto rounded-md p-2">
        <table className="w-full border-b-2 border-gray-200 p-4">
          <thead className="bg-gray-100">
            <tr>
              <th className="p-2 pl-8 text-left text-sm font-semibold tracking-wide">Fullname</th>
              <th className="p-2 text-left text-sm font-semibold">Role</th>
              <th className="p-2 text-left text-sm font-semibold">Main Admin</th>
              <th className="p-2 text-left text-sm font-semibold">Approved</th>
              <th className="p-2 text-left text-sm font-semibold">Status</th>
              <th className="p-2 pr-8 text-sm font-semibold">Actions</th>
            </tr>
          </thead>
          <tbody className="">
            {users.map((user) => {
              return <ListUserItem key={user.id} item={user} handleToggleActionModal={handleToggleActionModal} />;
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ListUsers;
