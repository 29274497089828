import { Link } from 'react-router-dom';

import footerLogo from '../../assets/footerLogo.webp';

const Navbar: React.FC = () => {
  return (
    <>
      <div className="hidden h-[14vh] w-full justify-between bg-sidebarBlack px-4 py-[2vh] text-white md:flex">
        <div className="flex justify-center justify-items-start">
          <Link to="">
            <img src={footerLogo} alt="Logo" className="inline h-[10vh] px-32" />
          </Link>
          <div className="flex flex-col justify-center space-y-2">
            <span>StickyFeet Loyalty</span>
            <span>Worlds Largest Platform</span>
          </div>
        </div>
        <div className="flex flex-col justify-center space-y-2">
          <button className="font-extrabold">Call Now: +61 2 8007 6440</button>
          <Link to="/register">
            <button className="rounded-xl border-2 border-solid border-white px-2 py-1 text-sm">
              Get Sticky - Book Your Demo
            </button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Navbar;
