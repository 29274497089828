import { skipToken } from '@reduxjs/toolkit/query';

import { OwnerType } from '../../../common/constants';
import { type PaginatedData } from '../../../common/types';
import { useGetAllReceiptRulesQuery,useGetReceiptRulesByOwnerQuery } from '../receipt-rule.api-slice';
import type { ReceiptRuleV2Dto } from '../types';

interface QueryInputs {
  page: number;
  scope?: 'all' | 'agency' | 'merchant' | 'store' | 'device' | undefined;
  filter?: { pending: boolean };
  agencyId?: string;
  merchantId?: string;
  merchantStoreId?: string;
  deviceId?: string;
}

const useCustomReceiptRuleQuery = ({
  page,
  scope,
  agencyId,
  merchantId,
  merchantStoreId,
  deviceId
}: QueryInputs): { rules: PaginatedData<ReceiptRuleV2Dto> | undefined | null } => {
  const { data: rules } =
    scope === 'device'
    ? useGetReceiptRulesByOwnerQuery(
        deviceId != null ? { ownerEntityId: deviceId, ownerEntityType: OwnerType.DEVICE, page } : skipToken
      )
    : scope === 'store'
    ? useGetReceiptRulesByOwnerQuery(
        merchantStoreId != null
          ? { ownerEntityId: merchantStoreId, ownerEntityType: OwnerType.STORE, page }
          : skipToken
      )
    : scope === 'merchant'
    ? useGetReceiptRulesByOwnerQuery(
        merchantId != null ? { ownerEntityId: merchantId, ownerEntityType: OwnerType.MERCHANT, page } : skipToken
      )
    : scope === 'agency'
    ? useGetReceiptRulesByOwnerQuery(
        agencyId != null ? { ownerEntityId: agencyId, ownerEntityType: OwnerType.AGENCY, page } : skipToken
      )
    : useGetAllReceiptRulesQuery({ page });
  return { rules };
};

export default useCustomReceiptRuleQuery;
