import { FaTimes } from 'react-icons/fa';

interface IProps {
  errorBag: string[];
  closeError?: () => any;
}

const RenderErrorBag: React.FC<IProps> = ({ errorBag, closeError }) => {
  return (
    <div
      className={`my-4 flex w-full items-center justify-between rounded-md bg-red-500 px-4 py-2 text-left text-sm text-white`}
    >
      <div>
        {errorBag.map((errorInstance, i) => (
          <span className="flex w-full flex-col py-1" key={i}>
            {errorInstance.charAt(0).toUpperCase() + errorInstance.slice(1)}
          </span>
        ))}
      </div>
      {closeError != null && (
        <div>
          <FaTimes color="white" onClick={() => closeError()} />
        </div>
      )}
    </div>
  );
};

export default RenderErrorBag;
