import { skipToken } from '@reduxjs/toolkit/query';

import type { MerchantStore, PaginatedData } from '../../../common/types';
import { useGetStoresForAgencyQuery, useGetStoresForMerchantQuery, useGetStoresQuery } from '../store.api-slice';

interface MerchantQueryInputs {
  page: number;
  term: string;
  scope?: 'all' | 'agency' | 'merchant' | undefined;
  filter?: { pending: boolean };
  agencyId?: string | undefined;
  merchantId?: string | undefined;
}

const useCustomMerchantStoreQuery = ({
  page,
  term,
  scope,
  filter,
  agencyId,
  merchantId
}: MerchantQueryInputs): { isLoading: boolean; merchantStores: PaginatedData<MerchantStore> | undefined } => {
  let merchantStores;
  let isLoading;

  if (scope === 'agency') {
    const agencyQuery = agencyId != null ? { page, term, id: agencyId } : skipToken;
    const agencyResult = useGetStoresForAgencyQuery(agencyQuery);
    merchantStores = agencyResult.data;
    isLoading = agencyResult.isLoading;
  } else if (scope === 'merchant') {
    const merchantQuery = merchantId != null ? { page, term, id: merchantId } : skipToken;
    const merchantResult = useGetStoresForMerchantQuery(merchantQuery);
    merchantStores = merchantResult.data;
    isLoading = merchantResult.isLoading;
  } else {
    const storesQuery = { page, term, pendingOnly: Boolean(filter?.pending) };
    const storesResult = useGetStoresQuery(storesQuery);
    merchantStores = storesResult.data;
    isLoading = storesResult.isLoading;
  }

  return { merchantStores, isLoading };
};

export default useCustomMerchantStoreQuery;
