import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { useAuth } from './app/hooks/useAuth';

const HomePage: React.FC = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (auth.user != null) {
      navigate('/admin');
    }
  }, [auth, navigate]);

  return (
    <div className="flex h-[70vh] flex-col items-center justify-center bg-[#E6E9EB] p-2 text-center">
      <h1 className="mb-8">
        Engaging <span className="text-primary">Loyalty</span> that Sticks.
      </h1>
      <hr />
      <p>
        When you’re thinking of creating a new exciting loyalty program, we’re the technology specialists you need to
        start, grow and win.
      </p>
      <Link data-cy="register-page-btn" to="/register">
        <button className="mt-4 w-48 rounded-3xl bg-greenColor px-5 py-2 text-white">Let&apos;s Connect</button>
      </Link>
    </div>
  );
};

export default HomePage;
