import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';

import type { Agency, Device, Merchant, MerchantStore } from '../../../common/types';
import Breadcrumb from '../../../components/Breadcrumb';
import Pagination from '../../../components/Pagination';
import SuccessMessage from '../../../components/success-message';
import DeleteItem from '../components/DeleteReceiptRule';
import ListReceiptRule from '../components/ListReceiptRule';
import useCustomReceiptRuleQuery from '../hooks/custom-receipt-rule-hook';
import type { ReceiptRuleV2Dto } from '../types';

interface IProps {
  filter: 'all' | 'agency' | 'merchant' | 'store' | 'device';
  agency?: Agency;
  merchant?: Merchant;
  merchantStore?: MerchantStore;
  device?: Device;
}

const getSampleReceiptHeaderAndLink = ({ filter, merchant, merchantStore, device }: IProps): string => {
  const commonHeader = 'List of Rules';
  return filter === 'device' && device != null
    ? `${commonHeader} of ${device.name}`
    : filter === 'store' && merchantStore != null
    ? `${commonHeader} of ${merchantStore.name}`
    : filter === 'merchant' && merchant != null
    ? `${commonHeader} of ${merchant.name}`
    : commonHeader;
};

const ListReceiptRulesPage: React.FC<IProps> = ({ filter, agency, merchant, merchantStore, device }) => {
  const { t } = useTranslation();
  const [page, setPage] = useState<number>(1);
  const [selectedItem, setSelectedItem] = useState<ReceiptRuleV2Dto | null>(null);
  const { rules: records } = useCustomReceiptRuleQuery({
    page,
    scope: filter,
    agencyId: agency?.id,
    merchantId: merchant?.id,
    merchantStoreId: merchantStore?.id,
    deviceId: device?.id
  });
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

  const toggleDeleteModal = (): void => {
    if (openDeleteModal) {
      setSelectedItem(null);
    }
    setOpenDeleteModal(!openDeleteModal);
  };

  const handleToggleActionModal = (action: string, item: ReceiptRuleV2Dto): void => {
    if (action === 'delete') {
      setOpenDeleteModal((openDeleteModal) => !openDeleteModal);
      setSelectedItem(item);
    }
  };

  const header = getSampleReceiptHeaderAndLink({
    filter,
    merchant,
    merchantStore,
    device
  });

  return (
    <div>
      <div className="pb-4">
        <h1>{t('receiptRule.list.title')}</h1>
        <Breadcrumb
          items={[{ label: t('common.adminBreadcrumbLabel') }, { label: t('receiptRule.list.breadcrumbLabel') }]}
        />
      </div>
      <div className="flex w-full items-center justify-between px-2">
        <h1>{header}</h1>
      </div>

      {records?.data == null ||
        (records.data.length === 0 && <SuccessMessage message={t('receipts.list.empty')} type="error" />)}

      {records?.data != null && records.data.length > 0 && (
        <>
          <DeleteItem isOpen={openDeleteModal} toggleDeleteModal={toggleDeleteModal} item={selectedItem} />
        </>
      )}

      {records?.data != null && records.data.length > 0 && (
        <>
          <ListReceiptRule items={records.data} handleToggleActionModal={handleToggleActionModal} />
          <Pagination pageData={records.meta} handlePageChange={setPage} />
          <Tooltip id="rule-list" />
        </>
      )}
    </div>
  );
};

export default ListReceiptRulesPage;
