import * as yup from 'yup';

export const AgencyRegisterSchema = yup.object().shape({
  name: yup.string().required('Agency name is required'),
  email: yup.string().email('Please enter a valid email').required('Email is required'),
  phone: yup.string().required('Mobile number is required'),
  address: yup.string().required('Address is required'),
  contactPersonFullname: yup.string().required('Contact person name is required'),
  password: yup.string().min(6, 'Password must be at least 6 letter long').required('Password is required'),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Password must match')
    .required('Password confirmation is required')
});

export const MerchantRegisterSchema = yup.object().shape({
  name: yup.string().required('Merchant name is required'),
  email: yup.string().email('Please enter a valid email').required('Email is required'),
  phone: yup.string().required('Mobile number is required'),
  address: yup.string().required('Address is required'),
  contactPersonFullname: yup.string().required('Contact person name is required'),
  password: yup.string().min(6, 'Password must be at least 6 letter long').required('Password is required'),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Password must match')
    .required('Password confirmation is required')
});

export const UserLoginSchema = yup.object().shape({
  email: yup.string().email('Please enter a valid email').required('Email is required'),
  password: yup.string().min(4, 'Password must be at least 4 letter long').required('Password is required')
});
