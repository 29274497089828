import { useTranslation } from 'react-i18next';
import { AiTwotoneCopy } from 'react-icons/ai';
import { FaBox, FaBuilding, FaCheckCircle, FaReceipt, FaTimes, FaUsers } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { getStatusLabelClassnames, getStatusText } from '../../../common/helpers';
import { type MerchantStore } from '../../../common/types';
import StoreActions from './StoreActions';

interface IProps {
  item: MerchantStore;
  pageType?: 'pending' | 'approved';
  handleToggleActionModal: (action: string, item: MerchantStore) => any;
}

const ListStoreItem: React.FC<IProps> = ({
  item,
  handleToggleActionModal,
  pageType = 'approved'
}) => {
  const { t } = useTranslation();
  return (
    <tr className="text-sm text-gray-700 odd:bg-white even:bg-gray-50 hover:bg-gray-300" key={item.id}>
      <td className="whitespace-nowrap p-2 pl-8">
        <div className="flex flex-col">
          <Link to={`/admin/stores/${item.id}`} className="flex space-x-2 hover:text-primary">
            <span>{item.name}</span>
            {item.isDefault ? <FaBuilding color="green" size={18} /> : ''}
          </Link>
          <div className="flex items-center space-x-1">
            <span className="text-xs text-gray-400">{item.email.toLowerCase()}</span>
            {item.isApproved ? (
              <FaCheckCircle
                size={10}
                color="green"
                data-tooltip-id="agency-list"
                data-tooltip-content={t('agencies.list.isApprovedTrue')}
              />
            ) : (
              <FaTimes
                size={10}
                color="red"
                data-tooltip-id="agency-list"
                data-tooltip-content={t('agencies.list.isApprovedFalse')}
              />
            )}{' '}
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap p-2">{item.address}</td>
      <td className="w-24 whitespace-nowrap p-2">{item.phone}</td>
      <td className="w-24 whitespace-nowrap p-2">
        <span
          className={`rounded px-2.5 py-0.5 text-xs font-medium text-gray-700 ${getStatusLabelClassnames(
            item.isActive
          )}`}>
          {getStatusText(item.isActive)}
        </span>{' '}
      </td>
      <td className="w-24 whitespace-nowrap p-2 pr-8 text-left">
        <div className="flex flex-row items-center space-x-3">
          <Link to={`/admin/stores/${item.id}/devices`}>
            <span
              className="h-full px-1"
              data-tooltip-id="store-list"
              data-tooltip-content={t('stores.list.deviceListBtnLabel')}>
              <FaBox size={18} />
            </span>
          </Link>
          <Link to={`/admin/stores/${item.id}/users`}>
            <span
              className="h-full px-1"
              data-tooltip-id="store-list"
              data-tooltip-content={t('stores.list.userListBtnLabel')}>
              <FaUsers color="green" size={18} />
            </span>
          </Link>
          <Link to={`/admin/stores/${item.id}/receipts`}>
            <span
              className="h-full px-1"
              data-tooltip-id="store-list"
              data-tooltip-content={t('stores.list.receiptListBtnLabel')}>
              <FaReceipt color="green" size={18} />
            </span>
          </Link>
          <Link
            to={`/admin/stores/${item.id}/sample-receipts`}
            data-tooltip-id="store-list"
            data-tooltip-content={t('stores.list.sampleReceiptListBtnLabel')}>
            <span className="h-full px-1">
              <AiTwotoneCopy size={18} />
            </span>
          </Link>
          <StoreActions
            item={item}
            handleToggleActionModal={handleToggleActionModal}
          />
        </div>
      </td>
    </tr>
  );
};

export default ListStoreItem;
