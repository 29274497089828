import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useParams } from 'react-router-dom';

import { type OwnerEntityType, OwnerType } from '../../../common/constants';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { useGetAgencyByIdQuery } from '../../agencies/agency.api-slice';
import { useGetDeviceByIdQuery } from '../../devices/device.api-slice';
import { useGetMerchantByIdQuery } from '../../merchants/merchant.api-slice';
import { useGetStoreByIdQuery } from '../../stores/store.api-slice';
import ConfigurationBuilder from '../components/ConfigurationBuilder';
import { useGetConfigurationByOwnerQuery } from '../configuration.api-slice';

const AddOrEditConfiguration: React.FC = () => {
  const { ownerEntityType, ownerEntityId } = useParams();

  let ownerEntity;

  if (ownerEntityType != null && OwnerType.AGENCY === ownerEntityType) {
    const { data } = useGetAgencyByIdQuery(ownerEntityId != null ? ownerEntityId : skipToken);
    ownerEntity = data;
  } else if (ownerEntityType != null && OwnerType.MERCHANT === ownerEntityType) {
    const { data } = useGetMerchantByIdQuery(ownerEntityId != null ? ownerEntityId : skipToken);
    ownerEntity = data;
  } else if (ownerEntityType != null && OwnerType.STORE === ownerEntityType) {
    const { data } = useGetStoreByIdQuery(ownerEntityId != null ? ownerEntityId : skipToken);
    ownerEntity = data;
  } else if (ownerEntityType != null && OwnerType.DEVICE === ownerEntityType) {
    const { data } = useGetDeviceByIdQuery(ownerEntityId != null ? ownerEntityId : skipToken);
    ownerEntity = data;
  } else {
    throw Error('Invalid data');
  }

  const { data: configuration, isLoading: loadingResourceById } = useGetConfigurationByOwnerQuery(
    ownerEntityId != null && ownerEntityType != null ? { ownerEntityType, ownerEntityId } : skipToken
  );

  if (ownerEntity == null || ownerEntityType == null || ownerEntityId == null || loadingResourceById) {
    return <LoadingSpinner />;
  }

  return (
    <>
      {configuration == null && (
        <ConfigurationBuilder
          configuration={{}}
          ownerEntityType={ownerEntityType as unknown as OwnerEntityType}
          ownerEntity={ownerEntity}
        />
      )}

      {configuration != null && (
        <ConfigurationBuilder
          configuration={configuration.config}
          ownerEntityType={ownerEntityType as unknown as OwnerEntityType}
          ownerEntity={ownerEntity}
          id={configuration.id}
        />
      )}
    </>
  );
};

export default AddOrEditConfiguration;
