import CustomTextInput from '../../../components/Form/custom-text-input';

const AddApiConfigFormV2: React.FC = () => {
  return (
    <>
      <CustomTextInput
        type="text"
        label="User ID"
        name="userId"
        divClass="w-full flex flex-col "
        labelClass="w-full text-left text-gray-600"
        inputClass="w-full my-1 px-4 py-1 rounded-md"
      />
      <CustomTextInput
        type="text"
        label="User API Key"
        name="userApiKey"
        divClass="w-full flex flex-col "
        labelClass="w-full text-left text-gray-600"
        inputClass="w-full my-1 px-4 py-1 rounded-md"
      />
      <CustomTextInput
        type="text"
        label="User Password"
        name="userPassword"
        divClass="w-full flex flex-col "
        labelClass="w-full text-left text-gray-600"
        inputClass="w-full my-1 px-4 py-1 rounded-md"
      />
      <CustomTextInput
        type="text"
        label="Campaign ID"
        name="campaignId"
        divClass="w-full flex flex-col "
        labelClass="w-full text-left text-gray-600"
        inputClass="w-full my-1 px-4 py-1 rounded-md"
      />
      <CustomTextInput
        type="text"
        label="Account ID"
        name="accountId"
        divClass="w-full flex flex-col "
        labelClass="w-full text-left text-gray-600"
        inputClass="w-full my-1 px-4 py-1 rounded-md"
      />
    </>
  );
};

export default AddApiConfigFormV2;
