import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useParams } from 'react-router-dom';

import LoadingSpinner from '../../../components/LoadingSpinner';
import { useGetAgencyByIdQuery } from '../../agencies/agency.api-slice';
import ListMerchantsPage from './ListMerchantsPage';

interface IProps {
  pendingOnly?: boolean;
}

const ListMerchantsForAgencyPage: React.FC<IProps> = ({ pendingOnly }) => {
  const { id } = useParams();
  const { data, isLoading } = useGetAgencyByIdQuery(id ?? skipToken);

  if (isLoading || id == null) {
    return <LoadingSpinner />;
  }

  return <ListMerchantsPage scope="agency" agency={data} />;
};

export default ListMerchantsForAgencyPage;
