import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiFillNotification, AiOutlineEdit, AiOutlineMail, AiOutlinePhone } from 'react-icons/ai';
import { FaLocationArrow } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useAuth } from '../../../app/hooks/useAuth';
import { OwnerType, UserRole } from '../../../common/constants';
import Breadcrumb from '../../../components/Breadcrumb';
import LoadingSpinner from '../../../components/LoadingSpinner';
import ReceiptStat from "../../dashboard/components/ReceiptStat";
import TopDeviceDashboard from "../../dashboard/components/TopDeviceStat";
import { useGetMerchantDashboardQuery } from '../../dashboard/dashboard-api-slice';
import DataContainer from '../../stores/components/DefaultApiConfig/DataContainer';
import RuleDataContainer from '../../stores/components/DefaultRule/RuleDataContainer';
import EditMerchant from '../components/EditMerchant';
import MerchantDetailStatus from '../components/MerchantDetailStatus';
import NotifyMerchant from '../components/NotifyMerchant';
import { useApproveMerchantMutation, useGetMerchantByIdQuery } from '../merchant.api-slice';

const MerchantDashboardPage: React.FC = () => {
  const { id } = useParams();
  const auth = useAuth();
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [openNotifyModal, setOpenNotifyModal] = useState<boolean>(false);
  const { data: merchant, isLoading, isSuccess } = useGetMerchantByIdQuery(id ?? skipToken);
  const { data: merchantStats, isLoading: isStatsLoading } = useGetMerchantDashboardQuery(id ?? skipToken);
  const [approveMerchant, { isSuccess: isApproveSuccess }] = useApproveMerchantMutation();
  const { t } = useTranslation();

  const handleApproveStoreClick = async (): Promise<void> => {
    if (id != null) {
      await approveMerchant(id);
    }
  };

  const toggleEditModal = (): void => {
    setOpenEditModal(!openEditModal);
  };

  const toggleNotifyModal = (): void => {
    setOpenNotifyModal(!openNotifyModal);
  };

  useEffect(() => {
    if (isApproveSuccess) {
      toast('Merchant is now approved.');
    }
  }, [isApproveSuccess]);

  if (isLoading || isStatsLoading || id == null) {
    return <LoadingSpinner />;
  }

  if (isSuccess && merchant === undefined) {
    toast('No such merchant exist');
    return <></>;
  }

  const stats = [
    {
      id: 'storeCount',
      title: 'Store',
      count: merchantStats?.countDashboard.data.storeCount != null ? merchantStats.countDashboard.data.storeCount : 0,
      link: `/admin/merchants/${id}/stores`
    },
    {
      id: 'userCount',
      title: 'User',
      count: merchantStats?.countDashboard.data.userCount != null ? merchantStats.countDashboard.data.userCount : 0,
      link: `/admin/merchants/${id}/users`
    },
    {
      id: 'deviceCount',
      title: 'Devices',
      count: merchantStats?.countDashboard.data.deviceCount != null ? merchantStats.countDashboard.data.deviceCount : 0,
      link: `/admin/merchants/${id}/devices`
    },
    {
      id: 'ruleCount',
      title: 'Receipt Rule',
      count: merchantStats?.countDashboard.data.receiptRuleCount != null ? merchantStats.countDashboard.data.receiptRuleCount : 0,
      link: ''
    }
  ];

  return (
    <>
      {merchant != null && <EditMerchant item={merchant} isOpen={openEditModal} toggleEditModal={toggleEditModal} />}
      {merchant != null && (
        <NotifyMerchant item={merchant} isOpen={openNotifyModal} toggleNotifyModal={toggleNotifyModal} />
      )}
      {merchant != null && (
        <div>
          <div className="pb-4">
            <h1>Dashboard</h1>
            <Breadcrumb
              items={[
                { label: t('common.adminBreadcrumbLabel') },
                { label: t('merchants.list.breadcrumbLabel') },
                { label: t('merchants.dashboard.breadcrumbLabel') }
              ]}
            />
          </div>
          <div className="flex w-full">
            <div className="flex w-1/2 flex-col rounded-lg bg-gray-50 p-4">
              <div className="flex w-full items-center justify-between border-b-2 pb-4">
                <span className="text-center text-xl font-bold">{merchant.name}</span>
                <div className="flex space-x-2">
                  <div
                    className="flex rounded-lg bg-red-500 px-4 py-2 text-center text-gray-50"
                    onClick={toggleNotifyModal}
                  >
                    <AiFillNotification size={24} />
                    <button className="text-sm">Notify</button>
                  </div>
                  <div
                    className="flex rounded-lg bg-greenColor px-4 py-2 text-center text-gray-50"
                    onClick={toggleEditModal}
                  >
                    <AiOutlineEdit size={24} />
                    <button className="text-sm">Edit</button>
                  </div>
                </div>
              </div>

              {/* Detail of Store */}
              <div className="flex w-full">
                <div className="w-1/2">
                  <div className="flex justify-between px-4 py-2">
                    <div className="flex items-center justify-between space-x-4">
                      <FaLocationArrow size={16} />
                      <span className="">{merchant.address}</span>
                    </div>
                  </div>
                  <div className="flex justify-between px-4 py-2">
                    <div className="flex items-center justify-between space-x-4">
                      <AiOutlineMail size={16} />
                      <span className="">{merchant.email}</span>
                    </div>
                  </div>
                  <div className="flex justify-between px-4 py-2">
                    <div className="flex items-center justify-between space-x-4">
                      <AiOutlinePhone size={16} />
                      <span className="">{merchant.phone}</span>
                    </div>
                  </div>
                </div>
                {/* Status */}
                <div className="w-1/2 pl-16">
                  <div className="flex justify-between px-4 py-2">
                    <div className="flex items-center justify-between space-x-4">
                      <MerchantDetailStatus status={merchant.isApproved} title="Approved" />
                      {auth.user != null && auth.user.role === UserRole.SUPER_ADMIN && !merchant.isApproved && (
                        <button
                          onClick={() => handleApproveStoreClick}
                          className="rounded-md bg-greenColor px-2 py-1 text-center text-sm text-gray-50"
                        >
                          Approve
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="flex justify-between px-4 py-2">
                    <div className="flex items-center justify-between space-x-4">
                      <MerchantDetailStatus status={merchant.isActive} title="Status" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Stat Cards */}
            <div className="flex w-1/2 flex-col">
              <div className="ml-2 flex w-full flex-col justify-between space-x-0 space-y-2  md:flex-row md:space-x-2 md:space-y-0">
                {stats.slice(0, 2).map((stat, i) => {
                  return (
                    <div
                      key={i}
                      className={`flex h-24 flex-col justify-between rounded-md bg-gray-100 px-4 py-2 sm:w-full md:w-1/2`}
                    >
                      <span className="px-2 text-center text-4xl">{stat.count}</span>
                      <Link to={stat.link} className="text-center hover:text-greenColor">
                        <span>{stat.title}</span>
                      </Link>
                    </div>
                  );
                })}
              </div>
              <div className="m-2 flex w-full flex-col justify-between space-x-0 space-y-2  md:flex-row md:space-x-2 md:space-y-0">
                {stats.slice(2, 4).map((stat) => {
                  return (
                    <div
                      key={stat.id}
                      className={`flex h-24 flex-col justify-between rounded-md bg-gray-100 px-4 py-2 sm:w-full md:w-1/2`}
                    >
                      <span className="px-2 text-center text-4xl">{stat.count}</span>
                      <Link to={stat.link} className="text-center hover:text-greenColor">
                        <span>{stat.title}</span>
                      </Link>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="mt-4 flex flex-col md:flex-row">
            <div className="flex w-full flex-col md:w-1/2">
              <div className="flex w-full justify-between px-4">
                <span className="text-lg">{merchantStats?.lastWeekReceiptsStats?.name}</span>
              </div>
              {((merchantStats?.lastWeekReceiptsStats?.data) != null) && <ReceiptStat statData={merchantStats.lastWeekReceiptsStats.data} />}
            </div>
            <div className="flex w-full flex-col md:w-1/2">
              <div className="flex w-full justify-between px-4">
                <span className="text-lg">{merchantStats?.topDeviceStats.name}</span>
              </div>
              {((merchantStats?.topDeviceStats?.data) != null) && <TopDeviceDashboard topDeviceData={merchantStats?.topDeviceStats.data} />}
            </div>
          </div>
          <div className="flex w-full space-x-2">
            <div className="mt-2 flex w-1/2 flex-col rounded-lg bg-gray-50 p-4">
              <RuleDataContainer ownerEntityType={OwnerType.MERCHANT} ownerEntityId={merchant.id} />
            </div>
            <div className="mt-2 flex w-1/2 flex-col rounded-lg bg-gray-50 p-4">
              <DataContainer ownerType={OwnerType.MERCHANT} ownerEntity={merchant} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MerchantDashboardPage;
