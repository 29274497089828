import CustomTextAreaInput from '../../../components/Form/custom-text-area-input';
import CustomTextInput from '../../../components/Form/custom-text-input';

const SampleReceiptForm: React.FC = () => {
  return (
    <>
      <CustomTextInput
        type="text"
        label="Title"
        name="title"
        divClass="w-full flex flex-col "
        labelClass="w-full text-left text-gray-600"
        inputClass="w-full my-1 px-4 py-1 rounded-md"
      />
      <CustomTextAreaInput
        type="text"
        label="Receipt"
        name="receipt"
        divClass="w-full flex flex-col "
        labelClass="w-full text-left text-gray-600"
        inputClass="w-full my-1 px-4 py-1 rounded-md"
      />
    </>
  );
};

export default SampleReceiptForm;
