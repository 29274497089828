import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useParams } from 'react-router-dom';

import LoadingSpinner from '../../../components/LoadingSpinner';
import { useGetAgencyByIdQuery } from '../../agencies/agency.api-slice';
import ListUsersPage from './ListUsersPage';

interface IProps {
  pendingOnly?: boolean;
}

const ListUsersForAgencyPage: React.FC<IProps> = ({ pendingOnly }) => {
  const { id } = useParams();
  const { data, isLoading } = useGetAgencyByIdQuery(id ?? skipToken);

  if (isLoading || data === undefined) {
    return <LoadingSpinner />;
  }

  return <ListUsersPage scope={'agency'} agency={data} />;
};

export default ListUsersForAgencyPage;
