import { AiOutlineCheck } from 'react-icons/ai';
import { FaTimes } from 'react-icons/fa';

interface IProps {
  label: string;
  value: string | boolean;
  type: 'boolean' | 'text';
}

const MyProfileReadonlyInfo: React.FC<IProps> = ({ label, type, value }) => {
  return (
    <div className="flex">
      <span className="pr-2 font-bold">{label}</span>
      {type === 'text' && <span>{value}</span>}
      {type === 'boolean' && value === true && <AiOutlineCheck size={24} color="green" />}
      {type === 'boolean' && value === false && <FaTimes size={24} color="red" />}{' '}
    </div>
  );
};

export default MyProfileReadonlyInfo;
