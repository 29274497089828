import { skipToken } from '@reduxjs/toolkit/query';

import { type Merchant, type PaginatedData } from '../../../common/types';
import { useGetMerchantsForAgencyQuery, useGetMerchantsQuery } from '../merchant.api-slice';

interface MerchantQueryInputs {
  page: number;
  scope?: 'all' | 'agency' | undefined;
  filter?: { pending: boolean };
  agencyId?: string | undefined;
}

const useCustomMerchantsQuery = ({
  page,
  scope,
  filter,
  agencyId
}: MerchantQueryInputs): { isLoading: boolean; merchants: PaginatedData<Merchant> | undefined } => {
  const { data: merchants, isLoading } =
    scope === 'agency'
      ? useGetMerchantsForAgencyQuery(agencyId != null ? { page, id: agencyId } : skipToken)
      : useGetMerchantsQuery({ page, pendingOnly: filter?.pending });

  return { isLoading, merchants };
};

export default useCustomMerchantsQuery;
