import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectCurrentUser } from '../../auth/auth.slice';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useAuth = () => {
  const user = useSelector(selectCurrentUser);

  return useMemo(() => ({ user }), [user]);
};
