import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { useAuth } from '../app/hooks/useAuth';
import { UserRole } from '../common/constants';

export const AgencyAdminOutlet: React.FC = () => {
  const auth = useAuth();
  const location = useLocation();

  return auth.user != null && (auth.user.role === UserRole.SUPER_ADMIN || auth.user.role === UserRole.AGENCY_ADMIN) ? (
    <Outlet />
  ) : (
    <Navigate to="/admin" state={{ from: location }} />
  );
};
