import {type ReceiptStatData} from "../../../common/types";
import EmptyState from "../../../components/EmptyView";

interface IProps {
    statData: ReceiptStatData[] | null;
}

const ReceiptStat: React.FC<IProps> = ({ statData }) => {
    if (statData == null || statData.length === 0) {
        return <EmptyState message="No Data" />;;
    }
    return (
        <div className="my-2 flex w-full flex-col space-y-2 overflow-auto border-b-gray-50 p-2">
            <div className="flex w-full items-center justify-between justify-items-center rounded-md bg-gray-50 p-4 text-lg font-bold">
                <span>Date</span>
                <span>Success</span>
                <span>Error</span>
                <span>Skipped</span>
            </div>
            {statData.map((stat) => {
                return (
                    <div
                        key={`receipt-stat-${stat.day}`}
                        className="flex w-full items-center justify-between justify-items-center rounded-md bg-gray-50 p-4"
                    >
                        <span>{stat.day}</span>
                        <span>{stat.stats.success}</span>
                        <span>{stat.stats.error}</span>
                        <span>{stat.stats.skipped}</span>
                    </div>
                );
            })}
        </div>
    );
};

export default ReceiptStat;
