import { t } from 'i18next';
import { AiTwotoneCopy } from 'react-icons/ai';
import { FaClipboardList, FaReceipt } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import type { Device, ItemActionProps } from '../../../common/types';
import DeviceActionsList from './DeviceActionsList';


const ListDeviceItem: React.FC<ItemActionProps<Device>> = ({ item, handleToggleActionModal }) => {
  return (
    <tr className="text-sm text-gray-700 odd:bg-white even:bg-gray-50 hover:bg-gray-300" key={item.id}>
      <td className="whitespace-nowrap p-2 pl-8">
        <div className="flex flex-col ">
          <span>{item.name}</span>
          <span className="text-xs text-gray-400">{item.email.toLowerCase()}</span>
        </div>
      </td>
      <td className="items-center p-2">{item.description}</td>
      <td className="w-24 whitespace-nowrap p-2 pr-8 text-left">
        <div className="flex w-full items-center space-x-3">
          <Link to={`/admin/devices/${item.id}/receipts`}>
            <span className="h-full px-1" data-tooltip-id='store-list' data-tooltip-content={t('stores.list.receiptListBtnLabel')}>
              <FaReceipt color="green" size={18} />
            </span>
          </Link>
          <Link to={`/admin/devices/${item.id}/sample-receipts`} data-tooltip-id='store-list' data-tooltip-content={t('stores.list.sampleReceiptListBtnLabel')}>
            <span className="h-full px-1">
              <AiTwotoneCopy size={18} />
            </span>
          </Link>
          <Link to={`/admin/rules/device/${item.id}`}>
            <span className="h-full px-1" data-tooltip-id='store-list' data-tooltip-content={t('stores.list.receiptListBtnLabel')}>
              <FaClipboardList size={18} />
            </span>
          </Link>
          <DeviceActionsList
            item={item} 
            handleToggleActionModal={handleToggleActionModal}
          />
        </div>
      </td>
    </tr>
  );
};

export default ListDeviceItem;
