import { type ApiConfig } from '../../../../common/types';

interface IProps {
  apiConfig: ApiConfig;
}

const ViewContainer: React.FC<IProps> = ({ apiConfig }) => {
  return (
    <div className="flex flex-col justify-items-center">
      <div className="flex justify-between border-b-2 p-2">
        <span>User ID</span>
        <span>{apiConfig.userId}</span>
      </div>

      <div className="flex justify-between border-b-2 p-2">
        <span>User API Key</span>
        <span>{apiConfig.userApiKey}</span>
      </div>
      <div className="flex justify-between border-b-2 p-2">
        <span>User Password</span>
        <span>{apiConfig.userPassword}</span>
      </div>
      <div className="flex justify-between border-b-2 p-2">
        <span>Campaign ID</span>
        <span>{apiConfig.campaignId}</span>
      </div>
      <div className="flex justify-between border-b-2 p-2">
        <span>Account ID</span>
        <span>{apiConfig.accountId}</span>
      </div>
    </div>
  );
};

export default ViewContainer;
