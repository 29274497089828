import { type ApiConfig } from '../../../common/types';
import ListApiConfigItem from './ListApiConfigItem';

interface IProps {
  items: ApiConfig[];
  handleToggleActionModal: (action: string, item: ApiConfig) => any;
}

const ListApiConfigs: React.FC<IProps> = ({ items: apiConfigs, handleToggleActionModal }) => {
  return (
    <>
      <div className="my-2 w-full overflow-auto rounded-md p-2">
        <table className="w-full border-b-2 border-gray-200 p-4">
          <thead className="bg-gray-100">
            <tr className="text-sm font-semibold">
              <th className="p-2 pl-8 text-left">ID</th>
              <th className="p-2 text-left">Owner</th>
              <th className="p-2 pr-8">Actions</th>
            </tr>
          </thead>
          <tbody className="">
            {apiConfigs?.map((apiConfig) => {
              return (
                <ListApiConfigItem
                  key={apiConfig.id}
                  item={apiConfig}
                  handleToggleActionModal={handleToggleActionModal}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ListApiConfigs;
