import {type ParsedReceipt} from "../../../common/types";

const ParsedReceiptSummary: React.FC<{ result: ParsedReceipt }> = ({ result }) => {
  return (
    <div className="flex grow flex-col justify-between">
      <p>
        {result.parsedItems.globalIdentifiers?.map((identifier, index) => (
          <div key={index}>
            <span className="font-bold">{identifier.key}</span> {identifier.value}
          </div>
        ))}
      </p>
      <p>Line Items</p>
      <table>
        <thead>
          <tr>
            <th className="text-left">Name</th>
            <th className="text-left">Quantity</th>
            <th className="text-left">Unit Price</th>
            <th className="text-left">Total Price</th>
          </tr>
        </thead>
        <tbody>
          {result.parsedItems.lineItems?.map((lineItem, index) => {
            return (
              <tr key={index} className="border-b-2 border-gray-200">
                <td>{lineItem.name}</td>
                <td>{lineItem.quantity}</td>
                <td>{lineItem.unitPrice}</td>
                <td>{lineItem.totalPrice}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ParsedReceiptSummary;
