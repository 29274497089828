import * as yup from 'yup';

import { SeparatorType } from '../../common/constants';

const lineItemComponentSchema = yup.object().shape({
  title: yup.string().required(),
  description: yup.string().nullable(),
  present: yup.boolean().default(true),
  order: yup.number().default(1),
  isModifier: yup.boolean().default(false),
  prefixSymbol: yup.string().nullable(),
  suffixSymbol: yup.string().nullable(),
  separator: yup.string().oneOf(Object.values(SeparatorType)).required(),
  suffixSpecialChar: yup.boolean().required().default(false)
});

const singleSection = yup.object().shape({
  title: yup.string().required(),
  startIndicator: yup.array().of(yup.string()),
  endIndicator: yup.array().of(yup.string())
});

export const RuleSchema = yup.object().shape({
  leadingReferenceQualifier: yup.array().of(yup.string()),
  leadingReferenceDisqualifier: yup.array().of(yup.string()),
  lineItemStartIndicator: yup.array().of(yup.string()),
  lineItemEndIndicator: yup.array().of(yup.string()),
  additionalFieldToParse: yup.array().of(yup.string()),
  lineItemRule: yup.object().shape({
    quantityIdentifier: lineItemComponentSchema,
    unitPriceIdentifier: lineItemComponentSchema,
    nameIdentifier: lineItemComponentSchema,
    priceIdentifier: lineItemComponentSchema
  }),
  sections: yup.object().shape({
    header: yup.array().of(singleSection),
    body: yup.array().of(singleSection),
    footer: yup.array().of(singleSection)
  })
});
