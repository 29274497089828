import { type Agency } from '../../../common/types';
import ListAgencyItem from './ListAgencyItem';

interface IProps {
  items: Agency[];
  filter?: { pending: boolean };
  handleToggleActionModal: (action: string, item: Agency) => void;
}

const ListAgencies: React.FC<IProps> = ({ items, handleToggleActionModal }) => {
  return (
    <>
      <div className="my-2 w-full overflow-auto rounded-md p-2">
        <table className="w-full border-b-2 border-gray-200">
          <thead className="bg-gray-100">
            <tr>
              <th className="p-2 pl-8 text-left text-sm font-semibold tracking-wide">Full Name</th>
              <th className="p-2 text-left text-sm font-semibold tracking-wide">Identifier</th>
              <th className="p-2 text-left text-sm font-semibold tracking-wide">Address</th>
              <th className="p-2 text-left text-sm font-semibold tracking-wide">Phone</th>
              <th className="p-2 text-left text-sm font-semibold tracking-wide">Status</th>
              <th className="w-12 p-2 pr-8 text-sm font-semibold tracking-wide">Actions</th>
            </tr>
          </thead>
          <tbody className="">
            {items.map((item) => {
              return <ListAgencyItem item={item} key={item.id} handleToggleActionModal={handleToggleActionModal} />;
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ListAgencies;
