import { apiSlice } from '../app/services/api-slice';
import { type ApiSuccessResponse, type UserLoginResponsePayload } from '../common/types';
import { type UserLogin } from './user-login.form';

const authApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    loginUser: builder.mutation<UserLoginResponsePayload, UserLogin>({
      query: (userLogin) => ({
        url: '/auth/login',
        method: 'POST',
        body: userLogin
      }),
      transformResponse: (responseData: ApiSuccessResponse) => {
        return responseData.data as UserLoginResponsePayload;
      }
    }),
    registerAgency: builder.mutation({
      query: (agency) => ({
        url: '/register-agency',
        method: 'POST',
        body: agency
      }),
      transformResponse: (responseData: ApiSuccessResponse) => {
        return responseData.data;
      }
    })
  }),
  overrideExisting: false
});

export const { useLoginUserMutation, useRegisterAgencyMutation } = authApi;
export default authApi;
