import { Form, Formik, type FormikHelpers } from 'formik';
import { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { OwnerType, UserRole } from '../../../common/constants';
import { type UserCreateRequest } from '../../../common/types';
import CustomTextInput from '../../../components/Form/custom-text-input';
import LoadingSpinner from '../../../components/LoadingSpinner';
import RenderErrorBag from '../../../components/render-error-bag';
import { useAddUserMutation } from '../user.api-slice';
import { UserAddSchema } from '../user.schema';

const AddStoreUserPage: React.FC = () => {
  const { id } = useParams();
  const [addUser, { isLoading }] = useAddUserMutation();
  const [errorBag] = useState<string[]>([]);
  const navigate = useNavigate();

  if (id == null) {
    return <LoadingSpinner />;
  }

  const handleAddUser = async (values: UserCreateRequest, actions: FormikHelpers<UserCreateRequest>): Promise<any> => {
    // TODO revisit this
    const userDto =
      id != null
        ? { ...values, storeId: id, ownerType: OwnerType.STORE, ownerId: id }
        : { ...values, ownerType: OwnerType.STORE, ownerId: id };
    await addUser({ ...userDto, role: UserRole.STORE_ADMIN });
    toast('User is successfully created');
    navigate(`/admin/stores/${id}/users`);
  };

  const initialValues = {
    fullname: 'Suresh LC for Store',
    email: 'store@example.com',
    password: '12345678',
    passwordConfirmation: '12345678'
  };

  return (
    <div className="mx-auto my-8 flex min-h-[70vh] max-w-2xl flex-col items-start rounded-md bg-gray-50 p-8">
      <div className="flex w-full items-center justify-center">
        <span className="h-full w-full text-2xl">Add User</span>
      </div>
      <div className="mt-4 flex w-full flex-col items-start px-2">
        {errorBag.length > 0 && <RenderErrorBag errorBag={errorBag} />}
        {
          <Formik initialValues={initialValues} onSubmit={handleAddUser} validationSchema={UserAddSchema}>
            {({ isSubmitting }) => (
              <Form className="flex w-full flex-col items-start px-2" autoComplete="off">
                <CustomTextInput
                  type="text"
                  label="Fullname"
                  name="fullname"
                  divClass="w-full flex flex-col "
                  labelClass="w-full text-left text-gray-600"
                  inputClass="w-full my-1 px-4 py-1 rounded-md"
                />
                <CustomTextInput
                  type="text"
                  label="Email"
                  name="email"
                  divClass="w-full flex flex-col "
                  labelClass="w-full text-left text-gray-600"
                  inputClass="w-full my-1 px-4 py-1 rounded-md"
                />
                <CustomTextInput
                  type="password"
                  label="Password"
                  name="password"
                  divClass="w-full flex flex-col "
                  labelClass="w-full text-left text-gray-600"
                  inputClass="w-full my-1 px-4 py-1 rounded-md"
                />
                <CustomTextInput
                  type="password"
                  label="Password Confirmation"
                  name="passwordConfirmation"
                  divClass="w-full flex flex-col"
                  labelClass="w-full text-left text-gray-600"
                  inputClass="w-full my-1 px-4 py-1 rounded-md"
                />
                <div className="mt-4 flex w-full space-x-2">
                  <button
                    type="submit"
                    disabled={isLoading || isSubmitting}
                    className="w-1/2 rounded-lg bg-greenColor px-4 py-2 text-white"
                  >
                    Submit
                  </button>
                  <Link to={`/admin/stores/${id}/users`} className="w-1/2">
                    <button className="ml-4 w-full rounded-lg bg-gray-200 px-5 py-2 text-black">Cancel</button>
                  </Link>
                </div>
              </Form>
            )}
          </Formik>
        }
      </div>
    </div>
  );
};

export default AddStoreUserPage;
