import * as yup from 'yup';

export const AddAgencySchema = yup.object().shape({
  name: yup.string().required('Agency name is required'),
  email: yup.string().email('Please enter a valid email').required('Email is required'),
  phone: yup.string().required('Phone number is required'),
  address: yup.string().required('Address is required')
});

export const EditAgencySchema = yup.object().shape({
  name: yup.string().required('Agency name is required'),
  email: yup.string().email('Please enter a valid email').required('Email is required'),
  phone: yup.string().required('Phone number is required'),
  address: yup.string().required('Address is required'),
  isActive: yup.boolean()
});
