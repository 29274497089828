import type {ParsedReceiptWrapper} from "../../../common/types";
import ParsedReceiptResult from './ParsedReceiptResult';

interface IProps {
  result: ParsedReceiptWrapper;
}

const ParsedReceiptSummary: React.FC<IProps> = ({ result }) => {
  return (
    <div className="max-w-xl p-4">
      <div className="flex h-full flex-col rounded-lg bg-gray-100 p-8">
        <div className="mb-3 flex items-center">
          <span className="font-bold">{result.sampleReceiptTitle}</span>
        </div>
        <ParsedReceiptResult result={result.parsedResult} />
      </div>
    </div>
  );
};

export default ParsedReceiptSummary;
