import { apiSlice } from '../../app/services/api-slice';
import { type OwnerType } from '../../common/constants';
import { urlWithPageAndTerm } from '../../common/helpers';
import type {
  ApiConfig,
  ApiConfigApiReq,
  ApiConfigUpdateRequest,
  ApiSuccessResponse,
  PaginatedData,
  PaginatedMeta
} from '../../common/types';

const apiConfigTagName: string = 'ApiConfig';

const apiConfigTagApi = apiSlice
  .enhanceEndpoints({
    addTagTypes: [apiConfigTagName]
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getAll: builder.query<PaginatedData<ApiConfig> | null, { page: number }>({
        query: ({ page = 1 }): string => {
          return urlWithPageAndTerm('/api-config', { page });
        },
        transformResponse: (data: ApiSuccessResponse) => {
          if (data.data === undefined) {
            return null;
          }
          return {
            data: data.data.data as ApiConfig[],
            meta: data.data.meta as PaginatedMeta
          };
        },
        providesTags: (result) =>
          result != null
            ? [
                ...result.data.map(({ id }) => ({
                  type: apiConfigTagName,
                  id
                })),
                { type: apiConfigTagName, id: 'LIST' }
              ]
            : [{ type: apiConfigTagName, id: 'LIST' }]
      }),
      addApiConfig: builder.mutation({
        query: (apiConfig: ApiConfigApiReq) => ({
          url: '/api-config',
          method: 'POST',
          body: apiConfig
        }),
        invalidatesTags: [{ type: apiConfigTagName, id: 'LIST' }]
      }),
      getApiConfigById: builder.query<ApiConfig, string>({
        query: (id: string) => `/api-config/${id}`,
        transformResponse: (data: ApiSuccessResponse) => {
          return data.data as ApiConfig;
        },
        providesTags: (result) => (result != null ? [{ type: apiConfigTagName, id: result?.id }] : [])
      }),
      getApiConfigByOwner: builder.query<ApiConfig, { ownerType: OwnerType; ownerId: string }>({
        query: ({ ownerId, ownerType }) => `/api-config/${ownerType}/${ownerId}`,
        transformResponse: (data: ApiSuccessResponse) => {
          return data.data as ApiConfig;
        },
        providesTags: (result) => (result != null ? [{ type: apiConfigTagName, id: result?.id }] : [])
      }),
      updateApiConfig: builder.mutation<ApiConfig, ApiConfigUpdateRequest>({
        query: ({ id, body }) => ({
          url: `/api-config/${id}`,
          method: 'PATCH',
          body
        }),
        invalidatesTags: (result, error, arg) => [{ type: apiConfigTagName, id: arg.id }]
      }),
      deleteApiConfigById: builder.mutation<void, string>({
        query: (id: string) => ({
          url: `/api-config/${id}`,
          method: 'DELETE'
        }),
        invalidatesTags: (result, error, arg) => [{ type: apiConfigTagName, id: 'LIST' }]
      })
    }),
    overrideExisting: false
  });

export const {
  useGetAllQuery,
  useAddApiConfigMutation,
  useGetApiConfigByIdQuery,
  useGetApiConfigByOwnerQuery,
  useUpdateApiConfigMutation,
  useDeleteApiConfigByIdMutation
} = apiConfigTagApi;
