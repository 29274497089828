import { useParams } from 'react-router-dom';

import AddStore from './AddStore';

const AddStoreForMerchant: React.FC = () => {
  const { id } = useParams();

  return <AddStore merchantId={id} />;
};

export default AddStoreForMerchant;
