import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

import { type Agency } from '../../../common/types';
import Breadcrumb from '../../../components/Breadcrumb';
import LoadingSpinner from '../../../components/LoadingSpinner';
import Pagination from '../../../components/Pagination';
import SuccessMessage from '../../../components/success-message';
import { useGetAgenciesQuery } from '../agency.api-slice';
import AddAgency from '../components/AddAgency';
import ApproveAgency from '../components/ApproveAgency';
import DeleteAgency from '../components/DeleteAgency';
import EditAgency from '../components/EditAgency';
import ListAgencies from '../components/ListAgencies';

interface IProps {
  filter?: { pending: boolean };
}

const ListAgenciesPage: React.FC<IProps> = ({ filter }) => {
  const [openAddModal, setOpenAddModal] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openApproveModal, setOpenApproveModal] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<Agency | null>(null);
  const [page, setPage] = useState<number>(1);
  const { data: items, isLoading } = useGetAgenciesQuery({ page, pendingOnly: filter?.pending === true });
  const { t } = useTranslation();

  const toggleAddModal = (): void => {
    setOpenAddModal(!openAddModal);
  };

  const toggleEditModal = (): void => {
    if (openEditModal) {
      setSelectedItem(null);
    }
    setOpenEditModal(!openEditModal);
  };

  const toggleDeleteModal = (): void => {
    if (openDeleteModal) {
      setSelectedItem(null);
    }
    setOpenDeleteModal(!openDeleteModal);
  };

  const toggleApproveModal = (): void => {
    if (openApproveModal) {
      setSelectedItem(null);
    }
    setOpenApproveModal(!openApproveModal);
  };

  const handleToggleActionModal = (action: string, item: Agency): void => {
    if (action === 'edit') {
      setOpenEditModal((openEditModal) => !openEditModal);
      setSelectedItem(item);
    }

    if (action === 'delete') {
      setOpenDeleteModal((openDeleteModal) => !openDeleteModal);
      setSelectedItem(item);
    }

    if (action === 'approve') {
      setOpenApproveModal((openApproveModal) => !openApproveModal);
      setSelectedItem(item);
    }
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <div className="pb-4">
        <h1>{t('agencies.list.title')}</h1>
        <Breadcrumb
          items={[{ label: t('common.adminBreadcrumbLabel') }, { label: t('agencies.list.breadcrumbLabel') }]}
        />
      </div>

      <div className="flex w-full flex-col space-y-2 md:flex-row md:items-center md:justify-between">
        <h1 className="text-2xl">{t('agencies.list.heading')}</h1>
        <div className="flex justify-items-center gap-x-2 md:items-center md:justify-between">
          {filter?.pending === true && (
            <Link to="/admin/agencies">
              <button data-cy="list-agency-btn" className="ml-4 rounded-lg bg-gray-50 px-5 py-2">
                {t('agencies.list.listLinkLabel')}
              </button>
            </Link>
          )}
          {filter?.pending !== true && (
            <>
              <Link to="/admin/agencies/pending-requests">
                <button data-cy="pending-agency-btn" className="ml-4 rounded-lg bg-gray-50 px-5 py-2">
                  {t('agencies.list.pendingListLabel')}
                </button>
              </Link>
              <button
                data-cy="add-agency-btn"
                onClick={toggleAddModal}
                className="ml-4 rounded-lg bg-greenColor px-5 py-2 text-white">
                {t('agencies.list.inviteLabel')}
              </button>
            </>
          )}
        </div>
      </div>

      <AddAgency isOpen={openAddModal} toggleModal={toggleAddModal} />

      <EditAgency isOpen={openEditModal} toggleEditModal={toggleEditModal} item={selectedItem} />

      <DeleteAgency isOpen={openDeleteModal} toggleDeleteModal={toggleDeleteModal} item={selectedItem} />

      <ApproveAgency isOpen={openApproveModal} toggleApproveModal={toggleApproveModal} item={selectedItem} />

      {items?.data == null ||
        (items.data.length === 0 && <SuccessMessage message={t('agencies.list.empty')} type="error" />)}

      {items?.data != null && items.data.length > 0 && (
        <>
          <ListAgencies
            items={items.data}
            filter={{ pending: filter?.pending === true }}
            handleToggleActionModal={handleToggleActionModal}
          />
          <Pagination pageData={items.meta} handlePageChange={setPage} />
          <Tooltip id="agency-list" />
        </>
      )}
    </div>
  );
};

export default ListAgenciesPage;
