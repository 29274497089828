import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineClose } from 'react-icons/ai';
import { toast } from 'react-toastify';

import { extractErrorsFromApi } from '../../../common/helpers';
import { type Receipt } from '../../../common/types';
import RenderErrorBag from '../../../components/render-error-bag';
import { useRetryReceiptParseMutation } from '../receipt.api-slice';
import { ReceiptParseStatus } from '../types';

interface IProps {
  isOpen: boolean;
  toggleRetryModal: () => any;
  item: Receipt | null;
}

const RetryReceipt: React.FC<IProps> = ({ item, isOpen, toggleRetryModal }) => {
  const [retryItem, { isSuccess, data, isLoading, isError, error, reset }] = useRetryReceiptParseMutation();
  const [errorBag] = useState<string[]>([]);
  const { t } = useTranslation();

  const handleRetryItem = (): void => {
    if (item?.id == null) {
      return;
    }
    void retryItem(item.id);
    toggleRetryModal();
  };

  useEffect(() => {
    if (isSuccess) {
      if (data != null && data.status !== ReceiptParseStatus.SUCCESS) {
        toast.warning(data.reason);
      } else {
        if (data != null && data.reason !== '') {
          toast(data.reason);
        } else {
          toast(t('receipts.retry.successMessage'));
        }
      }
      reset();
    }
    if (isError && error != null) {
      toast.error(extractErrorsFromApi(error).toString);
      reset();
    }
  }, [isSuccess, error]);

  return (
    <>
      {isOpen && item != null ? (
        <>
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden outline-none focus:outline-none">
            <div className="mx-auto my-6 w-1/2">
              <div className="flex flex-col rounded-lg border-0 bg-white shadow-lg outline-none focus:outline-none">
                <div className="flex items-start justify-between rounded-t border-b border-solid border-slate-200 p-5">
                  <h3 className="text-3xl font-semibold">{t('receipts.retry.title')}</h3>
                  <button
                    className="float-right ml-auto border-0 p-1 text-3xl font-semibold leading-none text-black outline-none focus:outline-none"
                    onClick={toggleRetryModal}
                  >
                    <AiOutlineClose size={20} />
                  </button>
                </div>
                <div className="relative w-full flex-auto p-6">
                  {errorBag.length > 0 && <RenderErrorBag errorBag={errorBag} />}
                  {t('receipts.retry.body')}
                </div>
                <div className="mt-4 flex w-full justify-end space-x-2 rounded-b border-t border-solid border-slate-200 px-4 py-6">
                  <button className="rounded-lg bg-gray-200 px-5 py-2 text-black" onClick={toggleRetryModal}>
                    {t('receipts.retry.cancelBtnLabel')}
                  </button>
                  <button
                    disabled={isLoading}
                    onClick={handleRetryItem}
                    className="rounded-lg bg-greenColor px-4 py-2 text-white"
                  >
                    {t('receipts.retry.saveBtnLabel')}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
        </>
      ) : null}
    </>
  );
};

export default RetryReceipt;
