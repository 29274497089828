import { Form, Formik, type FormikHelpers } from 'formik';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { extractErrorsFromApi } from '../../../common/helpers';
import { type MerchantStoreRequest } from '../../../common/types';
import CustomTextInput from '../../../components/Form/custom-text-input';
import RenderErrorBag from '../../../components/render-error-bag';
import { useAddStoreMutation } from '../store.api-slice';
import { AddMerchantStoreSchema } from '../store.schema';

const initialStoreData: MerchantStoreRequest = {
  name: 'Demo Store',
  email: 'demo@example.com',
  phone: '0212346789',
  address: '200 Wynward Street'
};

interface IProps {
  merchantId?: string;
}

const AddStore: React.FC<IProps> = ({ merchantId }) => {
  const [createMerchantStore, { data, isSuccess, isLoading, isError, error }] = useAddStoreMutation();
  const [errorBag, setErrorBag] = useState<string[]>([]);
  const navigate = useNavigate();

  const handleAddStore = async (
    values: MerchantStoreRequest,
    actions: FormikHelpers<MerchantStoreRequest>
  ): Promise<any> => {
    const storeDto = merchantId != null ? { ...values, merchantId } : { ...values };
    await createMerchantStore(storeDto);
    actions.resetForm();
  };

  useEffect(() => {
    if (isSuccess) {
      const message = 'Merchant store is successfully created.';
      toast(message);
      navigate('/admin/stores');
    }

    if (isError && error != null) {
      setErrorBag(extractErrorsFromApi(error));
    }
  }, [data, error, isError, isLoading, isSuccess, navigate]);

  return (
    <div className="mx-auto my-8 flex min-h-[70vh] max-w-2xl flex-col items-start rounded-md bg-gray-50 p-8">
      <div className="flex w-full items-center justify-center">
        <span className="h-full w-full text-2xl">Add New Store</span>
        <Link to="/admin/stores" className="w-1/4 rounded-lg bg-greenColor p-2 text-center text-white">
          <button>List Stores</button>
        </Link>
      </div>
      <div className="mt-4 flex w-full flex-col items-start px-2">
        {errorBag.length > 0 && <RenderErrorBag errorBag={errorBag} />}
        {!isSuccess && (
          <Formik initialValues={initialStoreData} onSubmit={handleAddStore} validationSchema={AddMerchantStoreSchema}>
            {({ isSubmitting }) => (
              <Form className="flex w-full flex-col items-start px-2" autoComplete="off">
                <CustomTextInput
                  type="text"
                  label="Store Name"
                  name="name"
                  divClass="w-full flex flex-col "
                  labelClass="w-full text-left text-gray-600"
                  inputClass="w-full my-1 px-4 py-1 rounded-md"
                />
                <CustomTextInput
                  type="text"
                  label="Email"
                  name="email"
                  divClass="w-full flex flex-col"
                  labelClass="w-full text-left mt-2 text-gray-600"
                  inputClass="w-full my-1 px-4 py-1 rounded-md"
                />
                <CustomTextInput
                  type="text"
                  label="Phone"
                  name="phone"
                  divClass="w-full flex flex-col"
                  labelClass="w-full text-left mt-2 text-gray-600"
                  inputClass="w-full my-1 px-4 py-1 rounded-md"
                />
                <CustomTextInput
                  type="text"
                  label="Address"
                  name="address"
                  divClass="w-full flex flex-col"
                  labelClass="w-full text-left mt-2 text-gray-600"
                  inputClass="w-full my-1 px-4 py-1 rounded-md"
                />
                <div className="mt-4 flex w-full space-x-2">
                  <button
                    type="submit"
                    disabled={isLoading || isSubmitting}
                    className="w-1/2 rounded-lg bg-greenColor px-4 py-2 text-white"
                  >
                    Submit
                  </button>
                  <Link to="/admin/stores" className="w-1/2">
                    <button className="ml-4 w-full rounded-lg bg-gray-200 px-5 py-2 text-black">Cancel</button>
                  </Link>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </div>
  );
};

export default AddStore;
