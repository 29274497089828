import { type FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { type OwnerEntityType } from '../../../common/constants';
import { getAgencyAndMerchantIdFromOwnerEntity } from '../../../common/helpers';
import { Action, type Agency, type Device, type Merchant, type MerchantStore } from '../../../common/types';
import Breadcrumb from '../../../components/Breadcrumb';
import DeleteConfiguration from '../components/DeleteConfiguration';
import { useAddConfigurationMutation, useUpdateConfigurationMutation } from '../configuration.api-slice';
import { convertConfigItemToArray, replaceConfigItemByIndex } from '../helpers';
import { type ConfigurationCreateRequest, type ConfigurationDataInput, type ConfigurationItemTemp } from '../types';
import ConfigurationItem from './Configurationitem';

interface IProps {
  ownerEntityType: OwnerEntityType;
  ownerEntity: Agency | Merchant | MerchantStore | Device;
  configuration: ConfigurationDataInput;
  id?: string;
}

const ConfigurationBuilder: React.FC<IProps> = ({ ownerEntityType, ownerEntity, configuration, id }) => {
  const [addResource] = useAddConfigurationMutation();
  const [updateResource, { isSuccess }] = useUpdateConfigurationMutation();
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [currentConfigs, setCurrentConfigs] = useState<ConfigurationItemTemp[]>(
    convertConfigItemToArray(configuration)
  );

  const { t } = useTranslation();

  const toggleDeleteModal = (): void => {
    setOpenDeleteModal((prev) => !prev);
  };

  const handleAddConfigItem = (): void => {
    setCurrentConfigs((prev) => [...prev, { key: `key-${prev.length + 1}`, value: `value-${prev.length + 1}` }]);
  };
  const handleSubmit = (event: FormEvent): void => {
    event.preventDefault();

    if (currentConfigs.length === 0) {
      toast.warning('At least one configuration item is required');
      return;
    }

    const { agencyId, merchantId } = getAgencyAndMerchantIdFromOwnerEntity(
      ownerEntityType as unknown as string,
      ownerEntity
    );

    const configToSend = currentConfigs.reduce<Record<string, any>>((result, configItem) => {
      result[configItem.key] = configItem.value;
      return result;
    }, {});
    const configObj: ConfigurationCreateRequest = {
      config: configToSend,
      ownerEntityType: ownerEntityType as unknown as string,
      ownerEntityId: ownerEntity.id,
      agencyId,
      merchantId
    };

    if (id != null) {
      void updateResource({ id, body: configObj });
    } else {
      void addResource(configObj);
      toast('Configuration is successfully attached');
    }
  };

  const handleConfigItemChange = (action: Action, index: number, value: { key: string; value: any }): void => {
    if (action === Action.MODIFY) {
      const updateConfigs = replaceConfigItemByIndex<ConfigurationItemTemp>(currentConfigs, index, value);
      setCurrentConfigs(updateConfigs);
    }

    if (action === Action.REMOVE) {
      setCurrentConfigs((prevState) => prevState.filter((configItem) => configItem.key !== value.key));
    }
  };

  useEffect(() => {
    if (isSuccess) {
      const message = 'Configuration is successfully updated.';
      toast(message);
    }
  }, [isSuccess]);

  return (
    <div>
      <div className="pb-4">
        <h1>{t('configuration.add.title')}</h1>
        <Breadcrumb items={[{ label: t('common.adminBreadcrumbLabel') }, { label: t('configuration.add.title') }]} />
      </div>
      <div className="flex w-full items-center justify-between">
        <div className="flex h-full w-full text-center text-2xl">
          {id !== undefined ? t('configuration.edit.title') : t('configuration.add.title')}
        </div>
        <div className="flex">
          {id != null && (
            <div className="flex w-1/2 items-center justify-end">
              <button onClick={toggleDeleteModal} className="ml-4 rounded-lg bg-red-800 px-5 py-2 text-white">
                {t('configuration.delete.title')}
              </button>
            </div>
          )}
          <div className="flex w-1/2 items-center justify-end">
            <button onClick={handleAddConfigItem} className="ml-4 rounded-lg bg-primary px-5 py-2 text-white">
              {t('configuration.add.addConfig')}
            </button>
          </div>
        </div>
      </div>
      <div className="flex w-full flex-col items-center justify-between">
        <div className="flex w-full flex-col p-2">
          <div>
            {currentConfigs.length !== 0 &&
              currentConfigs?.map((configItem, index) => (
                <ConfigurationItem
                  key={index}
                  configItemKey={configItem.key}
                  configItemValue={configItem.value}
                  handleChange={handleConfigItemChange}
                  index={index}
                />
              ))}
          </div>
          {currentConfigs.length !== 0 && (
            <div className="">
              <button onClick={handleSubmit} type="submit" className="mt-2 w-24 rounded-lg bg-primary py-2 text-white">
                Submit
              </button>
            </div>
          )}
        </div>
        {id != null && <DeleteConfiguration isOpen={openDeleteModal} toggleDeleteModal={toggleDeleteModal} id={id} />}
      </div>
    </div>
  );
};

export default ConfigurationBuilder;
