import { useTranslation } from 'react-i18next';
import { AiOutlineClose } from 'react-icons/ai';

import { type ApiConfig } from '../../../common/types';

interface IProps {
  isOpen: boolean;
  toggleModal: () => any;
  item: ApiConfig | null;
}

const ApiConfigInfo: React.FC<IProps> = ({ item, isOpen, toggleModal }) => {
  const { t } = useTranslation();
  return (
    <>
      {isOpen && item != null ? (
        <>
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden outline-none focus:outline-none">
            <div className="mx-auto my-6 w-1/2">
              <div className="flex flex-col rounded-lg border-0 bg-white shadow-lg outline-none focus:outline-none">
                <div className="flex items-start justify-between rounded-t border-b border-solid border-slate-200 p-5">
                  <h3 className="text-3xl font-semibold">{t('apiConfigs.info.title')}</h3>
                  <button
                    className="float-right ml-auto border-0 p-1 text-3xl font-semibold leading-none text-black outline-none focus:outline-none"
                    onClick={toggleModal}
                  >
                    <AiOutlineClose size={20} />
                  </button>
                </div>

                <div className="flex flex-col space-y-2 whitespace-pre-wrap px-8 py-2">
                  <div className="flex flex-row space-x-2">
                    <span className="font-bold">Account ID</span>
                    <span>{item.accountId}</span>
                  </div>
                  <div className="flex space-x-2">
                    <span className="font-bold">User</span>
                    <span>{item.userId}</span>
                  </div>
                  <div className="flex space-x-2">
                    <span className="font-bold">Campaign</span>
                    <span>{item.campaignId}</span>
                  </div>
                  <div className="flex space-x-2">
                    <span className="font-bold">API Key</span>
                    <span>{item.userApiKey}</span>
                  </div>

                  <div className="flex space-x-2">
                    <span className="font-bold">Password</span>
                    <span>{item.userPassword}</span>
                  </div>
                </div>

                <div className="mt-4 flex w-full justify-end space-x-2 rounded-b border-t border-solid border-slate-200 px-4 py-6">
                  <button className="rounded-lg bg-greenColor px-4 py-2 text-white" onClick={toggleModal}>
                    {t('apiConfigs.info.okayBtnLabel')}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
        </>
      ) : null}
    </>
  );
};

export default ApiConfigInfo;
