import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useParams } from 'react-router-dom';

import { useGetAgencyByIdQuery } from '../../agencies/agency.api-slice';
import ListReceiptsPage from './ListReceipts';

const ListReceiptsForAgency: React.FC = () => {
  const { id } = useParams();
  const { data } = useGetAgencyByIdQuery(id ?? skipToken);

  return <ListReceiptsPage agency={data} filter={'agency'} />;
};

export default ListReceiptsForAgency;
