import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useParams } from 'react-router-dom';

import LoadingSpinner from '../../../components/LoadingSpinner';
import { useGetMerchantByIdQuery } from '../../merchants/merchant.api-slice';
import ListUsersPage from './ListUsersPage';

interface IProps {
  pendingOnly?: boolean;
}

const ListUsersForMerchantPage: React.FC<IProps> = ({ pendingOnly }) => {
  const { id } = useParams();
  const { data, isLoading } = useGetMerchantByIdQuery(id ?? skipToken);

  if (isLoading || id == null) {
    return <LoadingSpinner />;
  }

  return <ListUsersPage scope={'merchant'} merchant={data} />;
};

export default ListUsersForMerchantPage;
