import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useParams } from 'react-router-dom';

import { useGetAgencyByIdQuery } from '../../agencies/agency.api-slice';
import ListDevicesPage from './ListDevicesPage';

const ListDevicesForAgency: React.FC = () => {
  const { id } = useParams();
  const { data } = useGetAgencyByIdQuery(id ?? skipToken);

  return <ListDevicesPage agency={data} scope={'agency'} />;
};

export default ListDevicesForAgency;
