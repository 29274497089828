import { AiOutlineFacebook, AiOutlineInstagram, AiOutlineTwitter, AiOutlineYoutube } from 'react-icons/ai';

import footerLogo from '../../assets/footerLogo.webp';

const Footer: React.FC = () => {
  return (
    <div className="mt-2 flex h-[15vh] w-full flex-col bg-sidebarBlack text-base text-primary">
      <div className="flex w-full justify-end px-4 pt-2">
        <span className="px-8">
          <AiOutlineInstagram size={30} className="text-white" />
        </span>
        <span className="px-8">
          <AiOutlineFacebook size={30} className="text-white" />
        </span>
        <span className="px-8">
          <AiOutlineTwitter size={30} className="text-white" />
        </span>
        <span className="px-8">
          <AiOutlineYoutube size={30} className="text-white" />
        </span>
      </div>
      <div className="mt-6 flex w-full justify-between text-sm">
        <div className="flex justify-around">
          <span className="px-4">Privacy Policy</span>
          <span className="px-4">Data Security</span>
        </div>
        <div className="flex justify-around">
          <span className="px-4">
            <img src={footerLogo} alt="Logo" className="inline h-[50%]" />
          </span>
          <span className="px-4">&copy; 2022 IQGecko</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
