import type { ApiConfig, ItemActionProps } from '../../../common/types';
import ApiConfigActionList from './ApiConfigActionList';

const ListApiConfigItem: React.FC<ItemActionProps<ApiConfig>> = ({ item, handleToggleActionModal }) => {
  return (
    <tr className="text-sm text-gray-700 odd:bg-white even:bg-gray-50 hover:bg-gray-300" key={item.id}>
      <td className="p-2 pl-8 text-sm text-gray-700">
        <div className="flex flex-col">
          <span>{item.id}</span>
        </div>
      </td>
      <td className="p-2">
        {item.ownerEntityType.toUpperCase()} - {item.ownerEntityId}
      </td>
      <td className="p-2 pr-9 text-sm text-gray-700 ">
        <div className="flex flex-row justify-center space-x-2">
          <ApiConfigActionList item={item} handleToggleActionModal={handleToggleActionModal} />
        </div>
      </td>
    </tr>
  );
};

export default ListApiConfigItem;
