import format from 'date-fns/format';

import { type ActionItem } from '../../../common/constants';
import type { Receipt } from '../../../common/types';
import NonDefaultCardIcon from './NonDefaultCardIcon';

interface IProps {
  item: Receipt;
  isActive: boolean;
  actions: ActionItem[];
}

const ListReceiptItem: React.FC<IProps> = ({ item, isActive, actions }) => {
  const getColoredStatusClassName = (status: string): string => {
    if (status === 'success') {
      return 'bg-green-100 text-green-800';
    } else if (status === 'error') {
      return 'bg-red-100 text-red-800';
    } else {
      return 'bg-blue-100 text-blue-800';
    }
  };
  return (
    <tr className={` ${isActive ? 'bg-primary' : 'odd:bg-white even:bg-gray-50'}`} key={item.id}>
      <td className="p-2 pl-8 text-sm text-gray-700">
        <div className="flex justify-items-center">
          <span>{item.id}</span>
          <NonDefaultCardIcon id={item.id} hasNonDefaultCard={item.hasNonDefaultCard} card={item.card} />
        </div>
      </td>
      <td className="p-2 text-sm text-gray-700">{item.deviceName}</td>
      <td className="p-2 text-sm text-gray-700">
        <span className={`mr-2 rounded px-2.5 py-0.5 text-xs font-medium ${getColoredStatusClassName(item.status)}`}>
          {item.status}
        </span>
      </td>
      <td className="p-2 text-sm text-gray-700">{item.reason}</td>
      <td className="p-2 text-sm text-gray-700">{item.retryCount}</td>
      <td className="p-2 text-sm text-gray-700">{format(new Date(item.createdDate), 'yyyy-MM-dd HH:mm')}</td>
      <td className="p-2 text-sm text-gray-700">{format(new Date(item.processedDate), 'yyyy-MM-dd HH:mm')}</td>
      <td className="p-2 text-sm text-gray-700">
        <div className="flex flex-row items-center justify-center space-x-2">
          {actions.map((action, index) => {
            return <div key={index}>{action.icon}</div>;
          })}
        </div>
      </td>
    </tr>
  );
};

export default ListReceiptItem;
