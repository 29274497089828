import * as yup from 'yup';

const fullNameIsRequired = 'Full name is required';

export const ProfileSchema = yup.object().shape({
  fullname: yup.string().required(fullNameIsRequired)
});

export const UserEditSchema = yup.object().shape({
  fullname: yup.string().required(fullNameIsRequired)
});

export const UserAddSchema = yup.object().shape({
  role: yup.string().required('Select a role'),
  fullname: yup.string().required(fullNameIsRequired),
  email: yup.string().required('Email is required'),
  password: yup.string().min(6, 'Password must be at least 6 letter long').required('Password is required'),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Password must match')
    .required('Password confirmation is required')
});
