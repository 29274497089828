import { Form, Formik, type FormikHelpers } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import AgencyForm from '../admin/agencies/components/AgencyForm';
import { useAuth } from '../app/hooks/useAuth';
import logo from '../assets/logo.webp';
import { extractErrorsFromApi } from '../common/helpers';
import { type MerchantCreateRequest } from '../common/types';
import CustomTextInput from '../components/Form/custom-text-input';
import RenderErrorBag from '../components/render-error-bag';
import AgencyRegisterSuccess from './agency-register-success';
import { useRegisterAgencyMutation } from './auth.api-slice';
import { AgencyRegisterSchema } from './auth.schema';

const initialMerchant: MerchantCreateRequest = {
  name: '',
  email: '',
  phone: '',
  address: '',
  contactPersonFullname: '',
  password: '',
  passwordConfirmation: ''
};

const AgencyRegisterForm: React.FC = () => {
  const [registerAgency, { data, isSuccess, isLoading, isError, error }] = useRegisterAgencyMutation();
  const [errorBag, setErrorBag] = useState<string[]>([]);
  const [successMessage, setSuccessMessage] = useState('');
  const auth = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onAgencyRegisterSuccess = async (
    values: MerchantCreateRequest,
    actions: FormikHelpers<MerchantCreateRequest>
  ): Promise<any> => {
    await registerAgency({ ...values });
    actions.resetForm();
  };

  useEffect(() => {
    if (auth.user != null) {
      navigate('/admin');
    }

    if (isSuccess) {
      const message =
        'Agency account is successfully requested. You will receive account details in mail once admin approves the request.';
      setSuccessMessage(message);
    }

    if (isError && error != null) {
      setErrorBag(extractErrorsFromApi(error));
    }
  }, [auth.user, data, error, isError, isLoading, isSuccess, navigate, successMessage]);

  return (
    <div className="mx-auto my-1 flex max-w-2xl flex-col items-start space-y-4 rounded-md bg-gray-50 px-6 py-4 text-center">
      <div className="w-full text-center">
        <img src={logo} alt="Logo" className="inline h-[10vh] px-32" />
      </div>
      <span className="mb-2 w-full text-center text-3xl font-bold">{t('auth.register.title')}</span>
      <div className="w-full">
        {isSuccess && successMessage !== '' && <AgencyRegisterSuccess message={successMessage} />}
        {error != null && errorBag.length > 0 && <RenderErrorBag errorBag={errorBag} />}
        {!isSuccess && (
          <Formik
            initialValues={initialMerchant}
            validationSchema={AgencyRegisterSchema}
            onSubmit={onAgencyRegisterSuccess}
          >
            {({ isSubmitting }) => (
              <Form className="flex w-full flex-col items-start space-y-2 px-2" autoComplete="off">
                <AgencyForm />

                <CustomTextInput
                  type="text"
                  label="Contact Person Fullname"
                  name="contactPersonFullname"
                  divClass="w-full flex flex-col"
                  labelClass="w-full text-left text-gray-600"
                  inputClass="w-full my-1 px-4 py-1 rounded-md"
                />
                <CustomTextInput
                  type="password"
                  label="Password"
                  name="password"
                  divClass="w-full flex flex-col"
                  labelClass="w-full text-left text-gray-600"
                  inputClass="w-full my-1 px-4 py-1 rounded-md"
                />
                <CustomTextInput
                  type="password"
                  label="Password Confirmation"
                  name="passwordConfirmation"
                  divClass="w-full flex flex-col"
                  labelClass="w-full text-left text-gray-600"
                  inputClass="w-full my-1 px-4 py-1 rounded-md"
                />

                <div className="mt-4 flex w-full flex-col space-y-2">
                  <button
                    type="submit"
                    disabled={isLoading || isSubmitting}
                    className="w-full rounded-lg bg-greenColor px-5 py-2 text-white disabled:opacity-20"
                  >
                    {t('auth.register.submitBtnLabel')}
                  </button>
                  <Link to="/login">
                    <button data-cy="login-page-btn" className="w-full rounded-lg bg-white px-5 py-2 text-black">
                      {t('auth.register.registerBtnLabel')}
                    </button>
                  </Link>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </div>
  );
};

export default AgencyRegisterForm;
