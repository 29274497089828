import { UserRole } from "../../common/constants";
import { type Option } from '../../common/types';

export const getAvailableOwnerTypeOptions = (loggerdInUserRole: string | undefined): Option[] => {
    if (!loggerdInUserRole) {
        return [];
    }
    if (loggerdInUserRole === UserRole.SUPER_ADMIN) {
        return [
            {label: 'Agency', value: 'agency'},
            {label: 'Merchant', value: 'merchant'},
            {label: 'Store', value: 'store'},
            {label: 'Device', value: 'device'},
        ];
    }
    if (loggerdInUserRole === UserRole.AGENCY_ADMIN) {
        return [
            {label: 'Merchant', value: 'merchant'},
            {label: 'Store', value: 'store'},
            {label: 'Device', value: 'device'},
        ];
    }

    if (loggerdInUserRole === UserRole.MERCHANT_ADMIN) {
        return [
            {label: 'Store', value: 'store'},
            {label: 'Device', value: 'device'},
        ];
    }

    if (loggerdInUserRole === UserRole.STORE_ADMIN) {
        return [
            {label: 'Device', value: 'device'},
        ];
    }

    return [];
}