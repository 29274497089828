import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { useAuth } from '../app/hooks/useAuth';

export const PrivateOutlet: React.FC = () => {
  const auth = useAuth();
  const location = useLocation();

  return auth.user != null ? <Outlet /> : <Navigate to="/login" state={{ from: location }} />;
};
