import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { useAuth } from './app/hooks/useAuth';
import SuccessMessage from './components/success-message';
import VerticalLayout from './components/VerticalLayout';

const AdminPage: React.FC = () => {
  const auth = useAuth();

  if (auth.user === null || auth.user === undefined) {
    const msg = 'Invalid State.';
    return <SuccessMessage message={msg} type="error" />;
  }

  return (
    <VerticalLayout authUser={auth.user}>
      <ToastContainer autoClose={2000} pauseOnFocusLoss draggable={false} pauseOnHover={true} theme="light" />
      <Outlet />
    </VerticalLayout>
  );
};

export default AdminPage;
