import format from 'date-fns/format';
import { useTranslation } from 'react-i18next';
import { AiOutlineClose, AiOutlineCopy } from 'react-icons/ai';
import { FaArchive } from 'react-icons/fa';

import { OwnerType } from '../../../common/constants';
import { type Receipt, type SampleReceiptCreateRequest } from '../../../common/types';
import HorizontalDivider from '../../devices/components/HorizontalDivider';
import { useAddSampleReceiptMutation } from '../../sample-receipts/sample-receipt.api-slice';
import { useMarkReceiptAsArchivableMutation } from '../receipt.api-slice';
import ParsedReceiptResult from './ParsedReceiptResult';

interface IProps {
  isOpen: boolean;
  toggleModal: () => any;
  item: Receipt | null;
}

const ReceiptInfo: React.FC<IProps> = ({ item, isOpen, toggleModal }) => {
  const { t } = useTranslation();
  const [markAsArchivable] = useMarkReceiptAsArchivableMutation();
  const [addSampleReceipt] = useAddSampleReceiptMutation();

  const handleMarkAsArchivable = (): void => {
    if (item == null) {
      return;
    }
    void markAsArchivable(item.id);
  };

  const handleSaveAsSample = (): void => {
    // console.log('Saving receipt as sample');
    if (item == null) {
      return;
    }

    const sampleReceiptInputDto: SampleReceiptCreateRequest = {
      title: `Copied Receipt#${item.id}`,
      receipt: item.receipt,
      ownerEntityType: OwnerType.DEVICE,
      ownerEntityId: item.deviceId,
      agencyId: item.agencyId,
      merchantId: item.merchantId
    };

    void addSampleReceipt(sampleReceiptInputDto);
  };
  return (
    <>
      {isOpen && item != null ? (
        <>
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden outline-none focus:outline-none">
            <div className="mx-auto my-6 w-1/2">
              <div className="flex flex-col rounded-lg border-0 bg-white shadow-lg outline-none focus:outline-none">
                <div className="flex items-center justify-between rounded-t border-b border-solid border-slate-200 p-5">
                  <h3 className="text-3xl font-semibold">{t('receipts.info.title')}</h3>
                  <button
                    onClick={handleMarkAsArchivable}
                    className="ml-4 flex items-center rounded-lg bg-primary px-2 py-1 text-sm text-white"
                  >
                    <div className="flex flex-row items-center space-x-1">
                      <FaArchive  />
                      <span>{t('receipts.info.archivableButtonLabel')}</span>  
                    </div>
                  </button>
                  <button
                    onClick={handleSaveAsSample}
                    className="ml-4 flex items-center rounded-lg bg-green-500 px-2 py-1 text-sm text-white"
                  >
                    <div className="flex flex-row items-center space-x-1">
                      <AiOutlineCopy />
                      <span>{t('receipts.info.saveAsSampleButtonLabel')}</span>  
                    </div>
                  </button>
                  <button
                    className="float-right ml-auto border-0 p-1 text-3xl font-semibold leading-none text-black outline-none focus:outline-none"
                    onClick={toggleModal}
                  >
                    <AiOutlineClose size={20} />
                  </button>
                </div>

                <div className="flex flex-col space-y-2 whitespace-pre-wrap p-4">
                  <div className="flex w-full flex-row justify-between">
                    <div className="flex w-1/2 flex-row space-x-2">
                      <span className="font-bold">Receipt ID</span>
                      <span>{item.id}</span>
                    </div>
                    <div className="flex w-1/2 flex-row space-x-2">
                      <span className="font-bold">Loyalty Card</span>
                      <span>{item.card}</span>
                    </div>
                  </div>
                  <div className="flex w-full flex-row justify-between">
                    <div className="flex w-1/2 flex-row space-x-2">
                      <span className="font-bold">Status</span>
                      <span>{item.status}</span>
                    </div>
                    <div className="flex w-1/2 flex-row space-x-2">
                      <span className="font-bold">Reason</span>
                      <span>{item.reason}</span>
                    </div>
                  </div>
                  <div className="flex w-full flex-row justify-between">
                    <div className="flex w-1/2 flex-row space-x-2">
                      <span className="font-bold">Retry Count</span>
                      <span>{item.retryCount}</span>
                    </div>
                    <div className="flex w-1/2 flex-row space-x-2">
                      <span className="font-bold">Last Processed At</span>
                      <span>{format(new Date(item.processedDate), 'yyyy-MM-dd HH:mm')}</span>
                    </div>
                  </div>
                  <div className="flex w-full flex-row justify-between">
                    <div className="flex w-1/2 flex-row space-x-2">
                      <span className="font-bold">Sent to external Service</span>
                      <span>{item.sentToExternalService ? 'No' : 'Yes'}</span>
                    </div>
                    <div className="flex w-1/2 flex-row space-x-2">
                      <span className="font-bold">Sent to external Service At</span>
                      <span>{format(new Date(item.sentToExternalServiceAt), 'yyyy-MM-dd HH:mm')}</span>
                    </div>
                  </div>
                  <HorizontalDivider />
                  <div className="flex max-h-64 flex-col overflow-y-auto">
                    <span className="font-bold">Receipt</span>
                    <span>{item.receipt}</span>
                  </div>
                  <HorizontalDivider />
                  <div className="flex max-h-64 flex-col overflow-y-auto">
                    <span className="font-bold">Parsed Result</span>
                    {item.parsedResult !== null && <ParsedReceiptResult result={item.parsedResult} />}
                  </div>
                </div>

                <div className="mt-4 flex w-full justify-end space-x-2 rounded-b border-t border-solid border-slate-200 px-4 py-6">
                  <button className="rounded-lg bg-gray-200 px-5 py-2 text-black text-primary" onClick={toggleModal}>
                    {t('receipts.info.okayBtnLabel')}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
        </>
      ) : null}
    </>
  );
};

export default ReceiptInfo;
