import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useParams } from 'react-router-dom';

import { OwnerType } from '../../../common/constants';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { useGetStoreByIdQuery } from '../../stores/store.api-slice';
import ListSampleReceiptsPage from './ListSampleReceiptsPage';

const ListStoreSampleReceiptsPage: React.FC = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetStoreByIdQuery(id != null ? id : skipToken);

  if (isLoading || id == null) {
    return <LoadingSpinner />;
  }

  return (
    <ListSampleReceiptsPage filter="store" merchantStore={data} ownerEntityType={OwnerType.STORE} ownerEntity={data} />
  );
};

export default ListStoreSampleReceiptsPage;
