import { useTranslation } from 'react-i18next';
import { FaBox, FaCheckCircle, FaStore, FaTimes, FaUsers } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { getStatusLabelClassnames, getStatusText } from '../../../common/helpers';
import { type Merchant } from '../../../common/types';
import MerchantActions from './MerchantActions';

interface IProps {
  item: Merchant;
  handleToggleActionModal: (action: string, item: Merchant) => any;
}

const ListMerchantItem: React.FC<IProps> = ({ item, handleToggleActionModal }) => {
  const { t } = useTranslation();

  return (
    <tr className="text-sm text-gray-700 odd:bg-white even:bg-gray-50 hover:bg-gray-300" key={item.id}>
      <td className="whitespace-nowrap p-2 pl-8">
        <div className="flex flex-col">
          <Link to={`/admin/merchants/${item.id}`} className="flex space-x-2 hover:text-primary">
            <span>{item.name}</span>
          </Link>
          <div className="flex items-center space-x-1">
            <span className="text-xs text-gray-400">{item.email.toLowerCase()}</span>
            {item.isApproved ? (
              <FaCheckCircle
                size={10}
                color="green"
                data-tooltip-id="merchant-list"
                data-tooltip-content={t('agencies.list.isApprovedTrue')}
              />
            ) : (
              <FaTimes
                size={10}
                color="red"
                data-tooltip-id="merchant-list"
                data-tooltip-content={t('agencies.list.isApprovedFalse')}
              />
            )}{' '}
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap p-2">{item.identifier}</td>
      <td className="whitespace-nowrap p-2">{item.address}</td>
      <td className="whitespace-nowrap p-2">{item.phone}</td>
      <td className="whitespace-nowrap p-2">
        <span
          className={`rounded px-2.5 py-0.5 text-xs font-medium text-gray-700 ${getStatusLabelClassnames(
            item.isActive
          )}`}>
          {getStatusText(item.isActive)}
        </span>{' '}
      </td>
      <td className="whitespace-nowrap p-2 pr-8 text-left text-sm text-gray-700">
        <div className="flex flex-row items-center space-x-3">
          <Link
            to={`/admin/merchants/${item.id}/stores`}
            data-tooltip-id="merchant-list"
            data-tooltip-content={t('merchants.list.storeListBtnLabel')}>
            <span className="h-full px-1">
              <FaStore color="green" size={18} />
            </span>
          </Link>
          <Link
            to={`/admin/merchants/${item.id}/devices`}
            data-tooltip-id="merchant-list"
            data-tooltip-content={t('merchants.list.deviceListBtnLabel')}>
            <span className="h-full px-1">
              <FaBox size={18} />
            </span>
          </Link>
          <Link
            to={`/admin/merchants/${item.id}/users`}
            data-tooltip-id="merchant-list"
            data-tooltip-content={t('merchants.list.userListBtnLabel')}>
            <span className="h-full px-1">
              <FaUsers color="green" size={18} />
            </span>
          </Link>
          <MerchantActions item={item} handleToggleActionModal={handleToggleActionModal} />
        </div>
      </td>
    </tr>
  );
};

export default ListMerchantItem;
