import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useParams } from 'react-router-dom';

import LoadingSpinner from '../../../components/LoadingSpinner';
import { useGetStoreByIdQuery } from '../../stores/store.api-slice';
import ListUsersPage from './ListUsersPage';

const ListUsersForStorePage: React.FC = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetStoreByIdQuery(id ?? skipToken);

  if (isLoading || id == null) {
    return <LoadingSpinner />;
  }

  return <ListUsersPage scope={'merchantStore'} store={data} />;
};

export default ListUsersForStorePage;
