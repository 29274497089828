import { Link } from 'react-router-dom';

import SuccessMessage from '../components/success-message';

interface IProps {
  message: string;
}

const AgencyRegisterSuccess: React.FC<IProps> = ({ message }) => {
  return (
    <div>
      <SuccessMessage message={message} type="success" />
      <Link to="/">
        <button className="w-full rounded-lg bg-greenColor px-5 py-2 text-white disabled:opacity-20">
          Go to Homepage
        </button>
      </Link>
    </div>
  );
};

export default AgencyRegisterSuccess;
