import { useState } from 'react';
import { DragDropContext, Draggable, type DropResult } from 'react-beautiful-dnd';
import { AiFillCaretDown } from 'react-icons/ai';
import { v4 as uuid } from 'uuid';

import { Action, type Section, type SectionTypes } from '../../../../common/types';
import { sectionButtons } from '../../constants';
import { StrictModeDroppable } from './CustomDraggableContext';
import SectionComponent from './SectionComponent';

interface IProps {
  sections: Section[];
  handleChange: (action: Action, value: Section) => void;
  handleReOrder: (sourceIndex: number, destinationIndex: number) => void;
}

const SectionBuilder: React.FC<IProps> = ({ sections, handleChange, handleReOrder }) => {
  const [openSectionSelectionButton, setOpenSectionSelectionButton] = useState(false);

  const handleToggleSection = (): void => {
    setOpenSectionSelectionButton(!openSectionSelectionButton);
  };

  const handleSectionSelection = (sectionType: SectionTypes): void => {
    handleChange(Action.ADD, { id: uuid(), label: `Section ${sections.length + 1}`, type: sectionType, value: [] });
    setOpenSectionSelectionButton(false);
  };

  const onSectionDrag = (result: DropResult): void => {
    if (result.destination == null) return;

    handleReOrder(result.source.index, result.destination.index);
  };

  return (
    <div className="flex w-full flex-col space-y-2">
      <div className="flex justify-between">
        <div className="text-xl">Section Mapping</div>
        <div className="relative inline-block text-left">
          <div>
            <button
              type="button"
              onClick={handleToggleSection}
              className="inline-flex w-full items-center justify-center gap-x-1.5 rounded-md bg-primary px-3 py-2 text-sm text-white"
              id="section-type-button"
              aria-expanded="true"
              aria-haspopup="true"
            >
              <span>Add Section</span>
              <AiFillCaretDown />
            </button>
          </div>
          <div>
            {openSectionSelectionButton && (
              <div
                className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="section-type-button"
                tabIndex={-1}
              >
                <div className="py-1" role="none">
                  {sectionButtons.map((section, index) => {
                    return (
                      <a
                        key={index}
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-primary hover:text-white focus:outline-none"
                        role="menuitem"
                        tabIndex={-1}
                        id={`menu-item-${index}`}
                        onClick={() => {
                          handleSectionSelection(section.value);
                        }}
                      >
                        {section.label}
                      </a>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <DragDropContext onDragEnd={onSectionDrag}>
        <StrictModeDroppable droppableId="section-area">
          {(provided) => (
            <div className="flex flex-col space-y-2" ref={provided.innerRef} {...provided.droppableProps}>
              {sections?.map((section, index) => (
                <Draggable key={section.id} draggableId={section.id} index={index}>
                  {(provided) => (
                    <SectionComponent
                      key={section.id}
                      section={section}
                      handleChange={handleChange}
                      innerRef={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    />
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </StrictModeDroppable>
      </DragDropContext>
    </div>
  );
};

export default SectionBuilder;
