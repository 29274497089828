import { AiOutlineMinusCircle } from 'react-icons/ai';

import { Action, type LineItemComponentV2, type ValueConstraints } from '../../../../common/types';
import CustomCheckboxInputV2 from '../../../../components/Form/CustomCheckboxInputV2';
import CustomSelectInputV2 from '../../../../components/Form/CustomSelectInputV2';
import CustomTextInputV3 from '../../../../components/Form/CustomTextInputV3';
import HorizontalDivider from '../../../devices/components/HorizontalDivider';
import { constraintOptions, separatorOptions, type SeparatorOptionValues } from '../../constants';
import {
  generateSelectOptionByKey,
  generateSeparatorByItsType,
  isReadOnlyLabel,
  mapSeparatorObjectToOption
} from '../../helpers';

interface IProps {
  lineItemComponent: LineItemComponentV2;
  handleChange: (action: Action, lineItemComponent: LineItemComponentV2) => void;
  innerRef: (element: HTMLElement | null) => void;
}

const LineItemReactComponent: React.FC<IProps> = ({ lineItemComponent, handleChange, innerRef, ...props }) => {
  const handleLineItemComponentDeletion = (): void => {
    handleChange(Action.REMOVE, lineItemComponent);
  };

  const handlePropChange = (name: string, value: string): void => {
    let updated: LineItemComponentV2 | null = null;
    if (name === 'name' && !isReadOnlyLabel(value)) {
      updated = { ...lineItemComponent, name: value, label: value };
    }
    if (lineItemComponent.rule.prefix != null && name === 'prefix') {
      updated = {
        ...lineItemComponent,
        rule: {
          ...lineItemComponent.rule,
          prefix: {
            ...lineItemComponent.rule.prefix,
            value
          }
        }
      };
    }

    if (lineItemComponent.rule.suffix != null && name === 'suffix') {
      updated = {
        ...lineItemComponent,
        rule: {
          ...lineItemComponent.rule,
          suffix: {
            ...lineItemComponent.rule.suffix,
            value
          }
        }
      };
    }

    if (name === 'separator') {
      updated = {
        ...lineItemComponent,
        rule: {
          ...lineItemComponent.rule,
          separator: generateSeparatorByItsType(value as SeparatorOptionValues)
        }
      };
    }

    if (name === 'constraints') {
      updated = {
        ...lineItemComponent,
        rule: {
          ...lineItemComponent.rule,
          valueConstraints: value as ValueConstraints
        }
      };
    }
    if (updated !== null) {
      handleChange(Action.MODIFY, updated);
    }
  };

  const handleCheckboxChange = (name: string, checked: boolean): void => {
    const updated = {
      ...lineItemComponent,
      uploadToExternalService: checked
    };
    handleChange(Action.MODIFY, updated);
  };

  return (
    <div className="flex flex-col rounded-md bg-gray-300 p-2" ref={innerRef} {...props} id={lineItemComponent.id}>
      <div className="flex flex-row space-x-2 space-y-2">
        <div className="flex flex-1 flex-col space-y-2 font-medium">
          <div className="flex items-center">
            <span className="px-2">{lineItemComponent.rule.operator}</span>
            <span>
              <CustomTextInputV3
                type="text"
                name="name"
                value={lineItemComponent.name}
                label=""
                readonly={isReadOnlyLabel(lineItemComponent.name)}
                handleChange={handlePropChange}
              />
            </span>
          </div>

          <div className="flex flex-row items-center justify-between space-x-2">
            {lineItemComponent.rule.prefix != null && (
              <>
                <span>Prefix</span>
                <CustomTextInputV3
                  type="text"
                  name="prefix"
                  value={lineItemComponent.rule.prefix.value}
                  label=""
                  handleChange={handlePropChange}
                />
              </>
            )}
            {lineItemComponent.rule.suffix != null && (
              <>
                <span>Suffix</span>
                <CustomTextInputV3
                  type="text"
                  name="suffix"
                  value={lineItemComponent.rule.suffix.value}
                  label=""
                  handleChange={handlePropChange}
                />
              </>
            )}
            <div className="flex items-center justify-between space-x-2">
              <span>Separator</span>
              <CustomSelectInputV2
                name="separator"
                label=""
                options={separatorOptions}
                selectOption={mapSeparatorObjectToOption(lineItemComponent.rule.separator)}
                handleChange={handlePropChange}
              />
            </div>
            <div className="flex items-center justify-between space-x-2">
              <span>Constraints</span>
              <CustomSelectInputV2
                name="constraints"
                label=""
                options={constraintOptions}
                selectOption={generateSelectOptionByKey(lineItemComponent.rule.valueConstraints)}
                handleChange={handlePropChange}
              />
            </div>
            <div className="flex items-center justify-between space-x-2">
              <span>Upload</span>
              <CustomCheckboxInputV2
                name="uploadToExternalService"
                label=""
                value={lineItemComponent.uploadToExternalService}
                handleChange={handleCheckboxChange}
              />
            </div>
          </div>
        </div>

        <button className="justify-center rounded-md px-3 py-2 text-sm" onClick={handleLineItemComponentDeletion}>
          <AiOutlineMinusCircle size={24} color="red" />
        </button>
      </div>
      <HorizontalDivider />
    </div>
  );
};

export default LineItemReactComponent;
