import { useState } from 'react';
import { AiFillCaretDown } from 'react-icons/ai';

import { Action, type GlobalIdentifierObject, type GlobalIdentifierOperator } from '../../../../common/types';
import { globalIdentifierOperators } from '../../constants';
import { generateGlobalIdentifierByName } from '../../helpers';
import GlobalIdentifierComponent from './GlobalIdentifierComponent';

interface IProps {
  identifiers: GlobalIdentifierObject[];
  handleChange: (action: Action, value: GlobalIdentifierObject) => void;
}

const GlobalIdentifiers: React.FC<IProps> = ({ identifiers, handleChange }) => {
  const [openGlobalIdentifierSelectionButton, setOpenGlobalIdentifierSelectionButton] = useState(false);

  const handleToggleGlobalIdentifierSelection = (): void => {
    setOpenGlobalIdentifierSelectionButton(!openGlobalIdentifierSelectionButton);
  };

  const handleGlobalIdentifierOperatorTypeSelection = (globalIdentifierOperator: GlobalIdentifierOperator): void => {
    handleChange(Action.ADD, generateGlobalIdentifierByName(identifiers.length, globalIdentifierOperator));
    setOpenGlobalIdentifierSelectionButton(false);
  };

  return (
    <div className="flex w-full flex-col space-y-2">
      <div className="flex items-center justify-between">
        <div className="text-xl">Global Identifier Builder</div>
        <div className="relative inline-block text-left">
          <div>
            <button
              type="button"
              onClick={handleToggleGlobalIdentifierSelection}
              className="inline-flex items-center justify-center gap-x-1.5 rounded-md bg-primary px-3 py-2 text-sm text-white"
              id="global-identifier-operator-type-button"
              aria-expanded="true"
              aria-haspopup="true"
            >
              <span>Add Global identifier</span>
              <AiFillCaretDown />
            </button>
          </div>
          <div>
            {openGlobalIdentifierSelectionButton && (
              <div
                className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="global-identifier-operator-type-button"
                tabIndex={-1}
              >
                <div className="py-1" role="none">
                  {globalIdentifierOperators.map((operator, index) => {
                    return (
                      <a
                        key={index}
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-primary hover:text-white focus:outline-none"
                        role="menuitem"
                        tabIndex={-1}
                        id={`menu-item-${index}`}
                        onClick={() => {
                          handleGlobalIdentifierOperatorTypeSelection(operator.value);
                        }}
                      >
                        {operator.label}
                      </a>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-col space-y-2">
        {identifiers?.map((globalIdentifier) => {
          return (
            <GlobalIdentifierComponent
              key={globalIdentifier.id}
              identifier={globalIdentifier}
              handleChange={handleChange}
            />
          );
        })}
      </div>
    </div>
  );
};

export default GlobalIdentifiers;
