import { useField } from 'formik';

interface CustomCheckboxInputProps {
  name: string;
  placeholder?: string;
  label: string;
  divClass: string;
  labelClass: string;
  inputClass: string;
  value?: boolean;
}

const CustomCheckboxInput: React.FC<CustomCheckboxInputProps> = ({
  label,
  divClass,
  labelClass,
  inputClass,
  value,
  ...props
}: CustomCheckboxInputProps) => {
  const [field, meta] = useField(props);

  const isError = Boolean(meta.touched && meta.error);

  const inputClassWithError = isError ? inputClass + ' border-red-600 border-[1px]' : inputClass;

  return (
    <div className={divClass}>
      <label className={labelClass}>{label}</label>
      <div className="flex flex-col">
        <input
          data-cy={`${props.name}-checkbox`}
          type="checkbox"
          checked={field.value}
          {...field}
          className={inputClassWithError}
          {...props}
        />
        {isError && <div className="text-left text-xs text-red-600">{meta.error}</div>}
      </div>
    </div>
  );
};

export default CustomCheckboxInput;
