import type { Agency, Device, Merchant, MerchantStore } from '../types';

export enum UserRole {
  SUPER_ADMIN = 'super-admin',
  AGENCY_ADMIN = 'agency-admin',
  MERCHANT_ADMIN = 'merchant-admin',
  STORE_ADMIN = 'store-admin',
  EMPLOYEE = 'employee'
}

export const UserRoleLabel: { [key in UserRole]: string } = {
  [UserRole.SUPER_ADMIN]: 'Super Admin',
  [UserRole.AGENCY_ADMIN]: 'Agency Admin',
  [UserRole.MERCHANT_ADMIN]: 'Merchant Admin',
  [UserRole.STORE_ADMIN]: 'Store Admin',
  [UserRole.EMPLOYEE]: 'Employee'
};

export enum OwnerType {
  SUPER = 'super',
  AGENCY = 'agency',
  MERCHANT = 'merchant',
  STORE = 'store',
  DEVICE = 'device',
  USER = 'user'
}

export const OwnerTypeLabel = ['Super', 'Agency', 'Merchant', 'Store', 'Device', 'User'];

export const AUTH_TOKEN_NAME = 'receipt-parser-jwt';

export type OwnerEntityType = Agency | Merchant | MerchantStore | Device;

export interface ActionItem {
  label: string;
  icon: JSX.Element | '';
  type: 'button' | 'link' | 'component';
  link?: string;
  handler?: () => any;
}

export enum SeparatorType {
  NONE = 'NONE',
  SINGLE_SPACE = 'SINGLE_SPACE',
  MULTI_SPACE = 'MULTI_SPACE',
  SINGLE_LINE_BREAK = 'SINGLE_LINE_BREAK',
  MULTI_LINE_BREAK = 'MULTI_LINE_BREAK'
}

export const separatorTypes = [
  { label: 'NONE', value: SeparatorType.NONE },
  { label: '1 Space', value: SeparatorType.SINGLE_SPACE },
  { label: '1+ Space', value: SeparatorType.MULTI_SPACE },
  { label: '1 new line', value: SeparatorType.SINGLE_LINE_BREAK },
  { label: '1+ new line', value: SeparatorType.MULTI_LINE_BREAK }
];
