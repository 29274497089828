import { Form, Formik, type FormikHelpers } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineClose } from 'react-icons/ai';
import { toast } from 'react-toastify';

import { extractErrorsFromApi } from '../../../common/helpers';
import { type ApiConfig } from '../../../common/types';
import RenderErrorBag from '../../../components/render-error-bag';
import { useUpdateApiConfigMutation } from '../sticky-config.api-slice';
import { AddApiConfigSchema } from '../sticky-config.schema';
import AddApiConfigFormV2 from './ApiConfigForm';

interface IProps {
  isOpen: boolean;
  toggleEditModal: () => any;
  item: ApiConfig | null;
}

const EditItem: React.FC<IProps> = ({ item, isOpen, toggleEditModal }) => {
  const [updateItem, { isLoading, isError, error, isSuccess, reset }] = useUpdateApiConfigMutation();
  const [errorBag, setErrorBag] = useState<string[]>([]);
  const { t } = useTranslation();

  const handleUpdateItem = async (values: ApiConfig, actions: FormikHelpers<ApiConfig>): Promise<any> => {
    if (item == null) {
      return;
    }
    await updateItem({ body: { ...values }, id: item.id });
    actions.resetForm();
    toggleEditModal();
  };

  useEffect(() => {
    if (isSuccess) {
      toast(t('apiConfigs.edit.successMessage'));
      reset();
    }
    if (isError && error != null) {
      setErrorBag(extractErrorsFromApi(error));
    }
  }, [isError, isSuccess]);

  return (
    <>
      {isOpen && item != null ? (
        <>
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden outline-none focus:outline-none">
            <div className="relative mx-auto my-6 w-full max-w-3xl">
              <div className="relative flex w-full flex-col rounded-lg border-0 bg-white shadow-lg outline-none focus:outline-none">
                <div className="flex items-start justify-between rounded-t border-b border-solid border-slate-200 p-5">
                  <h3 className="text-3xl font-semibold">{t('apiConfigs.edit.title')}</h3>
                  <button
                    className="float-right ml-auto border-0 p-1 text-3xl font-semibold leading-none text-black outline-none focus:outline-none"
                    onClick={toggleEditModal}
                  >
                    <AiOutlineClose size={20} />
                  </button>
                </div>
                <div className="relative w-full flex-auto p-6">
                  {errorBag.length > 0 && <RenderErrorBag errorBag={errorBag} />}
                  {
                    <Formik initialValues={item} onSubmit={handleUpdateItem} validationSchema={AddApiConfigSchema}>
                      {({ isSubmitting }) => (
                        <Form className="flex w-full flex-col items-start px-2" autoComplete="off">
                          <AddApiConfigFormV2 />
                          <div className="mt-4 flex w-full space-x-2 rounded-b border-t border-solid border-slate-200 py-6">
                            <button
                              className="w-1/2 rounded-lg bg-gray-200 px-5 py-2 text-black"
                              onClick={toggleEditModal}
                            >
                              {t('apiConfigs.edit.cancelBtnLabel')}
                            </button>
                            <button
                              type="submit"
                              disabled={isLoading || isSubmitting}
                              className="w-1/2 rounded-lg bg-greenColor px-4 py-2 text-white"
                            >
                              {t('apiConfigs.edit.saveBtnLabel')}
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
        </>
      ) : null}
    </>
  );
};

export default EditItem;
