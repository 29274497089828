import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useParams } from 'react-router-dom';

import { useGetMerchantByIdQuery } from '../../merchants/merchant.api-slice';
import ListReceiptsPage from './ListReceipts';

const ListReceiptsForMerchant: React.FC = () => {
  const { id } = useParams();
  const { data } = useGetMerchantByIdQuery(id ?? skipToken);

  return <ListReceiptsPage merchant={data} filter={'merchant'} />;
};

export default ListReceiptsForMerchant;
