import type { ReceiptRuleV2Dto } from '../../ReceiptRule/types';

interface IPropsTags {
  tags: string[];
}

const TagsList: React.FC<IPropsTags> = ({ tags }) => {
  return (
    <div className="flex flex-col">
      <div className="flex space-x-2">
        {tags.map((tag, index) => {
          return (
            <div key={index} className="flex items-center rounded-md	bg-green-200 px-2">
              <span className="pr-1 text-sm">{tag}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

interface IProps {
  rule: ReceiptRuleV2Dto;
}

const RuleSummary: React.FC<IProps> = ({ rule }) => {
  return (
    <div className="flex space-x-16">
      <div>
        <div className="flex justify-between border-b-2 px-2 py-1">
          <span>Leading Reference Qualifier</span>
          <span>
            <TagsList tags={rule.rule.generalComponents.leadingReferenceQualifier.tokens} />
          </span>
        </div>
        <div className="flex justify-between border-b-2 px-2 py-1">
          <span>Leading Reference Disqualifier</span>
          <span>
            <TagsList tags={rule.rule.generalComponents.leadingReferenceDisqualifier.tokens} />
          </span>
        </div>
        <div className="flex justify-between border-b-2 px-2 py-1">
          <span>Line Item Start Indicatior</span>
          <span>
            <TagsList tags={rule.rule.generalComponents.lineItemStartIndicator.tokens} />
          </span>
        </div>
        <div className="flex justify-between border-b-2 px-2 py-1">
          <span>Line Item End Indicatior</span>
          <span>
            <TagsList tags={rule.rule.generalComponents.lineItemEndIndicator.tokens} />
          </span>
        </div>
        {rule.rule.globalIdentifiers.map((identifier) => (
          <div key={identifier.id} className="flex justify-between border-b-2 px-2 py-1">
            <span>{identifier.name}</span>
            <span>
              <TagsList tags={identifier.rule.keyPattern} />
            </span>
          </div>
        ))}
      </div>
      <div>
        {rule.rule.lineItemComponents.map((lineItemComponent) => (
          <div key={lineItemComponent.id} className="flex flex-col justify-between border-b-2 px-2 py-1">
            <span className="font-bold">{lineItemComponent.name}</span>
            <div className="space-between mt-1 flex w-full justify-between">
              <span>
                Prefix{' '}
                <span className="rounded-md bg-green-200 px-1">
                  {lineItemComponent.rule.prefix?.value !== '' ? lineItemComponent.rule.prefix?.value : 'N/A'}
                </span>
              </span>
              <span>
                Suffix{' '}
                <span className="rounded-md bg-green-200 px-1">
                  {lineItemComponent.rule.suffix?.value !== '' ? lineItemComponent.rule.suffix?.value : 'N/A'}
                </span>
              </span>
              <span>
                Order <span className="rounded-md bg-green-200 px-1">{lineItemComponent.rule.order}</span>
              </span>
              <span className="flex items-center">
                Separator{''}
                <span className="rounded-md bg-green-200 px-1">
                  {lineItemComponent.rule.separator.type}, {lineItemComponent.rule.separator.value},{' '}
                  {lineItemComponent.rule.separator.minCount}
                </span>
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RuleSummary;
