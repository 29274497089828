import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { type ApiConfig } from '../../../common/types';
import Breadcrumb from '../../../components/Breadcrumb';
import LoadingSpinner from '../../../components/LoadingSpinner';
import Pagination from '../../../components/Pagination';
import SuccessMessage from '../../../components/success-message';
import ApiConfigInfo from '../components/ApiConfigInfo';
import DeleteApiConfig from '../components/DeleteApiConfig';
import EditApiConfigV2 from '../components/EditApiConfig';
import ListApiConfigs from '../components/ListApiConfigs';
import { useGetAllQuery } from '../sticky-config.api-slice';

const ListApiConfigsPage: React.FC = () => {
  const [page, setPage] = useState<number>(1);
  const { data: apiConfigs, isLoading } = useGetAllQuery({ page });
  const { t } = useTranslation();
  const [openInfoModal, setOpenInfoModal] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<ApiConfig | null>(null);

  const toggleInfoModal = (): void => {
    if (openInfoModal) {
      setSelectedItem(null);
    }
    setOpenInfoModal(!openInfoModal);
  };
  const toggleEditModal = (): void => {
    if (openEditModal) {
      setSelectedItem(null);
    }
    setOpenEditModal(!openEditModal);
  };

  const toggleDeleteModal = (): void => {
    if (openDeleteModal) {
      setSelectedItem(null);
    }
    setOpenDeleteModal(!openDeleteModal);
  };

  const handleToggleActionModal = (action: string, item: ApiConfig): void => {
    if (action === 'edit') {
      setOpenEditModal((openEditModal) => !openEditModal);
      setSelectedItem(item);
    }

    if (action === 'delete') {
      setOpenDeleteModal((openDeleteModal) => !openDeleteModal);
      setSelectedItem(item);
    }

    if (action === 'info') {
      setOpenInfoModal((openInfoModal) => !openInfoModal);
      setSelectedItem(item);
    }
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <div className="pb-4">
        <h1>{t('apiConfigs.list.title')}</h1>
        <Breadcrumb
          items={[{ label: t('common.adminBreadcrumbLabel') }, { label: t('apiConfigs.list.breadcrumbLabel') }]}
        />
      </div>
      <div className="flex w-full items-center justify-between">
        <h1>{t('apiConfigs.list.heading')}</h1>
      </div>

      <ApiConfigInfo isOpen={openInfoModal} toggleModal={toggleInfoModal} item={selectedItem} />
      <EditApiConfigV2 isOpen={openEditModal} toggleEditModal={toggleEditModal} item={selectedItem} />

      <DeleteApiConfig isOpen={openDeleteModal} toggleDeleteModal={toggleDeleteModal} item={selectedItem} />

      {apiConfigs?.data == null ||
        (apiConfigs.data.length === 0 && <SuccessMessage message={t('apiConfigs.list.empty')} type="error" />)}

      {apiConfigs?.data != null && apiConfigs.data.length > 0 && (
        <>
          <ListApiConfigs items={apiConfigs.data} handleToggleActionModal={handleToggleActionModal} />
          <Pagination pageData={apiConfigs.meta} handlePageChange={setPage} />
        </>
      )}
    </div>
  );
};

export default ListApiConfigsPage;
