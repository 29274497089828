import { useField } from 'formik';

interface CustomTextInputProps {
  type: string;
  name: string;
  placeholder?: string;
  label: string;
  divClass: string;
  labelClass: string;
  inputClass: string;
}

const CustomTextAreaInput: React.FC<CustomTextInputProps> = ({
  label,
  divClass,
  labelClass,
  inputClass,
  ...props
}: CustomTextInputProps) => {
  const [field, meta] = useField(props);

  const isError = Boolean(meta.touched && meta.error);
  const inputClassWithError = isError ? inputClass + ' border-red-600 border-[1px]' : inputClass;

  return (
    <div className={divClass}>
      <label className={labelClass}>{label}</label>
      <div className="flex flex-col">
        <textarea {...field} className={inputClassWithError} {...props} rows={20}></textarea>
        {isError && <div className="text-left text-xs text-red-600">{meta.error}</div>}
      </div>
    </div>
  );
};

export default CustomTextAreaInput;
