import type { Middleware, MiddlewareAPI } from '@reduxjs/toolkit';
import { isRejectedWithValue } from '@reduxjs/toolkit';

/**
 * Log a warning and show a toast!
 */
export const UnauthorizedHandlerMiddleware: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (isRejectedWithValue(action) && action.payload.status === 401) {
    console.warn('We got a rejected action!', action);
  }

  return next(action);
};
