import { type ReceiptRuleV2Dto } from '../types';
import ListReceiptRuleItem from './ListReceiptRuleItem';

interface IProps {
  items: ReceiptRuleV2Dto[];
  handleToggleActionModal: (action: string, item: ReceiptRuleV2Dto) => any;
}

const ListReceiptRule: React.FC<IProps> = ({ items: records, handleToggleActionModal }) => {
  return (
    <>
      <div className="my-2 w-full overflow-auto rounded-md p-2">
        <table className="w-full border-b-2 border-gray-200 p-4">
          <thead className="bg-gray-100 text-sm font-semibold">
            <tr>
              <th className="p-2 pl-8 text-left">ID</th>
              <th className="p-2 text-left">Name</th>
              <th className="p-2 text-left">Owner</th>
              <th className="p-2 font-semibold tracking-wide">Actions</th>
            </tr>
          </thead>
          <tbody className="">
            {records.map((record) => {
              return (
                <ListReceiptRuleItem key={record.id} item={record} handleToggleActionModal={handleToggleActionModal} />
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ListReceiptRule;
