import CustomCheckboxInput from '../../../components/Form/custom-checkbox-input';
import CustomTextInput from '../../../components/Form/custom-text-input';

const MerchantForm: React.FC = () => {
  return (
    <>
      <CustomTextInput
        type="text"
        label="Name"
        name="name"
        divClass="w-full flex flex-col "
        labelClass="w-full text-left text-gray-600"
        inputClass="w-full my-1 px-4 py-1 rounded-md"
      />
      <CustomTextInput
        type="text"
        label="Email"
        name="email"
        divClass="w-full flex flex-col"
        labelClass="w-full text-left text-gray-600"
        inputClass="w-full my-1 px-4 py-1 rounded-md"
      />
      <CustomTextInput
        type="text"
        label="Phone"
        name="phone"
        divClass="w-full flex flex-col"
        labelClass="w-full text-left text-gray-600"
        inputClass="w-full my-1 px-4 py-1 rounded-md"
      />
      <CustomTextInput
        type="text"
        label="Address"
        name="address"
        divClass="w-full flex flex-col"
        labelClass="w-full text-left text-gray-600"
        inputClass="w-full my-1 px-4 py-1 rounded-md"
      />
      <CustomCheckboxInput
        name="isActive"
        label="Active"
        divClass="w-full flex items-center"
        labelClass="text-left mt-2 text-gray-600"
        inputClass="p-2 mt-2 ml-4 rounded-md"
      />
    </>
  );
};

export default MerchantForm;
