import { skipToken } from '@reduxjs/toolkit/dist/query';

import type { OwnerType } from '../../../../common/constants';
import type { Agency, Device, Merchant, MerchantStore } from '../../../../common/types';
import AddApiConfig from '../../../sticky-config/components/AddApiConfig';
import EditApiConfigV2 from '../../../sticky-config/components/EditApiConfig';
import { useGetApiConfigByOwnerQuery } from '../../../sticky-config/sticky-config.api-slice';

interface IProps {
  ownerType: OwnerType;
  ownerEntity: Agency | Merchant | MerchantStore | Device;
  isOpen: boolean;
  toggleModal: () => any;
}

const AddOrEditApiConfig: React.FC<IProps> = ({ ownerType, ownerEntity, isOpen, toggleModal }) => {
  const { data: apiConfig } = useGetApiConfigByOwnerQuery(
    ownerEntity !== null ? { ownerType, ownerId: ownerEntity.id } : skipToken
  );

  return (
    <>
      {apiConfig != null && <EditApiConfigV2 isOpen={isOpen} toggleEditModal={toggleModal} item={apiConfig} />}
      {apiConfig == null && (
        <AddApiConfig isOpen={isOpen} toggleModal={toggleModal} ownerEntityType={ownerType} ownerEntity={ownerEntity} />
      )}
    </>
  );
};

export default AddOrEditApiConfig;
