import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import Breadcrumb from "../../../components/Breadcrumb";
import { FaFileExport, FaFileImport } from "react-icons/fa";
import UploadFile from "../components/UploadFile";
import { CSVLink } from "react-csv";
import { useImportSampleBonusItemsFileMutation } from "../receipt-rule.api-slice";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { extractErrorsFromApi } from "../../../common/helpers";

const ReceiptRuleBonusItemsPage: React.FC = () => {
    const { t } = useTranslation();
    const { ruleId } = useParams();
    const [errorBag, setErrorBag] = useState<string[]>([]);
    const [ addBonusItems, { isLoading, isError, error, isSuccess, reset } ] = useImportSampleBonusItemsFileMutation();
    const MAX_SIZE = 500000;
    const SAMPLE_FILE = [
        ["SN", "Product Name", "Bonus Point", "Start Date", "End Date"],
        [1, "Demo Product", 25, "2024-06-12", "2024-08-19"]
    ];

    useEffect(() => {
        if (isSuccess) {
          toast(t('receiptRule.bonusItems.successMessage'));
          reset();
        }
        if (isError && error != null) {
          setErrorBag(extractErrorsFromApi(error));
        }
      }, [isError, isSuccess]);

    const validateFile = (values: { file?: File }) => {
        if (!values.file) {
            return { file: "File should be selected"}
        }
        if (values.file && values.file.size > MAX_SIZE) {
            return { file: "Max file size exceeded." };
        }
    };

    

    const formik = useFormik<{ file?: File }>({
        initialValues: {},
        onSubmit: (values: any) => {
            if (!ruleId) {
                return;
            }
            addBonusItems({id: ruleId, file: values.file})
        },
        validate: validateFile
      });

    return (
        <div>
            <div className="pb-4">
                <h1>{t('receiptRule.bonusItems.title')}</h1>
                <Breadcrumb
                items={[{ label: t('common.adminBreadcrumbLabel') }, { label: t('receiptRule.list.breadcrumbLabel') }, { label: t('receiptRule.bonusItems.title') }]}
                />
        </div>
      <div className="flex w-full items-center justify-between px-2">
        <div className="flex w-full flex-col md:flex-row md:items-center md:justify-between">
        <h1 className="text-2xl">{t('receiptRule.bonusItems.title')}</h1>
        <div className="flex justify-items-center items-center gap-x-2 md:items-center md:justify-between">
            <button data-cy="list-agency-btn" className="ml-4 rounded-lg bg-greenColor text-white px-5 py-2">
                <CSVLink data={SAMPLE_FILE} filename="bonus_items_file.csv">
                    <div className="flex flex-row  items-center space-x-1">
                        <span className="h-full px-1">
                            <FaFileExport size={18} />
                        </span>
                        <span>{t('receiptRule.bonusItems.exportSample')}</span>
                    </div>
                </CSVLink>

            </button>
        </div>
      </div>
      </div>
      <div className="flex flex-col items-center h-96 justify-center">
        <UploadFile
            data={formik.values}
            errors={formik.errors}
            setFieldValue={formik.setFieldValue}
        />
        <button 
            data-cy="list-agency-btn" 
            className="bg-primary text-white mt-4 rounded-lg px-5 py-2" 
            type="submit" 
            onClick={() => formik.handleSubmit()}
            disabled={!formik.isValid || (formik.values.file ? false : true)}
        >
            <div className="flex flex-row items-center space-x-1">
                <span className="h-full px-1">
                    <FaFileImport size={18} />
                </span>
                <span>{t('receiptRule.bonusItems.importList')}</span>
            </div>
        </button>
      </div>
    </div>
    );
}

export default ReceiptRuleBonusItemsPage