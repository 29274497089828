import * as yup from 'yup';

export const AddMerchantSchema = yup.object().shape({
  name: yup.string().required('Merchant name is required'),
  email: yup.string().email('Please enter a valid email').required('Email is required'),
  phone: yup.string().required('Phone number is required'),
  address: yup.string().required('Address is required')
});

export const EditMerchantSchema = yup.object().shape({
  name: yup.string().required('Merchant name is required'),
  email: yup.string().email('Please enter a valid email').required('Email is required'),
  phone: yup.string().required('Phone number is required'),
  address: yup.string().required('Address is required'),
  isActive: yup.boolean()
});
