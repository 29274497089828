import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';
import { FaEllipsisVertical } from 'react-icons/fa6';
import { Link } from 'react-router-dom';

import { getActiveClassName } from '../../../common/helpers';
import type { ItemActionProps } from '../../../common/types';
import type { ReceiptRuleV2Dto } from '../types';

const ReceiptRuleActionsList: React.FC<ItemActionProps<ReceiptRuleV2Dto>> = ({ item, handleToggleActionModal }) => {
  const deleteItemHandler = (): void => {
    handleToggleActionModal('delete', item);
  };

  return (
    <div className="">
      <Menu as="div" className="">
        <div>
          <Menu.Button className="justify-center rounded-md text-sm font-medium text-white">
            <FaEllipsisVertical size={18} className="text-black hover:text-gray-600" aria-hidden="true" />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95">
          <Menu.Items className="absolute right-10 z-10 mt-2 w-48 origin-top-right divide-y divide-gray-100 rounded-md bg-white/100 shadow-lg ring-1 ring-black/5 focus:outline-none">
            <div className="p-1 ">
              <Menu.Item>
                {({ active }) => (
                  <Link to={`/admin/rules/${item.ownerEntityType}/${item.ownerEntityId}`}>
                    <div
                      className={`${getActiveClassName(
                        active
                      )} group flex w-full items-center space-x-2 rounded-md p-2 text-sm`}>
                      <span className="h-full px-1">
                        <AiOutlineEdit color="blue" size={18} />
                      </span>
                      <span>Edit</span>
                    </div>
                  </Link>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    data-cy="delete-rule-btn"
                    onClick={deleteItemHandler}
                    className={`${getActiveClassName(
                      active
                    )} group flex w-full items-center space-x-2 rounded-md p-2 text-sm`}>
                    <span className="h-full px-1">
                      <AiOutlineDelete color="red" size={18} />
                    </span>
                    <span>Delete</span>
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default ReceiptRuleActionsList;
