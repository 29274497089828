import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useParams } from 'react-router-dom';

import { OwnerType } from '../../../common/constants';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { useGetAgencyByIdQuery } from '../../agencies/agency.api-slice';
import { useGetDeviceByIdQuery } from '../../devices/device.api-slice';
import { useGetMerchantByIdQuery } from '../../merchants/merchant.api-slice';
import { useGetReceiptRuleByOwnerQuery } from '../../ReceiptRule/receipt-rule.api-slice';
import { useGetStoreByIdQuery } from '../../stores/store.api-slice';
import { initialRule } from '../constants';
import ReceiptRuleBuilderPage from './ReceiptRuleBuilderPage';

const getCancelDestination = (ownerEntityType: string, ownerEntityId: string): string => {
  let cancelUrl: string;
  if (ownerEntityType != null && OwnerType.AGENCY === ownerEntityType) {
    cancelUrl = `/admin/agencies/${ownerEntityId}`;
  } else if (ownerEntityType != null && OwnerType.MERCHANT === ownerEntityType) {
    cancelUrl = `/admin/merchants/${ownerEntityId}`;
  } else if (ownerEntityType != null && OwnerType.STORE === ownerEntityType) {
    cancelUrl = `/admin/stores/${ownerEntityId}`;
  } else if (ownerEntityType != null && OwnerType.DEVICE === ownerEntityType) {
    cancelUrl = '/admin/devices';
  } else {
    cancelUrl = '/admin/dashboard';
  }

  return cancelUrl;
};

const AddOrEditReceiptRule: React.FC = () => {
  const { ownerEntityType, ownerEntityId } = useParams();

  let ownerEntity;

  if (ownerEntityType != null && OwnerType.AGENCY === ownerEntityType) {
    const { data } = useGetAgencyByIdQuery(ownerEntityId != null ? ownerEntityId : skipToken);
    ownerEntity = data;
  } else if (ownerEntityType != null && OwnerType.MERCHANT === ownerEntityType) {
    const { data } = useGetMerchantByIdQuery(ownerEntityId != null ? ownerEntityId : skipToken);
    ownerEntity = data;
  } else if (ownerEntityType != null && OwnerType.STORE === ownerEntityType) {
    const { data } = useGetStoreByIdQuery(ownerEntityId != null ? ownerEntityId : skipToken);
    ownerEntity = data;
  } else if (ownerEntityType != null && OwnerType.DEVICE === ownerEntityType) {
    const { data } = useGetDeviceByIdQuery(ownerEntityId != null ? ownerEntityId : skipToken);
    ownerEntity = data;
  } else {
    throw Error('Invalid data');
  }

  const { data: rule, isLoading: loadingResourceById } = useGetReceiptRuleByOwnerQuery(
    ownerEntityId != null && ownerEntityType != null ? { ownerEntityType, ownerEntityId } : skipToken
  );

  if (ownerEntity == null || ownerEntityType == null || ownerEntityId == null || loadingResourceById) {
    return <LoadingSpinner />;
  }

  const cancelUrl = getCancelDestination(ownerEntityType, ownerEntityId);

  return (
    <>
      {rule == null && (
        <ReceiptRuleBuilderPage
          rule={initialRule}
          ownerEntityType={ownerEntityType}
          ownerEntity={ownerEntity}
          cancelUrl={cancelUrl}
        />
      )}

      {rule != null && (
        <ReceiptRuleBuilderPage
          rule={{ ...rule.rule, name: rule.name, id: rule.id }}
          cancelUrl={cancelUrl}
          ownerEntityType={ownerEntityType}
          ownerEntity={ownerEntity}
        />
      )}
    </>
  );
};

export default AddOrEditReceiptRule;
