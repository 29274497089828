import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useState } from 'react';
import { AiOutlineEdit } from 'react-icons/ai';

import type { OwnerType } from '../../../../common/constants';
import type { Agency, Device, Merchant, MerchantStore } from '../../../../common/types';
import AddApiConfig from '../../../sticky-config/components/AddApiConfig';
import EditApiConfigV2 from '../../../sticky-config/components/EditApiConfig';
import { useGetApiConfigByOwnerQuery } from '../../../sticky-config/sticky-config.api-slice';
import NoDataContainer from './NoDataContainer';
import ViewContainer from './ViewContainer';

interface IProps {
  ownerType: OwnerType;
  ownerEntity: Agency | Merchant | MerchantStore | Device;
}

const DataContainer: React.FC<IProps> = ({ ownerType, ownerEntity }) => {
  const { data: apiConfig } = useGetApiConfigByOwnerQuery(
    ownerEntity !== null ? { ownerType, ownerId: ownerEntity.id } : skipToken
  );
  const [openAddModal, setOpenAddModal] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);

  const toggleAddModal = (): void => {
    setOpenAddModal(!openAddModal);
  };

  const toggleEditModal = (): void => {
    setOpenEditModal(!openEditModal);
  };

  return (
    <div className="min-h-[200px]">
      <div className="flex h-full flex-col">
        <div className="flex w-full items-center justify-between border-b-2 pb-1">
          <span className="font-xl text-xl font-bold">Default Api Config</span>
          {apiConfig != null && (
            <div
              onClick={toggleEditModal}
              className="space-between flex items-center justify-center rounded-lg bg-greenColor p-2 text-center  text-gray-50"
            >
              <AiOutlineEdit size={20} />
              <button className="px-2 text-sm">Edit</button>
            </div>
          )}
        </div>
        {apiConfig != null && (
          <EditApiConfigV2 isOpen={openEditModal} toggleEditModal={toggleEditModal} item={apiConfig} />
        )}
        {apiConfig == null && (
          <AddApiConfig
            isOpen={openAddModal}
            toggleModal={toggleAddModal}
            ownerEntityType={ownerType}
            ownerEntity={ownerEntity}
          />
        )}
        {apiConfig == null && <NoDataContainer toggleModal={toggleAddModal} storeId={ownerEntity.id} />}
        {apiConfig != null && <ViewContainer apiConfig={apiConfig} />}
      </div>
    </div>
  );
};

export default DataContainer;
