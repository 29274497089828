import { skipToken } from '@reduxjs/toolkit/query';
import {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';

import { OwnerType } from '../../../common/constants';
import type { Agency, Device, Merchant, MerchantStore, Receipt } from '../../../common/types';
import Breadcrumb from '../../../components/Breadcrumb';
import LoadingSpinner from '../../../components/LoadingSpinner';
import Pagination from '../../../components/Pagination';
import SuccessMessage from '../../../components/success-message';
import BulkDeleteReceipts from '../components/BulkDeleteReceipts';
import BulkSyncReceipts from '../components/BulkSyncReceipts';
import DeleteItem from '../components/DeleteReceipt';
import ListReceipts from '../components/ListReceipt';
import ReceiptInfo from '../components/ReceiptInfo';
import RetryReceipt from '../components/RetryReceipt';
import { useGetAllReceiptsQuery, useGetReceiptByOwnerQuery } from '../receipt.api-slice';

interface IProps {
  filter: 'all' | 'agency' | 'merchant' | 'store' | 'device';
  agency?: Agency;
  merchant?: Merchant;
  merchantStore?: MerchantStore;
  device?: Device;
  ownerEntityType?: OwnerType;
  ownerEntityId?: string;
}

const getSampleReceiptHeaderAndLink = ({ filter, merchant, merchantStore, device }: IProps): string => {
  const commonHeader = 'List of Receipts';
  return filter === 'device' && device != null
      ? `${commonHeader} of ${device.name}`
      : filter === 'store' && merchantStore != null
      ? `${commonHeader} of ${merchantStore.name}`
      : filter === 'merchant' && merchant != null
      ? `${commonHeader} of ${merchant.name}`
      : commonHeader;
};

const ListReceiptsPage: React.FC<IProps> = ({
  filter,
  agency,
  merchant,
  merchantStore,
  device,
  ownerEntityId,
  ownerEntityType
}) => {
  const { t } = useTranslation();
  const [page, setPage] = useState<number>(1);
  const [displayErrorOnly, setDisplayErrorOnly] = useState<boolean>(false);
  const [displayNonDefaultCardOnly, setDisplayNonDefaultCardOnly] = useState<boolean>(false);
  // how to trigger this data fetch on displayErrorOnly change?

  const { data: records, isLoading, refetch, isUninitialized } =
    filter === 'device'
      ? useGetReceiptByOwnerQuery(
          device != null ? { ownerEntityId: device.id, ownerEntityType: OwnerType.DEVICE, page, errorOnly: displayErrorOnly, nonDefaultCardOnly: displayNonDefaultCardOnly } : skipToken
        )
      : filter === 'store'
      ? useGetReceiptByOwnerQuery(
          merchantStore != null
            ? { ownerEntityId: merchantStore.id, ownerEntityType: OwnerType.STORE, page, errorOnly: displayErrorOnly }
            : skipToken
        )
      : filter === 'merchant'
      ? useGetReceiptByOwnerQuery(
          merchant != null ? { ownerEntityId: merchant.id, ownerEntityType: OwnerType.MERCHANT, page, errorOnly: displayErrorOnly, nonDefaultCardOnly: displayNonDefaultCardOnly } : skipToken
        )
      : filter === 'agency'
      ? useGetReceiptByOwnerQuery(
          agency != null ? { ownerEntityId: agency.id, ownerEntityType: OwnerType.AGENCY, page, errorOnly: displayErrorOnly, nonDefaultCardOnly: displayNonDefaultCardOnly } : skipToken
        )
      : useGetAllReceiptsQuery({ page, errorOnly: displayErrorOnly, nonDefaultCardOnly: displayNonDefaultCardOnly });
  useEffect(() => {
    if (!isUninitialized) {
      void refetch();
    }
  }, [displayErrorOnly, displayNonDefaultCardOnly]);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openInfoModal, setOpenInfoModal] = useState<boolean>(false);
  const [openRetryModal, setOpenRetryModal] = useState<boolean>(false);
  const [openBulkSyncModal, setOpenBulkSyncModal] = useState<boolean>(false);
  const [openBulkDeleteModal, setOpenBulkDeleteModal] = useState<boolean>(false);
  const [deletingItem, setDeletingItem] = useState<Receipt | null>(null);
  const [selectedItem, setSelectedItem] = useState<Receipt | null>(null);
  const [retryingItem, setRetryingItem] = useState<Receipt | null>(null);

  const toggleDeleteModal = (): void => {
    if (openDeleteModal) {
      setDeletingItem(null);
    }
    setOpenDeleteModal(!openDeleteModal);
  };

  const toggleInfoModal = (): void => {
    if (openInfoModal) {
      setSelectedItem(null);
    }
    setOpenInfoModal(!openInfoModal);
  };

  const toggleRetryModal = (): void => {
    if (openRetryModal) {
      setRetryingItem(null);
    }
    setOpenRetryModal(!openRetryModal);
  };

  const toggleBulkSyncModal = (): void => {
    setOpenBulkSyncModal((prev) => !prev);
  };

  const toggleBulkDeleteModal = (): void => {
    setOpenBulkDeleteModal((prev) => !prev);
  };

  const handleSelectItem = (item: Receipt | null): void => {
    setSelectedItem(item);
  };

  const handleDeletingItem = (item: Receipt | null): void => {
    setDeletingItem(item);
  };

  const handleBulkSync = (): void => {
    // console.log('Initiating batch sync');
    toggleBulkSyncModal();
  };

  const handleBulkDelete = (): void => {
    // console.log('Initiating batch delete');
    toggleBulkDeleteModal();
  };

  const handleRetryItem = (item: Receipt | null): void => {
    // console.log('Retrying item...', item?.id);
    setRetryingItem(item);
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const toggleDisplayErrorOnly = (): void => {
    setDisplayErrorOnly((prev) => !prev);
  }

  const toggleNonDefaultCardOnly = (): void => {
    setDisplayNonDefaultCardOnly((prev) => !prev);
  }

  const header = getSampleReceiptHeaderAndLink({
    filter,
    merchant,
    merchantStore,
    device
  });

  return (
    <div>
      <div className="pb-4">
        <h1>{t('receipts.list.title')}</h1>
        <Breadcrumb
          items={[{ label: t('common.adminBreadcrumbLabel') }, { label: t('receipts.list.breadcrumbLabel') }]}
        />
      </div>
      <div className="flex w-full items-center justify-between px-2">
        <h1>{header}</h1>
        <div className="flex items-center justify-end">
        <button onClick={toggleNonDefaultCardOnly} className="ml-4 rounded-lg bg-[#2f658b] px-5 py-2 text-white">
            {  displayNonDefaultCardOnly ? t('receipts.list.displayAllCards') : t('receipts.list.displayNonDefaultCardOnly')}
          </button>
          <button onClick={toggleDisplayErrorOnly} className="ml-4 rounded-lg bg-greenColor px-5 py-2 text-white">
            {  displayErrorOnly ? t('receipts.list.displayAll') : t('receipts.list.displayErrorOnly')}
          </button>
          <button onClick={handleBulkSync} className="ml-4 rounded-lg bg-primary px-5 py-2 text-white">
            {t('receipts.list.batchSyncButtonLabel')}
          </button>
          <button onClick={handleBulkDelete} className="ml-4 rounded-lg bg-red-600 px-5 py-2 text-white">
            {t('receipts.list.batchDeleteButtonLabel')}
          </button>
        </div>
      </div>

      {records?.data == null ||
        (records.data.length === 0 && <SuccessMessage message={t('receipts.list.empty')} type="error" />)}

      {records?.data != null && records.data.length > 0 && (
        <>
          <DeleteItem isOpen={openDeleteModal} toggleDeleteModal={toggleDeleteModal} item={deletingItem} />
          <RetryReceipt isOpen={openRetryModal} toggleRetryModal={toggleRetryModal} item={retryingItem} />
          <ReceiptInfo isOpen={openInfoModal} toggleModal={toggleInfoModal} item={selectedItem} />
          <BulkSyncReceipts
            isOpen={openBulkSyncModal}
            toggleModal={toggleBulkSyncModal}
            ownerEntityId={ownerEntityId}
            ownerEntityType={ownerEntityType}
          />
          <BulkDeleteReceipts
            isOpen={openBulkDeleteModal}
            toggleModal={toggleBulkDeleteModal}
            ownerEntityId={ownerEntityId}
            ownerEntityType={ownerEntityType}
          />
        </>
      )}

      {records?.data != null && records.data.length > 0 && (
        <>
          <ListReceipts
            items={records.data}
            activeItemId={selectedItem?.id}
            toggleInfoModal={toggleInfoModal}
            toggleDeleteModal={toggleDeleteModal}
            toggleRetryModal={toggleRetryModal}
            handleInfoItem={handleSelectItem}
            handleDeletingItem={handleDeletingItem}
            handleRetryItem={handleRetryItem}
          />
          <Pagination pageData={records.meta} handlePageChange={setPage} />
        </>
      )}
    </div>
  );
};

export default ListReceiptsPage;
