import { apiSlice } from '../../app/services/api-slice';
import { type OwnerType } from '../../common/constants';
import { urlWithPageAndTerm } from '../../common/helpers';
import {
  type ApiSuccessResponse,
  type ListWithPaginationAndSearchReq,
  type PaginatedData,
  type PaginatedMeta,
  type SampleReceipt,
  type SampleReceiptCreateRequest,
  type SampleReceiptUpdateRequest
} from '../../common/types';

const sampleReceiptTagName: string = 'SampleReceipt';

interface SampleReceiptByOwnerReqParam {
  ownerId: string;
  ownerType: OwnerType;
  page?: number;
}

const sampleReceiptTagApi = apiSlice
  .enhanceEndpoints({
    addTagTypes: [sampleReceiptTagName]
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getAllSampleReceiptsSelect: builder.query<SampleReceipt[], void>({
        query: (): string => '/sample-receipts/short-list',
        transformResponse: (data: ApiSuccessResponse) => {
          return data.data as SampleReceipt[];
        },
        providesTags: (result) =>
          result != null
            ? [
                ...result.map(() => ({
                  type: sampleReceiptTagName
                })),
                { type: sampleReceiptTagName, id: 'LIST_SHORT' }
              ]
            : [{ type: sampleReceiptTagName, id: 'LIST_SHORT' }]
      }),
      getAllSampleReceipts: builder.query<PaginatedData<SampleReceipt> | null, ListWithPaginationAndSearchReq>({
        query: ({ term = '', page = 1 }): string => {
          return urlWithPageAndTerm('/sample-receipts', { term, page });
        },
        transformResponse: (data: ApiSuccessResponse) => {
          if (data.data === undefined) {
            return null;
          }
          return {
            data: data.data.data as SampleReceipt[],
            meta: data.data.meta as PaginatedMeta
          };
        },
        providesTags: (result) =>
          result != null
            ? [
                ...result.data.map(({ id }) => ({
                  type: sampleReceiptTagName,
                  id
                })),
                { type: sampleReceiptTagName, id: 'LIST' }
              ]
            : [{ type: sampleReceiptTagName, id: 'LIST' }]
      }),
      addSampleReceipt: builder.mutation({
        query: (body: SampleReceiptCreateRequest) => ({
          url: '/sample-receipts',
          method: 'POST',
          body
        }),
        invalidatesTags: [{ type: sampleReceiptTagName, id: 'LIST' }]
      }),
      getSampleReceiptById: builder.query<SampleReceipt, string>({
        query: (id: string) => `/sample-receipts/${id}`,
        transformResponse: (data: ApiSuccessResponse) => {
          return data.data as SampleReceipt;
        },
        providesTags: (result) => (result != null ? [{ type: sampleReceiptTagName, id: result?.id }] : [])
      }),
      getSampleReceiptByOwner: builder.query<PaginatedData<SampleReceipt> | null, SampleReceiptByOwnerReqParam>({
        query: ({ ownerId, ownerType, page = 1 }): string => {
          return urlWithPageAndTerm(`/sample-receipts/${ownerType}/${ownerId}`, { page });
        },
        transformResponse: (data: ApiSuccessResponse) => {
          if (data.data === undefined) {
            return null;
          }
          return {
            data: data.data.data as SampleReceipt[],
            meta: data.data.meta as PaginatedMeta
          };
        },
        providesTags: (result) =>
          result != null
            ? [
                ...result.data.map(({ id }) => ({
                  type: sampleReceiptTagName,
                  id
                })),
                { type: sampleReceiptTagName, id: 'LIST' }
              ]
            : [{ type: sampleReceiptTagName, id: 'LIST' }]
      }),
      updateSampleReceipt: builder.mutation<SampleReceipt, SampleReceiptUpdateRequest>({
        query: ({ id, body }) => ({
          url: `/sample-receipts/${id}`,
          method: 'PATCH',
          body
        }),
        invalidatesTags: (result, error, arg) => [{ type: sampleReceiptTagName, id: arg.id }]
      }),
      deleteSampleReceiptById: builder.mutation<void, string>({
        query: (id: string) => ({
          url: `/sample-receipts/${id}`,
          method: 'DELETE'
        }),
        invalidatesTags: (result, error, arg) => [{ type: sampleReceiptTagName, id: 'LIST' }]
      })
    }),
    overrideExisting: false
  });

export const {
  useGetAllSampleReceiptsSelectQuery,
  useGetAllSampleReceiptsQuery,
  useAddSampleReceiptMutation,
  useGetSampleReceiptByIdQuery,
  useGetSampleReceiptByOwnerQuery,
  useUpdateSampleReceiptMutation,
  useDeleteSampleReceiptByIdMutation
} = sampleReceiptTagApi;
