import { Form, Formik, type FormikHelpers } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { useAppDispatch } from '../app/hooks/hook';
import { useAuth } from '../app/hooks/useAuth';
import logo from '../assets/logo.webp';
import { AUTH_TOKEN_NAME } from '../common/constants';
import { extractErrorsFromApi } from '../common/helpers';
import CustomTextInput from '../components/Form/custom-text-input';
import RenderErrorBag from '../components/render-error-bag';
import { useLoginUserMutation } from './auth.api-slice';
import { UserLoginSchema } from './auth.schema';
import { login } from './auth.slice';

export interface UserLogin {
  email: string;
  password: string;
}

const initialUserLogin: UserLogin = {
  email: '',
  password: ''
};

const UserLoginForm: React.FC = () => {
  const [loginUser, { data, isSuccess, isLoading, isError, error }] = useLoginUserMutation();
  const [errorBag, setErrorBag] = useState<string[]>([]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const auth = useAuth();
  const { t } = useTranslation();

  useEffect(() => {
    if (auth.user != null) {
      navigate('/admin/dashboard');
    }

    if (isSuccess && data?.jwtToken != null) {
      navigate('/admin/dashboard');
      dispatch(login({ token: data.jwtToken, user: data.user, loggedIn: true }));
      sessionStorage.setItem(AUTH_TOKEN_NAME, data?.jwtToken);
    }

    if (isError && error != null) {
      setErrorBag(extractErrorsFromApi(error));
    }
  }, [auth, data, dispatch, error, isError, isSuccess, navigate]);

  const onUserLogin = async (values: UserLogin, actions: FormikHelpers<UserLogin>): Promise<any> => {
    await loginUser({ email: values.email, password: values.password });
    actions.resetForm();
  };
  return (
    <div className="mx-auto flex h-[100vh] max-w-2xl flex-col justify-center space-y-4 py-2 text-center">
      <div className="flex flex-col rounded-md bg-gray-50 px-6 py-4">
        <div className="w-full text-center">
          <img src={logo} alt="Logo" className="inline h-[10vh] px-32" />
        </div>
        <span className="mb-2 w-full text-center text-3xl font-bold">{t('auth.login.title')}</span>
        <div className="w-full">
          {error != null && errorBag.length > 0 && <RenderErrorBag errorBag={errorBag} />}
          <Formik initialValues={initialUserLogin} validationSchema={UserLoginSchema} onSubmit={onUserLogin}>
            {({ isSubmitting }) => (
              <Form className="flex w-full flex-col items-start space-y-4 px-2" autoComplete="off">
                <CustomTextInput
                  type="text"
                  label="Email"
                  name="email"
                  data-cy="email"
                  divClass="w-full flex flex-col"
                  labelClass="w-full text-left text-gray-600"
                  inputClass="w-full my-1 px-4 py-1 rounded-md"
                />
                <CustomTextInput
                  type="password"
                  label="Password"
                  name="password"
                  data-cy="password"
                  divClass="w-full flex flex-col"
                  labelClass="w-full text-left text-gray-600"
                  inputClass="w-full my-1 px-4 py-1 rounded-md"
                />
                <div className="mt-16 flex w-full flex-col space-y-4">
                  <button
                    type="submit"
                    disabled={isLoading}
                    data-cy="login-btn"
                    className={`w-full rounded-lg bg-greenColor px-5 py-2 text-white disabled:opacity-20`}
                  >
                    {t('auth.login.submitBtnLabel')}
                  </button>
                  <Link to="/register">
                    <button data-cy="register-page-btn" className="w-full rounded-lg bg-white px-5 py-2 text-black">
                      {t('auth.login.registerBtnLabel')}
                    </button>
                  </Link>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default UserLoginForm;
