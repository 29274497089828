import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useParams } from 'react-router-dom';

import { OwnerType } from '../../../common/constants';
import { useGetDeviceByIdQuery } from '../../devices/device.api-slice';
import ListReceiptsPage from './ListReceipts';

const ListReceiptsForDevice: React.FC = () => {
  const { id } = useParams();
  const { data } = useGetDeviceByIdQuery(id ?? skipToken);

  if (data === undefined) {
    return <ListReceiptsPage device={data} filter={'device'} />;
  } else {
    return (
      <ListReceiptsPage device={data} filter={'device'} ownerEntityType={OwnerType.DEVICE} ownerEntityId={data.id} />
    );
  }
};

export default ListReceiptsForDevice;
