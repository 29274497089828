import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

import { useAuth } from '../../../app/hooks/useAuth';
import { getAgencyMerchantStoreBasedOnScopeAndRole } from '../../../common/helpers';
import { type Agency, type Merchant } from '../../../common/types';
import Breadcrumb from '../../../components/Breadcrumb';
import LoadingSpinner from '../../../components/LoadingSpinner';
import Pagination from '../../../components/Pagination';
import SuccessMessage from '../../../components/success-message';
import AddMerchant from '../components/AddMerchant';
import ApproveMerchant from '../components/ApproveMerchant';
import DeleteMerchant from '../components/DeleteMerchant';
import EditMerchant from '../components/EditMerchant';
import ListMerchants from '../components/ListMerchants';
import useCustomMerchantsQuery from '../hooks/custom-merchant-hook';

interface IProps {
  scope?: 'all' | 'agency';
  agency?: Agency;
  filter?: { pending: boolean };
}

const ListMerchantsPage: React.FC<IProps> = ({ scope = 'all', filter, agency }) => {
  const { user } = useAuth();
  const [openAddModal, setOpenAddModal] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openApproveModal, setOpenApproveModal] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<Merchant|null>(null);
  const [page, setPage] = useState<number>(1);
  const { t } = useTranslation();
  const parentContext = getAgencyMerchantStoreBasedOnScopeAndRole({ loggedInUser: user, scope, agency });
  const {isLoading, merchants} = useCustomMerchantsQuery({scope, page, filter, agencyId: agency?.id});

  const toggleAddModal = (): void => {
    setOpenAddModal((prevState) => !prevState);
  };


  const toggleEditModal = (): void => {
    if (openEditModal) {
      setSelectedItem(null);
    }
    setOpenEditModal(!openEditModal);
  };

  const toggleDeleteModal = (): void => {
    if (openDeleteModal) {
      setSelectedItem(null);
    }
    setOpenDeleteModal(!openDeleteModal);
  };

  const toggleApproveModal = (): void => {
    if (openApproveModal) {
      setSelectedItem(null);
    }
    setOpenApproveModal(!openApproveModal);
  };



  const handleToggleActionModal = (action: string, item: Merchant): void => {
    if (action === 'edit') {
      setOpenEditModal(openEditModal => !openEditModal)
      setSelectedItem(item)
    }

    if (action === 'delete') {
      setOpenDeleteModal(openDeleteModal => !openDeleteModal)
      setSelectedItem(item)
    }

    if (action === 'approve') {
      setOpenApproveModal(openApproveModal => !openApproveModal)
      setSelectedItem(item)
    }
  }

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <div className="pb-4">
        <h1>{t('merchants.list.title')}</h1>
        <Breadcrumb
          items={[{ label: t('common.adminBreadcrumbLabel') }, { label: t('merchants.list.breadcrumbLabel') }]}
        />
      </div>

      <div className="flex w-full flex-col space-y-2 md:flex-row md:items-center md:justify-between">
        <h1 className="text-2xl">{t('merchants.list.heading')}</h1>
        <div className="flex justify-items-center gap-x-2 md:items-center md:justify-between">
          {filter?.pending === true && (
            <Link to="/admin/merchants">
              <button className="ml-4 rounded-lg bg-gray-50 px-5 py-2">
                {t('merchants.list.listLinkLabel')}
              </button>
            </Link>
          )}
          {filter?.pending !== true && (
            <>
              <Link to="/admin/merchants/pending-requests">
                <button className="ml-4 rounded-lg bg-gray-50 px-5 py-2">
                  {t('merchants.list.pendingListLabel')}
                </button>
              </Link>
              <button onClick={toggleAddModal} className="ml-4 rounded-lg bg-greenColor px-5 py-2 text-white">
                {t('merchants.list.inviteLabel')}
              </button>
            </>
          )}
        </div>
      </div>

      <AddMerchant
        isOpen={openAddModal}
        toggleModal={toggleAddModal}
        agencyInContext={agency}
        parentContext={parentContext}
      />

      <EditMerchant isOpen={openEditModal} toggleEditModal={toggleEditModal} item={selectedItem} />

      <DeleteMerchant isOpen={openDeleteModal} toggleDeleteModal={toggleDeleteModal} item={selectedItem} />

      <ApproveMerchant isOpen={openApproveModal} toggleApproveModal={toggleApproveModal} item={selectedItem} />

      {merchants?.data == null ||
        (merchants.data.length === 0 && <SuccessMessage message={t('merchants.list.empty')} type="error" />)}

      {merchants?.data != null && merchants.data.length > 0 && (
        <>
          <ListMerchants
            items={merchants.data}
            filter={{ pending: filter?.pending === true }}
            handleToggleActionModal={handleToggleActionModal}
          />
          <Pagination pageData={merchants.meta} handlePageChange={setPage} />
          <Tooltip id='merchant-list' />
        </>
      )}
    </div>
  );
};

export default ListMerchantsPage;
