import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useAuth } from '../../../app/hooks/useAuth';
import { UserRole } from '../../../common/constants';
import { getAgencyMerchantStoreBasedOnScopeAndRole } from '../../../common/helpers';
import type { Agency, Merchant, MerchantStore, RoleOption, User } from '../../../common/types';
import Breadcrumb from '../../../components/Breadcrumb';
import LoadingSpinner from '../../../components/LoadingSpinner';
import Pagination from '../../../components/Pagination';
import SuccessMessage from '../../../components/success-message';
import AddUser from '../components/AddUser';
import ApproveUser from '../components/ApproveUser';
import DeleteUser from '../components/DeleteUser';
import EditUser from '../components/EditUser';
import ListUsers from '../components/ListUsers';
import useCustomUsersQuery from '../hooks/custom-user-hook';
import { RoleLabel } from '../user.constant';

interface IProps {
  scope?: 'all' | 'agency' | 'merchant' | 'merchantStore';
  agency?: Agency;
  merchant?: Merchant;
  store?: MerchantStore;
  filter?: { pending: boolean };
}

const getPageTitle = ({ scope, filter, agency, merchant, store }: IProps): string => {
  const commonPageTitlePart = 'List of';
  return scope === 'agency' && agency != null
    ? `${commonPageTitlePart} Users for Agency ${agency.name}`
    : scope === 'merchant' && merchant != null
    ? `${commonPageTitlePart} Users for Merchant ${merchant.name}`
    : scope === 'merchantStore' && store != null
    ? `${commonPageTitlePart} Users for Store ${store.name}`
    : filter?.pending === true
    ? ' Pending Users'
    : ' Users';
};

const getAvailableRoles = (scope: string, loggedInUserRole?: string): RoleOption[] => {
  const roles: RoleOption[] = [];
  if (UserRole.SUPER_ADMIN === loggedInUserRole) {
    if (scope === 'all') {
      roles.push(
        { value: UserRole.SUPER_ADMIN, label: RoleLabel.superAdmin },
        { value: UserRole.AGENCY_ADMIN, label: RoleLabel.agencyAdmin },
        { value: UserRole.MERCHANT_ADMIN, label: RoleLabel.merchantAdmin },
        { value: UserRole.STORE_ADMIN, label: RoleLabel.storeAdmin },
        { value: UserRole.EMPLOYEE, label: RoleLabel.employee }
      );
    } else if (scope === 'agency') {
      roles.push(
        { value: UserRole.AGENCY_ADMIN, label: RoleLabel.agencyAdmin },
        { value: UserRole.MERCHANT_ADMIN, label: RoleLabel.merchantAdmin },
        { value: UserRole.STORE_ADMIN, label: RoleLabel.storeAdmin },
        { value: UserRole.EMPLOYEE, label: RoleLabel.employee }
      );
    } else if (scope === 'merchant') {
      roles.push(
        { value: UserRole.MERCHANT_ADMIN, label: RoleLabel.merchantAdmin },
        { value: UserRole.STORE_ADMIN, label: RoleLabel.storeAdmin },
        { value: UserRole.EMPLOYEE, label: RoleLabel.employee }
      );
    } else if (scope === 'store') {
      roles.push(
        { value: UserRole.STORE_ADMIN, label: RoleLabel.storeAdmin },
        { value: UserRole.EMPLOYEE, label: RoleLabel.employee }
      );
    } else {
      roles.push({ value: UserRole.EMPLOYEE, label: RoleLabel.employee });
    }
  } else if (UserRole.AGENCY_ADMIN === loggedInUserRole) {
    if (scope === 'all' || scope === 'merchant') {
      roles.push(
        { value: UserRole.MERCHANT_ADMIN, label: RoleLabel.merchantAdmin },
        { value: UserRole.STORE_ADMIN, label: RoleLabel.storeAdmin },
        { value: UserRole.EMPLOYEE, label: RoleLabel.employee }
      );
    } else if (scope === 'store') {
      roles.push(
        { value: UserRole.STORE_ADMIN, label: RoleLabel.storeAdmin },
        { value: UserRole.EMPLOYEE, label: RoleLabel.employee }
      );
    } else {
      roles.push({ value: UserRole.EMPLOYEE, label: RoleLabel.employee });
    }
  } else if (UserRole.MERCHANT_ADMIN === loggedInUserRole) {
    if (scope === 'all' || scope === 'store') {
      roles.push(
        { value: UserRole.STORE_ADMIN, label: RoleLabel.storeAdmin },
        { value: UserRole.EMPLOYEE, label: 'Employee' }
      );
    } else {
      roles.push({ value: UserRole.EMPLOYEE, label: 'Employee' });
    }
  }

  return roles;
};

const ListUsersPage: React.FC<IProps> = ({ scope = 'all', filter, agency, merchant, store }) => {
  const [openAddModal, setOpenAddModal] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openApproveModal, setOpenApproveModal] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const { t } = useTranslation();
  const { user } = useAuth();
  const injectedIds = getAgencyMerchantStoreBasedOnScopeAndRole({ loggedInUser: user, scope, agency, merchant, store });
  const [selectedItem, setSelectedItem] = useState<User|null>(null);

  const { users, isLoading } = useCustomUsersQuery({
    page,
    scope,
    filter,
    agencyId: agency?.id,
    merchantId: merchant?.id,
    storeId: store?.id,
  })
  const toggleAddModal = (): void => {
    if (openAddModal) {
      setSelectedItem(null);
    }
    setOpenAddModal(!openAddModal);
  };

  const toggleEditModal = (): void => {
    if (openEditModal) {
      setSelectedItem(null);
    }
    setOpenEditModal(!openEditModal);
  };

  const toggleDeleteModal = (): void => {
    if (openDeleteModal) {
      setSelectedItem(null);
    }
    setOpenDeleteModal(!openDeleteModal);
  };

  const toggleApproveModal = (): void => {
    if (openApproveModal) {
      setSelectedItem(null);
    }
    setOpenApproveModal(!openApproveModal);
  };

  const handleToggleActionModal = (action: string, item: User): void => {
    if (action === 'edit') {
      setOpenEditModal(openEditModal => !openEditModal)
      setSelectedItem(item)
    }

    if (action === 'delete') {
      setOpenDeleteModal(openDeleteModal => !openDeleteModal)
      setSelectedItem(item)
    }

    if (action === 'approve') {
      setOpenApproveModal(openApproveModal => !openApproveModal)
      setSelectedItem(item)
    }
   
  }

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <div className="pb-4">
        <h1>{t('users.list.title')}</h1>
        <Breadcrumb
          items={[{ label: t('common.adminBreadcrumbLabel') }, { label: t('merchants.list.breadcrumbLabel') }]}
        />
      </div>
      <div className="flex w-full items-center justify-between">
        <h1>{getPageTitle({ scope, filter, agency, merchant, store })}</h1>
        <div>
          {scope === 'all' && filter?.pending !== true && (
            <Link to={`/admin/users/pending-requests`}>
              <button className="ml-4 rounded-lg bg-gray-50 px-5 py-2">Pending Users</button>
            </Link>
          )}
          {user?.role !== UserRole.EMPLOYEE && filter?.pending !== true && (
            <button onClick={toggleAddModal} className="ml-4 rounded-lg bg-greenColor px-5 py-2 text-white">
              Invite User
            </button>
          )}
        </div>

        {filter?.pending === true && (
          <div>
            <Link to={`/admin/users`}>
              <button className="ml-4 rounded-lg bg-gray-50 px-5 py-2">List Users</button>
            </Link>
          </div>
        )}
      </div>

      <AddUser
        isOpen={openAddModal}
        toggleModal={toggleAddModal}
        availableRoles={getAvailableRoles(scope, user?.role)}
        injectedIds={injectedIds}
      />

      <EditUser isOpen={openEditModal} toggleEditModal={toggleEditModal} item={selectedItem} />

      <DeleteUser isOpen={openDeleteModal} toggleDeleteModal={toggleDeleteModal} item={selectedItem} />
      <ApproveUser isOpen={openApproveModal} toggleApproveModal={toggleApproveModal} item={selectedItem} />
      {users?.data == null ||
        (users.data.length === 0 && <SuccessMessage message={t('users.list.empty')} type="error" />)}
      {users?.data != null && users?.data.length > 0 && (
        <>
          <ListUsers
            items={users.data}
            filter={{ pending: filter?.pending === true }}
            handleToggleActionModal={handleToggleActionModal}
          />
          <Pagination pageData={users.meta} handlePageChange={setPage} />
        </>
      )}
    </div>
  );
};

export default ListUsersPage;
